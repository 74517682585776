import { Injectable } from "@angular/core";
import { environment } from "@env";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { GetOrderDetail } from "@app/shared/CSDAModels/GetOrderDetail";
import { GetOrderResult } from "@app/shared/CSDAModels/GetOrderResult";
import { HttpClient } from "@angular/common/http";
import { ValidationService } from "@app/core/services/validation.service";
import { QuoteDetail } from "@app/shared/CSDAModels/QuoteDetail";
import { SaveOrderResult } from "@app/shared/CSDAModels/SaveOrderResult";
import { CreateQuotesResult } from "@app/shared/CSDAModels/CreateQuotesResult";
import { CreateShipmentDetail } from "@app/shared/CSDAModels/CreateShipmentDetail";
import { CreateShipmentsResult } from "@app/shared/CSDAModels/CreateShipmentsResult";
import Swal from "sweetalert2";
import { GetCustomPackagesResult } from "@app/shared/CSDAModels/GetCustomPackagesResult";
import { GetCustomPackagesDetail } from "@app/shared/CSDAModels/GetCustomPackagesDetail";
import { SaveOrderDetail } from "@app/shared/CSDAModels/SaveOrderDetail";
import { Language } from "@app/core/enums/Language";
import { LanguageService } from "@app/core/services/language.service";

@Injectable({
	providedIn: "root"
})
export class CreateNewShipmentService {

	/** The constructor of the  CreateNewShipmentService class*/
	constructor(
		private httpClient: HttpClient,
		private validationService: ValidationService,
		private languageService: LanguageService,
	) { }

	getCustomPackages(getCustomPackagesDetail: GetCustomPackagesDetail) {
		return this.httpClient.post<GetCustomPackagesResult>(environment.CSDAEndPoint + "/CSDA/GetCustomPackages", getCustomPackagesDetail)
			.pipe(
				map((data) => {
					var validation = this.validationService.validate(data);

					if (validation.isValid === false) {
						Swal.fire('', validation.message, "error");
						return null;
					}

					if (validation.message !== "") {
						Swal.fire('', validation.message, "info");
					}

					return data !== undefined ? data : null;
				})
			);
	}
	/** Get the current order detail
	 * @param getOrderDetail - The order detail
	 * @returns The order result
	 */
	getCurrentOrder(getOrderDetail: GetOrderDetail): Observable<GetOrderResult> {
		return this.httpClient.post<GetOrderResult>(environment.CSDAEndPoint + "/CSDA/GetOrder", getOrderDetail)
			.pipe(
				map((data) => {
					var validation = this.validationService.validate(data);

					if (validation.isValid === false) {
						Swal.fire('', validation.message, "error");
						return null;
					}

					if (validation.message !== "") {
						Swal.fire('', validation.message, "info");
					}

					return data !== undefined ? data : null;
				})
			);
	}

	/** Save an order
	 * @param order - The order to save
	 * @returns null if the save doesn't work, else the order ID
	 */
	saveOrder(order: QuoteDetail): Observable<number> {
		var saveOrderDetail: SaveOrderDetail = {
			Language: this.languageService.GetCurrentLanguage(),
			IsDevelopmentEnvironment: false,
			QuoteType: parseInt(sessionStorage.getItem("quoteType")),
			OrderToSave: order
		}
		return this.httpClient.post<SaveOrderResult>(environment.CSDAEndPoint + "/CSDA/SaveOrder", saveOrderDetail)
			.pipe(
				map((data) => {
					var validation = this.validationService.validate(data);

					if (validation.isValid === false) {
						Swal.fire('', validation.message, "error");
						return null;
					}

					if (validation.message !== "") {
						Swal.fire('', validation.message, "info");
					}

					return data.OrderID !== undefined ? data.OrderID : null;
				})
			);
	}

	/** Get quotes for the actual order
	 * 
	 * @param order - The order 
	 * @returns quotes result
	 */
	public getQuotes(order: QuoteDetail, isQuickQuote: boolean): Observable<CreateQuotesResult> {
		var URL = environment.CSDAEndPoint + "/CSDA/" + (isQuickQuote ? "CreateQuickQuotes" : "CreateQuotes");

		return this.httpClient.post<CreateQuotesResult>(URL, order)
			.pipe(
				map((data) => {
					var validation = this.validationService.validate(data);

					if (validation.isValid === false) {
						Swal.fire('', validation.message, "error");
						return null;
					}

					return data !== undefined ? data : null;
				})
			);
	}


	/** Used by step 2 */
	public createShipment(createShipmentDetail: CreateShipmentDetail): Observable<CreateShipmentsResult> {
		return this.httpClient.post<CreateShipmentsResult>(environment.CSDAEndPoint + "/CSDA/CreateShipment", createShipmentDetail)
			.pipe(map((data) => {
				// Verifies if the result is valid and return data, else display an error message and return null
				var parentValidation = this.validationService.validate(data);
				if (parentValidation.isValid === false) {
					Swal.fire('', parentValidation.message, "info");
					return null;
				}
				else {
					var childValidation = this.validationService.validate(data.ShipmentResults[0]);
					if (childValidation.isValid === false) {
						Swal.fire('', childValidation.message, "info");
						return null;
					}
					else {
						var validationMessage = parentValidation.message + '<br><br>' + childValidation.message;
						if (validationMessage !== '<br><br>') {
							Swal.fire('', validationMessage, "info");
						}
						return data;
					}
				}
			}));
	}
}
