import { MatButtonModule as MatButtonModule } from "@angular/material/button";

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "@app/shared/shared.module";
import { ShopifyOrdersRoutingModule } from './shopify-orders-routing.module';
import { ShopifyOrdersComponent } from './pages/shopify-orders.component';

@NgModule({
  imports: [CommonModule, ShopifyOrdersRoutingModule, SharedModule, MatButtonModule],
  exports: [MatButtonModule],
  declarations: [ShopifyOrdersComponent]
})
export class ShopifyOrdersModule { }
