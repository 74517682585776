import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from "@angular/core";
import { spCSDAManageRateGetListResult } from "@app/shared/CSDAModels/ManageClientModels/Rate/spCSDAManageRateGetListResult";
import { TranslateService } from "@ngx-translate/core";
import { ManageClientsService } from "../_Services/manage-clients.service";
import Swal from "sweetalert2";
import { CarriersList } from "@app/shared/CSDAModels/CarriersList";
import { Countries } from "@app/shared/CSDAModels/Countries";
import { CSAPI_ServiceDescriptions } from "@app/shared/CSDAModels/CSAPI_ServiceDescriptions";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";

@Component({
  selector: 'Rates',
  templateUrl: './Rates.component.html',
  styleUrls: ['./Rates.component.scss']
})
export class RatesComponent implements OnInit, AfterViewInit {

  //---------------------------------------------------
  // Define the fields of the model which are displayed
  //---------------------------------------------------
  COLUMNS_SCHEMA = this.getSchema();
  displayedColumns: string[] = this.COLUMNS_SCHEMA.map((col) => col.key);
  dataSource = new MatTableDataSource([]);
  ratesList: spCSDAManageRateGetListResult[];

  isProcessing = false;
  public Div_Id = -1;
  selectedRateId = -1;
  originalRate: spCSDAManageRateGetListResult;
  editing = false;
  carriersList: CarriersList[];
  countriesList: Countries[];
  serviceDescriptionsList: CSAPI_ServiceDescriptions[];
  filteredServices: CSAPI_ServiceDescriptions[];
  selectedCarrier: number = -1;

  constructor(
    private translateService: TranslateService,
    private ManageClientsService: ManageClientsService,
  ) { }

  @HostListener('window:keyup', ['$event'])

  keyEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      //TODO: If possible because I don't have the element here and I need to cancel the element being edited.
    }
  }

  @Output('selectedRate') selectedRate: EventEmitter<number> = new  EventEmitter<number>();
  @ViewChild(MatSort) sort: MatSort;
  
  ngAfterViewInit() {
  }

  ngOnInit(): void {
    this.carriersList = JSON.parse(sessionStorage.getItem("Carriers")) as CarriersList[];
    this.countriesList = JSON.parse(sessionStorage.getItem("Countries")) as Countries[];
    this.serviceDescriptionsList = JSON.parse(sessionStorage.getItem("ServiceDescriptions")) as CSAPI_ServiceDescriptions[];
  }

  getSchema() {
    return [
      {
        key: 'MGA_CarrierName',
        type: 'listCarriers',
        label: "Agent",
        labelEn: 'Carrier'
      },
      {
        key: 'MGA_ServiceDescription',
        type: 'listServiceDescriptions',
        label: "Nom de service",
        labelEn: 'Service Name'
      },
      {
        key: 'MGA_Rate',
        type: 'number',
        label: "Taux",
        labelEn: 'Rate'
      },
      {
        key: 'MGA_Min_Weight',
        type: 'number',
        label: "Poids Min.",
        labelEn: 'Min. Weight'
      },
      {
        key: 'MGA_Max_Weight',
        type: 'number',
        label: "Poids Max.",
        labelEn: 'Max. Weight'
      },
      {
        key: 'MGA_CountryOrig_Code',
        type: 'listCountries',
        label: "Pays Origine",
        labelEn: 'Origin Country'
      },
      {
        key: 'MGA_CountryDest_Code',
        type: 'listCountries',
        label: "Pays Destination",
        labelEn: 'Destination Country'
      },
      {
        key: 'MGA_IsFromShoppingCart',
        type: 'checkboxSC',
        label: "Du Panier",
        labelEn: 'From Shopping Cart'
      },
      {
        key: 'MGA_DryIce',
        type: 'checkboxDI',
        label: "Glace Sèche",
        labelEn: 'Dry Ice'
      },
      {
        key: 'MGA_ShoppingCartName',
        type: 'text',
        label: "Nom du ratif",
        labelEn: 'Rate Name'
      },
      {
        key: 'MGA_ShoppingCartDescription',
        type: 'text',
        label: "Desc. du ratif",
        labelEn: 'Rate Desc.'
      },
      {
        key: 'isEdit',
        type: 'isEdit',
        label: ''
      }
    ];
  }
  
  getRates(id: any) {
    if(id != -1) {
      this.Div_Id = id;
      const DivId = id != -1 ? id : this.Div_Id;
      this.Div_Id = DivId;
      this.isProcessing = true;
      this.editing = false;
      this.selectedRateId = -1;
      
      this.ManageClientsService.getRates(DivId).subscribe(Result => {
        this.ratesList = Result.RatesList;
        this.dataSource = new MatTableDataSource(this.ratesList);
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch(property) {
            case 'MGA_ServiceDescription': 
              return this.getServiceDescription(item.MGA_ServiceDescription);
            default: 
              return item[property];
          }
        };
    
        this.dataSource.sort = this.sort;
            this.isProcessing = false;
      })
    } else {
      this.dataSource = null;
    }
  }

  addRow() {
    const newRow = {
      MGA_ID: -1,
      MGA_DIV_REF: this.Div_Id,
      MGA_CarrierName: '',
      MGA_ServiceDescription: '',
      MGA_Rate: null,
      MGA_Min_Weight: null,
      MGA_Max_Weight: null,
      MGA_CountryOrig_Code: '',
      MGA_CountryDest_Code: '',
      MGA_IsFromShoppingCart: false,
      MGA_ShoppingCartName: '',
      MGA_ShoppingCartDescription: '',
      MGA_DryIce: false,
      isEdit: true,
    };

    this.editing = true;
    this.dataSource.data = [newRow, ...this.dataSource.data];
    this.filteredServices = null;
  }

  removeRow(element) {
    Swal.fire({
      title: 'Confirmation',
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.isProcessing = true;

        this.ManageClientsService.deleteRate(element).subscribe(Result => {
          this.isProcessing = false;
          this.dataSource.data = this.dataSource.data.filter((u) => u.MGA_ID != element.MGA_ID);
        });
      }
    });    
  }

  removeSelectedRows() {
    Swal.fire({
      title: 'Confirmation',
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.dataSource.data = this.dataSource.data.filter((u: any) => !u.isSelected);
      }
    });    
  }

  saveChanges(element: spCSDAManageRateGetListResult): boolean {

    if(this.validateRange(element)) {
      this.isProcessing = true;

      const index = this.dataSource.data.findIndex(object => {
        return object.MGA_ID === element.MGA_ID;
      });

      this.ManageClientsService.saveRate(element).subscribe(Result => {
        this.dataSource.data[index].MGA_ID = Result.MGA_ID;
        this.isProcessing = false;
      });

      this.editing = false;
      return false;
    }
    else
      return true;
  }

  editRate(element: any) {
    this.editing = true;
    element.isEdit = true;
    this.originalRate = JSON.parse(JSON.stringify(element));
    this.filteredServices = this.serviceDescriptionsList.filter(f => f.SDE_CARRIER == element.MGA_CarrierName);
  }

  cancelEdit(element: any) {
    if(element.MGA_ID == -1)
      this.dataSource.data = this.dataSource.data.filter((u) => u.MGA_ID != element.MGA_ID);
    else {
      element.MGA_CarrierName = this.originalRate.MGA_CarrierName;
      element.MGA_ServiceDescription = this.originalRate.MGA_ServiceDescription;
      element.MGA_Rate = this.originalRate.MGA_Rate;
      element.MGA_Min_Weight = this.originalRate.MGA_Min_Weight;
      element.MGA_Max_Weight = this.originalRate.MGA_Max_Weight;
      element.MGA_CountryOrig_Code = this.originalRate.MGA_CountryOrig_Code;
      element.MGA_CountryDest_Code = this.originalRate.MGA_CountryDest_Code;
      element.MGA_IsFromShoppingCart = this.originalRate.MGA_IsFromShoppingCart;
      element.MGA_ShoppingCartName = this.originalRate.MGA_ShoppingCartName;
      element.MGA_ShoppingCartDescription = this.originalRate.MGA_ShoppingCartDescription;
      element.MGA_DryIce = this.originalRate.MGA_DryIce;
    }
    
    this.editing = false;
    element.isEdit = false;
    return false;
  }

  highlight(row){
    if(!this.editing)
      this.selectedRateId = row.MGA_ID;

    //return false;
  }

  selectedCarrierChanged($event) {
    this.filteredServices = this.serviceDescriptionsList.filter(f => f.SDE_CARRIER == $event.value);
  }

  getServiceDescription(Code: string): string {
    const index = this.serviceDescriptionsList.findIndex(object => {
      return object.SDE_CODE === Code;
    });

    if(index != -1)
      return this.serviceDescriptionsList[index].SDE_DESCRIPTION.trim();
    else
      return '';
  }

  validateRange(element: spCSDAManageRateGetListResult) : boolean {
    
    const index = this.dataSource.data.findIndex(rate => {

      var SameID = element.MGA_ID == rate.MGA_ID;

      var CarrierSame = element.MGA_CarrierName == rate.MGA_CarrierName;
      var ServiceSame = element.MGA_ServiceDescription == rate.MGA_ServiceDescription;
      var CountryOrigSame = (element.MGA_CountryOrig_Code == undefined ? '' : element.MGA_CountryOrig_Code) == (rate.MGA_CountryOrig_Code == undefined ? '' : rate.MGA_CountryOrig_Code);
      var CountryDestSame = (element.MGA_CountryDest_Code == undefined ? '' : element.MGA_CountryDest_Code) == (rate.MGA_CountryDest_Code == undefined ? '' : rate.MGA_CountryDest_Code);
      var MinInside   = (+element.MGA_Min_Weight >= rate.MGA_Min_Weight && +element.MGA_Min_Weight < rate.MGA_Max_Weight);
      var MaxInside   = (+element.MGA_Max_Weight > rate.MGA_Min_Weight && +element.MGA_Max_Weight <= rate.MGA_Max_Weight);
      var AllOutside   = (+element.MGA_Min_Weight <= rate.MGA_Min_Weight && +element.MGA_Max_Weight >= rate.MGA_Max_Weight);
      var IsDryIce =  element.MGA_DryIce == rate.MGA_DryIce;

      var finalState = !SameID && CarrierSame && ServiceSame && CountryOrigSame && CountryDestSame && (MinInside || MaxInside || AllOutside) && IsDryIce;

      return finalState;
    });

    if(index != -1) {
      Swal.fire('', this.translateService.instant("app.manageClients.rateRangeOverlap"), "error") 
      return false;
    }
    else
      return true;
  }
}
