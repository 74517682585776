<!-- Main container -->
<div id="printable">
    <table width="100%">
        <tbody>
            <!-- Pieces details-->
            <tr width="100%">
                <td width="100%">
                    <!-- Pieces details -->
                    <div id="piecesDetail">
                        <strong>{{"app.shipmentSummary.billingWeight" | translate }} : </strong>
                        {{data.quote.BillableWeight}} {{ data.quote.UnitOfMeasure === unitOfMeasure.Metric ? "Kgs" : "Lbs" }}
                    </div>
                </td>
            </tr>

            <!-- Options and Price estimate-->
            <tr width="100%">
                <td width="100%">
                    <!-- Estimated price -->
                    <div id="estimate">
                        <div class="estimate-title"> {{ "app.shipmentSummary.priceEstimate" | translate }}
                        </div>

                        <!-- Base price -->
                        <div style="text-align: right;">
                            <strong>{{ "app.shipmentSummary.basePrice" | translate }}</strong>&nbsp;
                            {{data.quote.Price.BasePrice | number : '.2-2'}} $
                        </div>

                        <div *ngIf="addedFees === false">
                            <strong>{{ "app.shipmentSummary.noAdditionnalFee" | translate }}</strong>
                        </div>

                        <!-- Additionnal fees title-->
                        <div *ngIf="addedFees === true">
                            <strong>{{ "app.shipmentSummary.additionnalFees" | translate }}</strong>
                        </div>

                        <!-- Additionnal Fees Table-->
                        <div *ngIf="addedFees === true">
                            <mat-table [dataSource]="additionnalFeesDataSource">
                                <ng-container matColumnDef="AdditionnalFeesType">
                                    <mat-cell *matCellDef="let element" class="mat-table-style">
                                        {{ element.Description }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Fees">
                                    <mat-cell *matCellDef="let element" class="mat-table-style">
                                        {{ element.Amount | number : '.2-2'}} $
                                    </mat-cell>
                                </ng-container>

                                <!-- Table row definition -->
                                <mat-row *matRowDef="let row; columns: feesTableColumns"></mat-row>
                            </mat-table>
                        </div>

                        <div *ngIf="taxable === true && addedFees=== true" style="text-align: right;">
                            <strong>{{ "app.shipmentSummary.subtotal" | translate }}</strong> &nbsp;
                            {{ getShipmentSubtotalPrice() | number : '.2-2'}} $
                        </div>

                        <div *ngIf="taxable === false">
                            <strong>{{ "app.misc.noTaxes" | translate }}</strong>
                        </div>

                        <!-- Taxes title-->
                        <div *ngIf="taxable === true">
                            <strong>{{ "app.shipmentSummary.taxes" | translate }}</strong>
                        </div>

                        <!-- Taxes Table -->
                        <div *ngIf="taxable === true">
                            <mat-table [dataSource]="taxesDataSource" style="border-top: 1px solid white;">
                                <ng-container matColumnDef="TaxesType">

                                    <!-- Taxe type -->
                                    <mat-cell *matCellDef="let element" class="mat-table-style">
                                        {{ changeTaxLang(element.Type) }}
                                    </mat-cell>
                                </ng-container>

                                <!-- Taxe amount -->
                                <ng-container matColumnDef="Taxe">
                                    <mat-cell *matCellDef="let element" class="mat-table-style">
                                        {{ element.Amount | number : '.2-2'}} $
                                    </mat-cell>
                                </ng-container>

                                <!-- Table row definition -->
                                <mat-row *matRowDef="let row; columns: taxesTableColumns"></mat-row>
                            </mat-table>
                        </div>

                        <!-- Total estimated price -->
                        <div style="text-align: right;">
                            <strong>{{ "app.shipmentSummary.total" | translate }}</strong> &nbsp;{{
                            data.quote.Price.TotalAmount | number : '.2-2'}} $
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Button for actions -->
<div id="notPrintable">
    <button mat-raised-button style="margin-right:15px" color="primary" printSectionId="printable"
    ngxPrint
    [useExistingCss]="true" styleSheetFile="src\app\shared\components\shipment-summary\shipment-summary.component.scss"
    >
        {{"app.misc.print" | translate}}
    </button>
    <button mat-raised-button mat-dialog-close>{{"app.misc.close" | translate}}</button>
</div>