import { Component, Input, OnInit } from '@angular/core';
import { AlertType } from "@app/core/enums/alert-type";

@Component({
    selector: 'app-box-message',
    templateUrl: './box-message.component.html',
    styleUrls: ['./box-message.component.scss'],
    standalone: false
})
export class BoxMessageComponent implements OnInit {
	@Input() alertType = AlertType.Danger;
	readonly alertTypeEnum = AlertType;
	constructor() { }

	ngOnInit(): void {
	}

}
