import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { environment } from "@env";
import { VoidResult } from "@app/core/models/VoidResult";
import { LanguageService } from "@app/core/services/language.service";
import { ValidationService } from "@app/core/services/validation.service";
import { ActiveOrdersTableModel } from "../models/active-orders-table-model";
import { GetOrdersListDetail } from "@app/shared/CSDAModels/GetOrdersListDetail";
import { GetOrdersListResult } from "@app/shared/CSDAModels/GetOrdersListResult";
import { CreatePickupsDetail } from "@app/shared/CSDAModels/CreatePickupsDetail";
import { CreatePickupsResult } from "@app/shared/CSDAModels/CreatePickupsResult";
import { GetTrackingRefreshResult } from "@app/shared/CSDAModels/GetTrackingRefreshResult";
import { GetTrackingRefreshDetail } from "@app/shared/CSDAModels/GetTrackingRefreshDetail";
import { GetTrackingRefreshResponse } from "@app/shared/CSDAModels/GetTrackingRefreshResponse";
import { GetOrderSummaryResult } from "@app/shared/CSDAModels/GetOrderSummaryResult";
import { OrderResult } from "@app/shared/CSDAModels/OrderResult";
import { GetOrderDetail } from "@app/shared/CSDAModels/GetOrderDetail";
import { GetWaybillResult } from "@app/shared/CSDAModels/GetWaybillResult";
import { VoidShipmentDetail } from "@app/shared/CSDAModels/VoidShipmentDetail";
import Swal from "sweetalert2";
import { PickupResult } from "@app/shared/CSDAModels/PickupResult";

@Injectable({
	providedIn: "root"
})
export class ShipmentService {

	/** Constructor of the ShipmentService class */
	constructor(
		private httpClient: HttpClient,
		private validationService: ValidationService,
		private translateService: LanguageService,
	) { }

	/** Get the active-orders table model for the data table of the active-orders page
	 * @returns An array of active-orders table model
	 */
	public getActiveOrdersList(searchTerm: string): Observable<ActiveOrdersTableModel[]> {

		const orderStatus: GetOrdersListDetail = {
			ListOfOrderStatus: "9",         // Get Shipments List
			Language: this.translateService.GetCurrentLanguage(),
			IsDevelopmentEnvironment: false,
			ShowVoid: false,
			StartDate: null,
			EndDate: null,
			SearchTerm: searchTerm
		};

		return this.httpClient.post<GetOrdersListResult>(environment.CSDAEndPoint + "/CSDA/GetOrdersList", orderStatus)
		.pipe(
			map(data => {
				if (data && data.OrdersList) {
					return data.OrdersList.map(response => {
						var validation = this.validationService.validate(data);
	
						if (validation.isValid === false) {
							Swal.fire('', validation.message, "error");
							return null;
						}
	
						if (validation.message !== "") {
							Swal.fire('', validation.message, "info");
						}
						
						return new ActiveOrdersTableModel(response);
					});
				} else {
					// Handle the case when data or data.OrdersList is null
					return [];
				}
			})
		);
	
	}

	/** Create new pickups 
	 * @param createPickupsDetail 
	 * @returns A CreatePickupsResult if the http request is successfull and contains no error, else return null
	 */
	public createPickups(createPickupsDetail: CreatePickupsDetail): Observable<CreatePickupsResult> {
		return this.httpClient.post<CreatePickupsResult>(environment.CSDAEndPoint + "/CSDA/CreatePickups", createPickupsDetail)
			.pipe(map((data) => {
				var pickups: PickupResult[] = [];

				// Verifies if the result is valid and return data, else display an error message and return null
				var parentValidation = this.validationService.validate(data);

				if (parentValidation.isValid === false) {
					Swal.fire('', parentValidation.message, "error");
					return null;
				}
				else {
					var validationMessage = parentValidation.message;

					data.PickupResults.forEach((pickup) => {

						var childValidation = this.validationService.validate(pickup);

						if (childValidation.isValid === false) {
							Swal.fire('', childValidation.message, "error");
						}
						else {
							validationMessage = validationMessage + childValidation.message;
							pickups.push(pickup);
						}

						if (validationMessage !== '') {
							if(!createPickupsDetail.IsNoMessage)
								Swal.fire('', validationMessage, "success");
						}
					});

					data.PickupResults = pickups;
					return (data.PickupResults !== undefined && data.PickupResults.length !== 0) ? data : null;
				}
			}));
	}

	/** Get tracking refresh response
	 * @param getTrackingRefreshDetail 
	 * @returns 
	 */
	public GetTrackingRefresh(getTrackingRefreshDetail: GetTrackingRefreshDetail): Observable<GetTrackingRefreshResponse[]> {
		return this.httpClient.post<GetTrackingRefreshResult>(environment.CSDAEndPoint + "/CSDA/GetTrackingRefresh", getTrackingRefreshDetail)
			.pipe(
				map((data) => {
					var validation = this.validationService.validate(data);

					if (validation.isValid === false) {
						Swal.fire('', validation.message, "error");
						return null;
					}

					if (validation.message !== "") {
						Swal.fire('', validation.message, "info");
					}

					return data.GetTrackingRefreshResponses !== undefined ? data.GetTrackingRefreshResponses : null;
				})
			);
	}

	/** Get the details of a specific order
	 * @param orderId 
	 * @returns 
	 */
	public GetOrderSummary(orderId: number): Observable<OrderResult> {
		const orderDetail: GetOrderDetail = {
			OrderId: orderId,
			Language: this.translateService.GetCurrentLanguage(),
			IsDevelopmentEnvironment: false
		};

		return this.httpClient.post<GetOrderSummaryResult>(environment.CSDAEndPoint + "/CSDA/GetOrderSummary", orderDetail)
			.pipe(
				map((data) => {
					var validation = this.validationService.validate(data);

					if (validation.isValid === false) {
						Swal.fire('', validation.message, "error");
						return null;
					}

					if (validation.message !== "") {
						Swal.fire('', validation.message, "info");
					}

					return data.Order !== undefined ? data.Order : null;
				})
			);
	}

	/** Get the waybill corresponding to the order id */
	public GetWaybill(orderId: number): Observable<GetWaybillResult> {

		const orderDetail: GetOrderDetail = {
			OrderId: orderId,
			Language: this.translateService.GetCurrentLanguage(),
			IsDevelopmentEnvironment: false
		};

		return this.httpClient.post<GetWaybillResult>(environment.CSDAEndPoint + "/CSDA/GetWaybill", orderDetail)
			.pipe(
				map((data) => {
					var validation = this.validationService.validate(data);

					if (validation.isValid === false) {
						Swal.fire('', validation.message, "error");
						return null;
					}

					if (validation.message !== "") {
						Swal.fire('', validation.message, "info");
					}

					return data !== undefined ? data : null;
				})
			);
	}

	/** Void a shipment
	 * @param voidShipmentDetail 
	 * @returns 
	 */
	public voidShipment(voidShipmentDetail: VoidShipmentDetail): Observable<VoidResult> {
		return this.httpClient.post<VoidResult>(environment.CSDAEndPoint + "/CSDA/VoidShipment", voidShipmentDetail)
			.pipe(
				map((data) => {
					var validation = this.validationService.validate(data);

					if (validation.isValid === false) {
						Swal.fire('', validation.message, "error");
						return null;
					}

					return data !== undefined ? data : null;
				})
			);
	}
}
