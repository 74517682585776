import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from "@app/core/services/header.service";
import { ClientsComponent } from "../Clients/Clients.component";
import { DivisionsComponent } from "../Divisions/Divisions.component";
import { UsersComponent } from "../Users/Users.component";
import { RatesComponent } from "../Rates/Rates.component";
import { RateInsurancesComponent } from "../RateInsurances/RateInsurances.component";
import { UserRightsComponent } from "../User-Rights/User-Rights.component";

@Component({
    templateUrl: './Layout.component.html',
    styleUrls: ['./Layout.component.scss'],
    standalone: false
})

export class ManageClientsViewLayoutComponent implements OnInit {

  constructor(
		private translateService: TranslateService,
		private headerService: HeaderService,
  ) { }

  @ViewChild(ClientsComponent) Clients: ClientsComponent;
  @ViewChild(DivisionsComponent) Divisions: DivisionsComponent;
  @ViewChild(UsersComponent) Users: UsersComponent;
  @ViewChild(RatesComponent) Rates: RatesComponent;
  @ViewChild(RateInsurancesComponent) RateInsurances: RateInsurancesComponent;
  @ViewChild(UserRightsComponent) UserRights: UserRightsComponent;

  ngOnInit(): void {
    //-------------------
    // Set the page title
    //-------------------
    this.headerService.setTitle(this.translateService.instant("app.manageClients.title"));

    //---------------------------------------------------------------------------------
    // Subscribe to the translate service to update the title when the language changes
    //---------------------------------------------------------------------------------
    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.headerService.setTitle(this.translateService.instant("app.manageClients.title"));
    });
  }

  getData(event) {
    if(event.index == 0)
      this.Users.getUsers(-1);
  }

  tabClick(tab) {
    if(tab.index == 4) // UserRights
    this.UserRights.getUsers();
  }  
}
