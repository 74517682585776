<mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="Rit_Id">
        <mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 60px;">
            {{'app.administration.rightId' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [attr.data-label]="'app.administration.rightId' | translate" style="max-width: 60px;">
            <span class="dots">
                {{ row.Rit_Id }}
            </span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Rit_Name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'app.administration.rightName' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [attr.data-label]="'app.administration.rightName' | translate">
            <span class="dots" *ngIf="row.Rit_Action != 'UPDATE' && row.Rit_Action != 'ADD'">
                {{ row.Rit_Name }}
            </span>
            <mat-form-field appearance="outline" *ngIf="row.Rit_Action == 'UPDATE' || row.Rit_Action == 'ADD'" class="wide-form-field">
                <mat-label>{{'app.administration.rightName' | translate}}</mat-label>
                <input matInput required id="{{row.Rit_Name}}" [(ngModel)]="row.Rit_Name"
                    [ngModelOptions]="{standalone: true}" name="{{row.Rit_Name}}" autocomplete="rights"
                    placeholder=" {{'app.administration.rightName' | translate}}" />
            </mat-form-field>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Rit_Desc">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'app.administration.rightDesc' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [attr.data-label]="'app.administration.rightDesc' | translate">
            <span class="dots" *ngIf="row.Rit_Action != 'UPDATE' && row.Rit_Action != 'ADD'">
                {{ row.Rit_Desc }}
            </span>
            <mat-form-field appearance="outline" *ngIf="row.Rit_Action == 'UPDATE' || row.Rit_Action == 'ADD'" class="wide-form-field">
                <mat-label>{{'app.administration.rightDesc' | translate}}</mat-label>
                <input matInput id="{{row.Rit_Desc}}" [(ngModel)]="row.Rit_Desc"
                    [ngModelOptions]="{standalone: true}" name="{{row.Rit_Desc}}"
                    autocomplete="rights" placeholder=" {{'app.administration.rightDesc' | translate}}" />
            </mat-form-field>
        </mat-cell>
    </ng-container>

    <!-- matTooltip="{{'app.misc.cancel' | translate}}" matTooltipPosition="above" -->
    <!-- matTooltipPosition="above" (click)="onSaveRight(row)" -->
    <!-- matTooltipPosition="above" (click)="onModifyRight(row)" -->
    <!-- matTooltipPosition="above" (click)="onRemoveRight(row)" -->

    <ng-container matColumnDef="Rit_Action">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            Actions
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
            <div style="position: relative; z-index: 1;">
              <div *ngIf="row.Rit_Action == 'UPDATE' || row.Rit_Action == 'ADD'">
                  <button style="margin-right: 10px;cursor: pointer;background: none; border: none;" matTooltip="{{'app.misc.cancel' | translate}}" matTooltipPosition="above" 
                      (click)="onCancel(row)">
                      <mat-icon style="color:red" class="material-icons">
                          cancel
                      </mat-icon>
                  </button>
                  <button style="margin-left:4px;cursor: pointer;background: none; border: none;" matTooltip="{{'app.misc.save' | translate}}" matTooltipPosition="above"
                      (click)="onSaveRight(row)">
                      <mat-icon style="color: blue" class="material-icons">
                          save
                      </mat-icon>
                  </button>
              </div>
            </div>
            <div *ngIf="row.Rit_Action != 'UPDATE' && row.Rit_Action != 'ADD'">
                <button style="margin-right: 10px;cursor:pointer;background: none; border: none;" matTooltip="{{'app.misc.edit' | translate}}" matTooltipPosition="above"
                    (click)="onModifyRight(row)">
                    <mat-icon style="color: blue" class="material-icons">
                        edit
                    </mat-icon>
                </button>
                <button style="margin-left:4px;cursor:pointer;background: none; border: none;" matTooltip="{{'app.misc.delete' | translate}}" matTooltipPosition="above"
                    (click)="onRemoveRight(row)">
                    <mat-icon style="color:red" class="material-icons">
                        delete
                    </mat-icon>
                </button>
            </div>
        </mat-cell>
    </ng-container>

    <!-- Table header definition -->
    <mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true">
    </mat-header-row>

    <!-- Table row definition -->
    <mat-row *matRowDef="let row; columns: displayedColumns">
    </mat-row>
</mat-table>

<br>

<button mat-raised-button color="primary" mat-button (click)="onAddRight()" [disabled]="!enableAdd" style="margin-left:12px">
  {{ 'app.administration.rightAdd' | translate }}
  <mat-icon class="material-icons">
      add
  </mat-icon>
</button>

<!-- The overlay to display when the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>