//#region Imports & Component definition
import { MatMenuTrigger as MatMenuTrigger } from "@angular/material/menu";

import { HeaderService } from "@app/core/services/header.service";
import { StorageService } from "@app/core/services/storage.service";
import { AddressContainer } from "@app/shared/CSDAModels/AddressContainer";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Component, Input, OnInit, NgZone, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Countries } from "@app/shared/CSDAModels/Countries";
import { Provinces } from "@app/shared/CSDAModels/Provinces";
import { Observable } from 'rxjs';
import { AddressBookService } from "../../services/address-book.service";
import { AddContacts } from "@app/shared/CSDAModels/AddContacts";
import { Router } from "@angular/router";
import { AddModifyAddressDetail } from "@app/shared/CSDAModels/AddModifyAddressDetail";
import { Language } from "@app/core/enums/Language";
import { spGetAddressListForDivisionResult } from "@app/shared/CSDAModels/spGetAddressListForDivisionResult";
import { environment } from "@env";
import { NgForm } from "@angular/forms";
import { Type } from "@app/shared/CSDAModels/Type";

import PlaceResult = google.maps.places.PlaceResult;
import AutocompleteOptions = google.maps.places.AutocompleteOptions;
import Swal from "sweetalert2";

export enum Appearance {
  STANDARD = 'standard',
  FILL = 'fill',
  OUTLINE = 'outline',
}

@Component({
    selector: 'address-editor-form',
    templateUrl: './address-editor.component.html',
    styleUrls: ['./address-editor.component.scss'],
    standalone: false
})
//#endregion

export class AddressEditorComponent implements OnInit {
  //#region Inputs, Outputs and ViewChild
  @Input() appearance: string | Appearance = Appearance.OUTLINE;
  
  //----------------------------------------------------------------
  // This allow access to the form (NgForm) and its controls
  // excepts the contacts which are standalone because it's an array
  //----------------------------------------------------------------
  @ViewChild('addressEditorForm', { static: true }) addressEditorForm: NgForm;

  @ViewChild("SearchAddress") searchAddressElement: ElementRef;
  @ViewChild("UnitElement") unitElement: ElementRef;
  //#endregion
  //#region Properties
  isProcessing = false;
  
  //--------------------------------------------------------------------------------------------------
  // The arrays of addresses filtered based on the auto complete search box
  // as well as the list of addresses, countries and states which were loaded when the user logged in.
  // See authentication.service.ts in app/core/services
  //--------------------------------------------------------------------------------------------------
  originAddresses: Observable<any>
  countriesList: Countries[];
  statesProvincesList: Provinces[];
  FilteredStateProvinces: Provinces[];
  autoCompleteOptions: AutocompleteOptions = {};
  AutoCompleteAddressesList: spGetAddressListForDivisionResult[];
  
  //----------------------------------------------------------------------------------------------
  // We are using Template-Driven form from Angular with Two Way Data Binding
  // No need to create a form, set the values and get the values back from the form
  // Everything is handled by the NgForm, see the attribut on the form tag in the html
  //
  // This is the model bound to the form It is bound via the ngModel attribute on all the matInput
  // It's important for the input to have the attribut name set 
  //----------------------------------------------------------------------------------------------
  AddressContainerModel: AddressContainer;
  DefaultAddressTypes: Type[];


  //#endregion
  //#region Constructor
  constructor(
		private headerService: HeaderService,
		private translateService: TranslateService,
		private storageService: StorageService,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef,
    private router: Router,
    private addressBookService: AddressBookService
  ) { }
  //#endregion
  //#region OnInit
  //---------------------------------------------------
  // Enable display and set default values of the forms
  //---------------------------------------------------
  ngAfterViewInit() {
    setTimeout(() => {
      //--------------------------------------------------------------------------------------------------
      // keep the lists countries and states/provinces from the user session which was obtained
      // when the user logged in.  See in app/core/services/authentication.service.ts
      //--------------------------------------------------------------------------------------------------
      this.countriesList = JSON.parse(sessionStorage.getItem("Countries")) as Countries[];
      this.statesProvincesList = JSON.parse(sessionStorage.getItem("Provinces")) as Provinces[];
      
      this.changeCountryEvent();
      this.cd.detectChanges();

    }, 0);
  }

  ngOnInit(): void {
    //---------------------------------------------------------------------------------------------
    // Gets the address from the storage which the user either selected Edit of an existing address
    // or clicked the Add button to add a new address.  getAddress always return an instance
    //---------------------------------------------------------------------------------------------
    this.AddressContainerModel = this.storageService.getAddress();
    this.DefaultAddressTypes = JSON.parse(sessionStorage.getItem("DefaultAddressTypes")) as Type[];

    var Title:string = "";

    //-----------------------------------------------------------------
    // If we are editing an existing address or adding a new one we set 
    // the appropriate title resource.
    //-----------------------------------------------------------------
    if(this.AddressContainerModel.Address.ADD_ID != -1)
      Title = "app.addressBook.editorTitleEdit";
    else
      Title = "app.addressBook.editorTitleAdd";

    //-------------------
    // Set the page title
    //-------------------
    this.headerService.setTitle(this.translateService.instant(Title));

    //---------------------------------------------------------------------------------
    // Subscribe to the translate service to update the title when the language changes
    //---------------------------------------------------------------------------------
    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.headerService.setTitle(this.translateService.instant(Title));
    }); 
  }
  //#endregion
  //#region Countries & States / Provinces handling
  changeCountryEvent(): void {
    var htmlInputElement: HTMLInputElement = null;

    if(this.searchAddressElement)
      htmlInputElement = this.searchAddressElement.nativeElement;
    
    //----------------------------------- 
    // Gets our selected country instance
    //----------------------------------- 
    const selectedCountry = this.countriesList.find(c => c.CON_ID == this.AddressContainerModel.Address.ADD_CON_Ref);

    if(selectedCountry) {
      const options: AutocompleteOptions = {
        types: ['address'],
        componentRestrictions: {country: selectedCountry.CON_Code},
      };
      
      this.autoCompleteOptions = Object.assign(this.autoCompleteOptions, options);
      this.initGoogleMapsAutocomplete();

        //---------------------------------------------------------
      // By default we enable the zip code and state controls
      // Bellow the clode will check if they needs to be disabled
      //---------------------------------------------------------
      this.addressEditorForm.controls["ADD_Zip"].enable();
      this.addressEditorForm.controls["ADD_PRV_Ref"].enable();

      if(htmlInputElement)
        htmlInputElement.disabled = false;

      //---------------------------------------------------------------------------------------------
      // Our Countries table contains a flag to tell us if we need to enter a zip code and a province
      // In case the CON_ZipEmpty is true we disable the zip code and state controls
      //---------------------------------------------------------------------------------------------
      if(selectedCountry.CON_ZipEmpty) {
        this.AddressContainerModel.Address.ADD_Zip = null;
        this.AddressContainerModel.Address.ADD_PRV_Ref = null;
        this.addressEditorForm.controls["ADD_Zip"].disable();
        this.addressEditorForm.controls["ADD_PRV_Ref"].disable();
      }
      else {
        //---------------------------------------------------------
        // Get the states / provinces based on the selected country
        //---------------------------------------------------------
          this.FilteredStateProvinces = this.statesProvincesList.filter(f => f.PRV_CON_REF === this.AddressContainerModel.Address.ADD_CON_Ref);

          //----------------------------------------------------------------
          // If we don't have any states listed we disable the state control
          //----------------------------------------------------------------
          if(this.FilteredStateProvinces.length == 0)
            this.addressEditorForm.controls["ADD_PRV_Ref"].disable();
      }
    }
    else {
      if(htmlInputElement)
        htmlInputElement.disabled = true;
    }
  }
  //#endregion
  //#region Methods
  onSubmit() {
    
    if(this.addressEditorForm.valid && this.contactsValid()) {
      this.isProcessing = true;

      this.saveAddress((SaveResult: boolean) => {
        this.isProcessing = false;
        if(SaveResult) {
          Swal.fire({
            icon: "info",
            title: this.translateService.instant("app.addressEditor.addressSaved"),
            confirmButtonText: 'OK'
          }).then((sweetAlertResult) => {
              this.router.navigate(["/addressbook"]);
          });
        }
      });
    }
  }

  saveAddress(CallBack: { (SaveResult: boolean): void; (arg0: boolean): void; }) {
    //---------------------------------------------------------------------
    // Ensure that if we have only one contact we set the IsDefault to true
    //---------------------------------------------------------------------
    if(this.AddressContainerModel.Contacts.length == 1)
      this.AddressContainerModel.Contacts[0].ADC_IsDefault = true;

    var Detail: AddModifyAddressDetail = {
      AddressContainer: this.AddressContainerModel,
      Language: Language.English,
      IsDevelopmentEnvironment: false
    }

    this.addressBookService.saveAddress(Detail).subscribe(Result => {
      if(Result != null) {
        if(Result.IsValid) {
            this.AutoCompleteAddressesList = Result.AddressesList;

          sessionStorage.setItem("AddressesList", JSON.stringify(this.AutoCompleteAddressesList));
        }
      }

      if(CallBack)
        CallBack(Result == null ? false : true);
    });
  }

  contactsValid(): boolean {
    //---------------------------------------------------------------
    // Validate contacts in a Template-Driven Two Way Binding context
    //---------------------------------------------------------------
    var element: AddContacts;

    for(element of this.AddressContainerModel.Contacts) {
      if(!element.ADC_Name || !element.ADC_Email || !element.ADC_Phone) {
        return false;
      }
    }
    
    return true;
  }

  onAddContact() {
    this.AddressContainerModel.Contacts.push({
      ADC_ID: 0,
      ADC_ADD_Ref: this.AddressContainerModel.Address.ADD_ID,
      ADC_Name: "",
      ADC_Email: "",
      ADC_EmailNotification: null,
      ADC_Phone: "",
      ADC_PhoneExt: "",
      ADC_Fax: "",
      ADC_Pager: "",
      ADC_IsDefault: false,
      ADC_Typ_Ref: 26 // Not Assigned
    });
  }

  onRemoveContact(contact: AddContacts)
  {
    this.AddressContainerModel.Contacts = this.AddressContainerModel.Contacts.filter(item => item !== contact);

    if(this.AddressContainerModel.Contacts.length == 1)
      this.AddressContainerModel.Contacts[0].ADC_IsDefault = true;
  }

  toggleDefaultContact(contact: AddContacts) {
    this.AddressContainerModel.Contacts.forEach((item) => {
      if(item != contact)
        item.ADC_IsDefault = false;
    });
  }

  onChangeDefaultAddress(contact: AddContacts, menuTrigger: MatMenuTrigger) {
    this.AddressContainerModel.Contacts.forEach((item, index) => {
      if(item != contact)
        this.AddressContainerModel.Contacts[index].ADC_Typ_Ref = 26 // Not assigned;
    });
    
    menuTrigger.closeMenu();
  }

  //#endregion
  //#region Google Places
  //---------------------------------------------------------------------
  // Installation requise pour Google : 
  // 
  // npm install ngx-google-places-autocomplete@latest
  //---------------------------------------------------------------------
  public initGoogleMapsAutocomplete(): void {
    const htmlInputElement: HTMLInputElement = this.searchAddressElement.nativeElement;
    const autocomplete = new google.maps.places.Autocomplete(htmlInputElement, this.autoCompleteOptions);
    htmlInputElement.autocomplete = "off";
        
    var streetNo: any;
    var streetName: any;
    var zipCode: any;
    var municipality: any;
    var cityName: any;
    var countryCode: any;
    var countryName: any;
    var stateCode: any;
    var stateName: any;

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: PlaceResult = autocomplete.getPlace();
        //------------------------------------------
        // Sometimes I don't get data from google ??
        //------------------------------------------
        if(place.address_components) {
          place.address_components.forEach(value => {
            if (value.types.indexOf('street_number') > -1) {
              streetNo = value.short_name;
            }

            if (value.types.indexOf('route') > -1) {
              streetName = value.long_name;
            }

            if (value.types.indexOf('postal_code') > -1) {
              zipCode = value.long_name;
            }
            
            if (value.types.indexOf('sublocality') > -1) {
              municipality = value.long_name;
            }

            if (value.types.indexOf('locality') > -1) {
              if(municipality)
                cityName = municipality;
              else
                cityName = value.long_name;
            }

            if (value.types.indexOf('administrative_area_level_1') > -1) {
              stateCode = value.short_name;
              stateName = value.long_name;
            }
            if (value.types.indexOf('country') > -1) {
              countryCode = value.short_name;
              countryName = value.long_name;
            }
          });

          //------------------------------------
          // Lookups for country id and state id
          //------------------------------------
          const countryID = this.countriesList.find(a => a.CON_Code == countryCode).CON_ID;

          //----------------------------------------------------------
          // Assign the values from Google to the controls on the form
          //----------------------------------------------------------
          this.AddressContainerModel.Address.ADD_StreetName = streetNo + " " + streetName;
          this.AddressContainerModel.Address.ADD_Zip = zipCode;

          //----------------------------------------------------------------------------------
          // On a pas ce type de donnée dans l'interface mais je le conserve quand même au cas
          // Example: Ville-Marie dans Montréal
          //----------------------------------------------------------------------------------
          //AddressSection.controls["municipality"].setValue(municipality);

          this.AddressContainerModel.Address.ADD_City = cityName;
          this.AddressContainerModel.Address.ADD_CON_Ref = countryID;
          
          //---------------------------------------------------------------
          // We have to set the state AFTER the change country is completed
          // in order to have the list of state ready for the state change
          //---------------------------------------------------------------
          this.changeCountryEvent();
          var stateID = -1;

          if(this.FilteredStateProvinces)
            stateID = this.FilteredStateProvinces.find(a => a.PRV_Code == stateCode).PRV_ID;

            this.AddressContainerModel.Address.ADD_PRV_Ref = stateID;
            this.AddressContainerModel.Address.ADD_City = cityName;

          this.searchAddressElement.nativeElement.value = "";
          this.unitElement.nativeElement.focus();
        }
      });
    });
  }
  //#endregion
}
