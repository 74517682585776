import { MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource as MatTableDataSource } from "@angular/material/table";

import { Component, OnInit, Inject } from '@angular/core';
import { Carrier } from "@app/core/enums/carrier";
import { TranslateService } from "@ngx-translate/core";
import { OrderResult } from "@app/shared/CSDAModels/OrderResult";
import { OrdersSurcharges } from "@app/shared/CSDAModels/OrdersSurcharges";
import { OrdersTaxes } from "@app/shared/CSDAModels/OrdersTaxes";

@Component({
    selector: 'app-shipment-summary',
    templateUrl: './shipment-summary.component.html',
    styleUrls: ['./shipment-summary.component.scss'],
    standalone: false
})
export class ShipmentSummaryComponent implements OnInit {

  /** Enums with all the possible carriers or languages*/
  carrier = Carrier;
  currentLanguage: string;

  /** Flag to display the taxes and additionnal fees sections */
  taxable: boolean = true;
  addedFees: boolean = true;

  /** Variables for the packages data table */
  packagesDataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [
    "PackageType",
    "Quantity",
    "Width",
    "Height",
    "Depth",
    "Weight",
    "VolumeWeight"
  ];

  /** Variables for the packages data table */
  additionnalFees: OrdersSurcharges[] = [];
  additionnalFeesDataSource = new MatTableDataSource([]);
  feesTableColumns: string[] = [
    "AdditionnalFeesType",
    "Fees"
  ];

  /** Variables for the packages data table */
  taxes: OrdersTaxes[] = [];
  taxesDataSource = new MatTableDataSource([]);
  taxesTableColumns: string[] = [
    "TaxesType",
    "Taxe"
  ];

  /** Constructor of the ShipmentSummaryComponent class */
  constructor(
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {
      orderSummary: OrderResult
    }
  ) { }

  /** Set the packages details for the package details data table */
  ngOnInit(): void {

    /** Create the data table for the additionnal fees and removing the 0 values */
    if (this.data.orderSummary.Surcharges != null) {
      this.data.orderSummary.Surcharges.forEach(element => {
        if (element && element.OSU_Amount !== 0) {
          this.additionnalFees.push(element);
        }
      });
      this.additionnalFeesDataSource = new MatTableDataSource(this.additionnalFees);
    }
    else {
      this.addedFees = false;
    }
    
    /** Create the data table for the taxes and removing the 0 values*/
    if (this.data.orderSummary.Taxes != null) {
      this.data.orderSummary.Taxes.forEach(element => {
        if (element && element.OTA_Amount !== 0) {
          this.taxes.push(element);
        }
      });
      this.taxesDataSource = new MatTableDataSource(this.taxes);
    }
    else {
      this.taxable = false;
    }

    /** Create the data table for the packages */
    this.packagesDataSource = new MatTableDataSource(this.data.orderSummary.Packages);


    /** Set the default language a the opening of the page */
    this.currentLanguage = this.translateService.currentLang;
  }

  GetDitiesAndTaxesPaidBy(value) {
    if(this.currentLanguage == "fr") {
      if(value == "RECEIVER")
        return "DESTINATAIRE"
      else
        return "EXPÉDITEUR"
    }

    return value;
  }

  IsDutiable() {
    return !this.data.orderSummary.Quote.OQU_IsDocumentOnly && (this.data.orderSummary.OriginAddress.OAD_Country != "CA" || this.data.orderSummary.DestinationAddress.OAD_Country != "CA")
  }
  /** Get the subtotal price of the shipment, to the base price we add all the additionnal fees 
   * @return The estimation of the subtotal before taxes
   */
  getShipmentSubtotalPrice(): number {

    /** The estimate start with the base price */
    var estimate = this.data.orderSummary.Quote.OQU_BasePrice;

    /** Then all the additionnal fees are added */
    if (this.data.orderSummary.Surcharges.length > 0) {
      this.data.orderSummary.Surcharges.forEach((item) => estimate = estimate + item.OSU_Amount)
    }

    return estimate;
  }

  /** Get the Volumetric Weight in kilograms rounded up to the nearest 0.5kg, if smaller than 0.5 it is rounded to 0.5
   * @returns volumetric weight in kg, return 0.5 if the volume is smaller than 0.5kg
   */
  getVolumeWeight(index: number): number {
    var volumetricWeight = 0;
    var factor = 0;

    if(this.data.orderSummary.Order.ORD_UnitOfMeasure == "Imperial")
      factor = 139;
    else
      factor = 5000;

    /** Get the volumetric weight */
    volumetricWeight = (
      this.data.orderSummary.Packages[index].OPA_Length
      * this.data.orderSummary.Packages[index].OPA_Width
      * this.data.orderSummary.Packages[index].OPA_Height / factor);

    /** Round up to the nearest 0.5kg value */
    let roundedVolumeWeight = Math.ceil(volumetricWeight * 2) / 2

    /** Always return a minimum of 0.5 kg */
    return this.data.orderSummary.Packages[index].OPA_Quantity * Math.max(0.5, roundedVolumeWeight);
  }

  /** Get the total billing weight
   * @returns the greater value between the volumetric weight and the total weight
   */
  getTotalBillingWeight(): number {
    var billingWeight = 0;
    var volumetricWeight = 0;

    var factor = 0;

    if(this.data.orderSummary.Order.ORD_UnitOfMeasure == "Imperial")
      factor = 139;
    else
      factor = 5000;

    /** Get the volumetric weight */
    if (this.data.orderSummary.Packages.length > 0) {

      this.data.orderSummary.Packages.forEach((pk) => {

        volumetricWeight = pk.OPA_Length * pk.OPA_Width * pk.OPA_Height / factor;

        /** Round up to the nearest 0.5kg value */
        let roundedVolumeWeight = Math.ceil(volumetricWeight * 2) / 2;
        roundedVolumeWeight = Math.max(0.5, roundedVolumeWeight);

        billingWeight = billingWeight + pk.OPA_Quantity * (pk.OPA_Weight > roundedVolumeWeight ? pk.OPA_Weight : roundedVolumeWeight);
      });
    }

    return billingWeight;
  }
  //-------------------------------------------------------------------------------
  // Marc: This method gets the total pieces of all packages to show on the summary
  //-------------------------------------------------------------------------------
  getTotalPieces(): number {
    return this.data.orderSummary.Packages.reduce((sum: number, x) => sum + x.OPA_Quantity, 0);
  }
}
