import { MatTableDataSource as MatTableDataSource } from "@angular/material/table";
import { MAT_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions as MatTooltipDefaultOptions } from '@angular/material/tooltip';

import { TranslateService } from "@ngx-translate/core";
import { spCSDAManageUserGetListResult } from "@app/shared/CSDAModels/ManageClientModels/User/spCSDAManageUserGetListResult";
import { ManageClientsService } from "../_Services/manage-clients.service";
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { DatePipe } from "@angular/common";
import { v4 as uuidv4 } from 'uuid';
import { Subscription } from "rxjs";
import Swal from "sweetalert2";

const matTooltipDefaultOptions: MatTooltipDefaultOptions = {
  disableTooltipInteractivity: true,
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0
};

@Component({
    selector: 'Users',
    templateUrl: './Users.component.html',
    styleUrls: ['./Users.component.scss'],
    providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipDefaultOptions }],
    standalone: false
})

export class UsersComponent implements OnInit {
  private subscription: Subscription;
  
  //---------------------------------------------------
  // Define the fields of the model which are displayed
  //---------------------------------------------------
  COLUMNS_SCHEMA = this.getSchema();
  displayedColumns: string[] = this.COLUMNS_SCHEMA.map((col) => col.key);
  dataSource = new MatTableDataSource([]);
  isProcessing = false;
  public Div_Id = -1;
  selectedUserId = -1;
  originalUser: spCSDAManageUserGetListResult;
  editing = false;

  constructor(
    private translateService: TranslateService,
    private ManageClientsService: ManageClientsService,
  ) { }

  @Output('selectedUser') selectedUser: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("password") passwordElement: ElementRef;

  ngAfterViewInit() {
  }

  ngOnInit(): void {
    this.subscription = this.ManageClientsService.divisionDeactivated.subscribe(() => {
      this.getUsers(null);
    });    
  }

  getSchema() {
    return [
      // {
      //   key: 'isSelected',
      //   type: 'isSelected',
      //   label: '',
      //   visible: true
      // },
      {
        key: 'USR_FirstName',
        type: 'text',
        label: "Prénom",
        labelEn: 'First Name'
      },
      {
        key: 'USR_LastName',
        type: 'text',
        label: "Nom",
        labelEn: 'Last Name'
      },
      {
        key: 'USR_API',
        type: 'checkboxAPI',
        label: "Utilise API",
        labelEn: 'Use API'
      },
      {
        key: 'USR_UserName',
        type: 'text',
        label: "Nom d'usager",
        labelEn: 'User Name'
      },
      {
        key: 'USR_Password',
        type: 'password',
        label: 'Mot de passe',
        labelEn: 'Password'
      },
      {
        key: 'USR_Email',
        type: 'text',
        label: "Courriel",
        labelEn: 'Email'
      },
      {
        key: 'USR_Phone',
        type: 'text',
        label: "Téléphone",
        labelEn: 'Phone'
      },
      {
        key: 'isEdit',
        type: 'isEdit',
        label: ''
      }
    ];
  }

  getUsers(id: any) {
    if (id != -1) {
      const UserId = id != -1 ? id : this.Div_Id;
      this.Div_Id = UserId;
      this.isProcessing = true;
      this.editing = false;
      this.selectedUserId = -1;

      this.ManageClientsService.getUsers(UserId).subscribe(Result => {
        this.dataSource = new MatTableDataSource(Result.UsersList);
        this.dataSource.sort = this.sort;
        this.isProcessing = false;
      })
    } else {
      this.dataSource = null;
    }
  }

  addRow() {
    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(new Date(), 'yyyy-MM-dd');

    const newRow = {
      USR_ID: -1,
      USR_UserName: '',
      USR_Password: '',
      USR_IsActive: true,
      USR_CreationDate: formattedDate,
      USR_LastLogin: formattedDate,
      USR_Email: '',
      USR_Phone: '',
      USR_FirstName: '',
      USR_LastName: '',
      USR_API: false,
      USR_CSAPI_Token: '',
      USR_Div_Ref: this.Div_Id,
      USR_Add_Ref: null,
      USR_System: null,
      isEdit: true,
    };

    this.editing = true;
    this.dataSource.data = [newRow, ...this.dataSource.data];
  }

  removeRow(element) {
    Swal.fire({
      title: 'Confirmation',
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.isProcessing = true;

        this.ManageClientsService.deleteUser(element).subscribe(Result => {
          this.isProcessing = false;
          this.dataSource.data = this.dataSource.data.filter((u) => u.USR_ID != element.USR_ID);
        });
      }
    });
  }

  removeSelectedRows() {
    Swal.fire({
      title: 'Confirmation',
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.dataSource.data = this.dataSource.data.filter((u: any) => !u.isSelected);
      }
    });
  }

  saveChanges(element: any): boolean {
    this.isProcessing = true;

    const index = this.dataSource.data.findIndex(object => {
      return object.USR_ID === element.USR_ID;
    });

    this.ManageClientsService.saveUser(element).subscribe(Result => {
      this.dataSource.data[index].USR_ID = Result.USR_ID;
      this.isProcessing = false;
    });

    this.editing = false;
    return false;
  }

  editUser(element: any) {
    this.editing = true;
    element.isEdit = true;
    this.originalUser = JSON.parse(JSON.stringify(element));

    setTimeout(() => {
      this.passwordElement.nativeElement.disabled = element.USR_API;
    }, 100);

    return false;
  }

  cancelEdit(element: any) {
    if (element.USR_ID == -1)
      this.dataSource.data = this.dataSource.data.filter((u) => u.USR_ID != element.USR_ID);
    else {
      element.USR_API = this.originalUser.USR_API;
      element.USR_UserName = this.originalUser.USR_UserName;
      element.USR_Password = this.originalUser.USR_Password;
      element.USR_Email = this.originalUser.USR_Email;
      element.USR_Phone = this.originalUser.USR_Phone;
      element.USR_FirstName = this.originalUser.USR_FirstName;
      element.USR_LastName = this.originalUser.USR_LastName;
    }

    this.editing = false;
    element.isEdit = false;
    return false;
  }

  highlight(row) {
    if (!this.editing)
      this.selectedUserId = row.USR_ID;
  }

  passwordHandler(element: spCSDAManageUserGetListResult) {
    if (element.USR_API) {
      element.USR_Password = uuidv4();
      this.passwordElement.nativeElement.disabled = true;
    } else {
      if(this.originalUser)
        element.USR_Password = this.originalUser.USR_Password;
      else
        element.USR_Password = "";
        
      this.passwordElement.nativeElement.disabled = false;
    }
  }
}
