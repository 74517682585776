<!-- Quotes card template -->
 <br>
 <span style="font-family: Calibri;font-size: 24px;font-weight: 700;text-align: left;">{{ "app.quotes.selectYourQuote" | translate }}</span>
 <br>
<div class="quotes-list">
  <div class="quotes-list__cards-container">
    <ng-container *ngFor="let quote of quotes">
      <mat-card appearance="outlined" class="card--properties">
        <span style="background-color: orange;color:white;width:100%" *ngIf="order.IsSignatureRequired && quote.IsSignatureUnavailable">{{ "app.misc.signatureUnavailable" | translate }}</span>
        <!-- <span *ngIf="!order.IsSignatureRequired" style="width:100%">&nbsp;</span> -->
        <!-- Card header that contains the carrier name and the service type -->
        <div *ngIf="quote.CarrierName === carrier.DHL" class="card--dhl-icon">
        </div>
        <div *ngIf="quote.CarrierName === carrier.FedEx" class="card--fedex-icon">
        </div>
        <div *ngIf="quote.CarrierName === carrier.Purolator" class="card--purolator-icon">
        </div>
        <div *ngIf="quote.CarrierName === carrier.Globex" class="card--globex-icon">
        </div>
        <div *ngIf="quote.CarrierName === carrier.Loomis" class="card--loomis-icon">
        </div>
    <mat-card-header>
          <!-- Carrier -->
          <mat-card-title>
          </mat-card-title>

          <!-- Service Type -->
          <mat-card-subtitle>
            <span style="font-family: Calibri;font-size: 14px;font-weight: 700;text-align: center;line-height: 28px;">{{quote.ServiceDescription}}</span>
          </mat-card-subtitle>
        </mat-card-header>

        <div *ngIf="quote.Price.BasePrice == 0" style="display:flex;flex-direction: column;">
          <span style="font-size: 14px;color: green;font-weight: bold;">
            {{ 'app.globexMessage.basePriceZero' | translate }}
          </span>
          <span style="font-size: 14px;color: green;font-weight: bold;">
            1-800-359-2111
          </span>
        </div>

        <div *ngIf="quote.Price.BasePrice > 0">
          <!-- Card content that display the total price and the pickup date -->
          <mat-card-content class="card__content">
            <!-- Total amount -->
            <div class="card__content--price">
              ${{quote.Price.TotalAmount.toFixed(2)}} <span style="font-weight: 700;font-size: 20px;color:red">{{ getCurrency(quote) }}</span>
            </div>

            <!-- Delivery date -->
            
            <!----------------------------------------------------------------------------------------------->
            <!-- Quand FedEx retourne une année 0001 c'est parce que la livraison tombe dans un jour férié -->
            <!----------------------------------------------------------------------------------------------->
            <div *ngIf="quote.DeliveryDate.startsWith('0001')" style="font-family: Calibri;font-size: 20px;font-weight: 700;text-align: center;">
              <span style="color:red;font-weight: bold;">{{ 'app.misc.noETA' | translate }}</span>
            </div>
            <div class="card__content--date" *ngIf="!quote.DeliveryDate.startsWith('0001')" style="font-family: Calibri;font-size: 20px;font-weight: 700;text-align: center;">
              <!-- Dans le cas de Purolator et Loomis, leur quotes n'ont pas d'heure alors on enlève le 12:00AM visuellement, demandé par Patrick Légaré -->
              {{ 
                quote.CarrierName === carrier.Purolator || quote.CarrierName === carrier.Loomis ? quote.DeliveryDate.replace("12:00 AM", "") : quote.DeliveryDate.split(' ').slice(0, 2).join(' | ') + ' ' + quote.DeliveryDate.split(' ').slice(2).join(' ')
              }}
            </div>
            </mat-card-content>
          <div style="position:absolute;left:10px;bottom: 10px;font-size: 1em !important;">
            <strong>{{ quote.SubCarrierName }}</strong>&nbsp;
          </div>
          <span *ngIf="checkForNonDoc(quote)" style="position:absolute;left:120px;bottom:60px;color:red;font-weight: bold;font-size:small;">{{ 'app.misc.nonDocs' | translate }}</span>
          <div class="small-buttons">
            <div class="buttons">
              <button *ngIf="!isQuickQuote || isGuest" mat-raised-button color="primary" matStepperNext (click)="viewQuote(quote)" style="border-radius: 20px;">
                <span>&nbsp;{{ "app.quotes.select" | translate }}</span>
              </button>
              <button mat-raised-button color="primary" style="margin-left:8px;margin-right: 23px;border-radius: 20px;background-color: rgb(144, 144, 144);" (click)="getCostBreakdown(quote)">
                <!-- <mat-icon class="icon material-icons">details</mat-icon> -->
                &nbsp;{{ "app.quotes.detail" | translate }}
              </button>
            </div>
          </div>          
    </div>
      </mat-card>
    </ng-container>
  </div>
  <br>

  <div>
    <!-- Display message from the API if needed -->
    <div *ngIf="message!==''" style="white-space: pre-line; background-color: #d1ecf1; width: 100%;">{{message}}</div>
    <br>
    <!-- Submission conditions -->
    <span style="font-family: Calibri;font-size: 16px;font-weight: 700;text-align: center;"> {{ 'app.globexMessage.quotesConditions' | translate }}</span><br><br>

    <!-- Button to go back to STEP 1-->
    <button mat-raised-button color="primary" matStepperPrevious (click)="returnToPreviousStep()">
      <!-- <mat-icon class="icon material-icons">west</mat-icon> -->
      &nbsp;{{ "app.quotes.return" | translate }}
    </button>
  </div>

</div>
