<div class="product-container">
  <div class="product-container__filter">
    <div style="display: flex; align-items: center; gap: 8px;margin-bottom:20px">
      <mat-form-field appearance="outline" style="width: 300px; height: 56px; margin: 0;">
        <input matInput [(ngModel)]="searchText" name="searchText" (keyup)="applyFilter($event.target.value)" placeholder="{{ 'app.shopifyProducts.filter' | translate }}" />
      </mat-form-field>
    
      <button mat-flat-button color="primary" (click)="fetchProducts()"
              style="height: 56px; line-height: 56px; padding: 0 16px; display: flex; align-items: center;">
        <mat-icon style="margin-right: 5px;">download</mat-icon>
        {{ "app.shopifyProducts.fetch" | translate }}
      </button>
      <button mat-flat-button color="primary" style="height: 56px; line-height: 56px; padding: 0 16px; display: flex; align-items: center;" (click)="importProducts()">
        {{ 'app.shopifyProducts.importUpdate' | translate }}
        <mat-icon style="margin-right: 5px">import_export</mat-icon>
      </button>
    </div>
  
  </div>
  <div class="product-container__table">
    <span *ngIf="products" style="font-size: 14;margin-bottom: 8px;">
      <b>
        {{ 'app.shopifyOrders.details.productsCount' | translate }} : {{ products.length }}
      </b>
    </span>
      <table mat-table [dataSource]="dataSource" matSort matSortStart="desc"
      class="my-table mat-elevation-z8 custom-table">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "app.shopifyProducts.name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "app.shopifyProducts.description" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.Description }}
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="customTH" id="quantity">
          {{ "app.shopifyProducts.quantity" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="customTD">{{ element.Quantity }}</td>
      </ng-container> -->

      <ng-container matColumnDef="packageWidth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="customTH" id="packageWidth">
          {{ "app.shopifyProducts.width" | translate }} ({{ IsMetric ? "cm" : "in"}})
        </th>
        <td mat-cell *matCellDef="let element" class="customTD">{{ element.PackageWidth }}</td>
      </ng-container>

      <ng-container matColumnDef="packageLength">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="customTH" id="packageLength">
          {{ "app.shopifyProducts.length" | translate }} ({{ IsMetric ? "cm" : "in"}})
        </th>
        <td mat-cell *matCellDef="let element" class="customTD">{{ element.PackageLength }}</td>
      </ng-container>

      <ng-container matColumnDef="packageHeight">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="customTH" id="packageHeight">
          {{ "app.shopifyProducts.height" | translate }} ({{ IsMetric ? "cm" : "in"}})
        </th>
        <td mat-cell *matCellDef="let element" class="customTD">{{ element.PackageHeight }}</td>
      </ng-container>

      <ng-container matColumnDef="packageWeight">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="customTH" id="packageWeight">
          {{ "app.shopifyProducts.weight" | translate }} ({{ IsMetric ? "kg" : "lb"}})
        </th>
        <td mat-cell *matCellDef="let element" class="customTD">{{ element.PackageWeight }}</td>
      </ng-container>

      <ng-container matColumnDef="shippingSurcharge">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="customTH" id="shippingSurcharge">
          {{ "app.shopifyProducts.shippingSurcharge" | translate }} $
        </th>
        <td mat-cell *matCellDef="let element" class="customTD">{{ element.ShippingSurcharge }}</td>
      </ng-container>

      <ng-container matColumnDef="packageType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="customTH" id="packageType">
          {{ "app.shopifyProducts.packageType" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="customTD">{{ element.PackageType }}</td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="customTH" id="country">
          {{ "app.shopifyProducts.country" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="customTD">{{ getCountryName(element.CountryId) }}</td>
      </ng-container>

      <ng-container matColumnDef="hscode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="customTH" id="hscode">
          {{ "app.shopifyProducts.hscode" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="customTD">{{ element.HSCode }}</td>
      </ng-container>

      <ng-container matColumnDef="unitValue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="customTH" id="unitValue">
          {{ "app.shopifyProducts.unitvalue" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="customTD">{{ element.UnitValue }}</td>
      </ng-container>

      <!-- Update product column definition -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="buttonColumn">
          {{ "app.shopifyProducts.action" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="action-link" class="buttonColumn">
          <mat-icon *ngIf="!element.CubiId">
            check
          </mat-icon>
          <!-- <button mat-mini-fab color="primary" (click)="openDialog('Update', element)">
            <mat-icon>border_color</mat-icon>
          </button> -->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
<app-processing-overlay *ngIf="isLoading" [message]=" 'app.misc.processing' | translate "></app-processing-overlay>