import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { ValidationService } from "@app/core/services/validation.service";
import { Observable, Subject, throwError } from "rxjs";
import { Language } from "@app/core/enums/Language";
import { environment } from "@env";
import Swal from "sweetalert2";
import { catchError, map } from "rxjs/operators";
import { GetManageClientListDetail } from "@app/shared/CSDAModels/ManageClientModels/Client/GetManageClientListDetail";
import { UsersRights } from "@app/shared/CSDAModels/UserRights";
import { GetManageClientListResult } from "@app/shared/CSDAModels/ManageClientModels/Client/GetManageClientListResult";
import { GetManageClientResult } from "@app/shared/CSDAModels/ManageClientModels/Client/GetManageClientResult";
import { GetManageClientDetail } from "@app/shared/CSDAModels/ManageClientModels/Client/GetManageClientDetail";
import { spCSDAManageClientGetListResult } from "@app/shared/CSDAModels/ManageClientModels/Client/spCSDAManageClientGetListResult";
import { TranslateService } from "@ngx-translate/core";
import { GetManageDivisionListResult } from "@app/shared/CSDAModels/ManageClientModels/Division/GetManageDivisionListResult";
import { GetManageDivisionListDetail } from "@app/shared/CSDAModels/ManageClientModels/Division/GetManageDivisionListDetail";
import { spCSDAManageDivisionGetListResult } from "@app/shared/CSDAModels/ManageClientModels/Division/spCSDAManageDivisionGetListResult";
import { GetManageDivisionResult } from "@app/shared/CSDAModels/ManageClientModels/Division/GetManageDivisionResult";
import { GetManageDivisionDetail } from "@app/shared/CSDAModels/ManageClientModels/Division/GetManageDivisionDetail";
import { GetManageUserListResult } from "@app/shared/CSDAModels/ManageClientModels/User/GetManageUserListResult";
import { GetManageUserListDetail } from "@app/shared/CSDAModels/ManageClientModels/User/GetManageUserListDetail";
import { spCSDAManageUserGetListResult } from "@app/shared/CSDAModels/ManageClientModels/User/spCSDAManageUserGetListResult";
import { GetManageUserResult } from "@app/shared/CSDAModels/ManageClientModels/User/GetManageUserResult";
import { GetManageUserDetail } from "@app/shared/CSDAModels/ManageClientModels/User/GetManageUserDetail";
import { GetManageRateListResult } from "@app/shared/CSDAModels/ManageClientModels/Rate/GetManageRateListResult";
import { GetManageRateListDetail } from "@app/shared/CSDAModels/ManageClientModels/Rate/GetManageRateListDetail";
import { GetManageRateDetail } from "@app/shared/CSDAModels/ManageClientModels/Rate/GetManageRateDetail";
import { spCSDAManageRateGetListResult } from "@app/shared/CSDAModels/ManageClientModels/Rate/spCSDAManageRateGetListResult";
import { GetManageRateResult } from "@app/shared/CSDAModels/ManageClientModels/Rate/GetManageRateResult";
import { GetManageRateInsuranceListResult } from "@app/shared/CSDAModels/ManageClientModels/RateInsurance/GetManageRateInsuranceListResult";
import { GetManageRateInsuranceListDetail } from "@app/shared/CSDAModels/ManageClientModels/RateInsurance/GetManageRateInsuranceListDetail";
import { spCSDAManageRateInsuranceGetListResult } from "@app/shared/CSDAModels/ManageClientModels/RateInsurance/spCSDAManageRateInsuranceGetListResult";
import { GetManageRateInsuranceResult } from "@app/shared/CSDAModels/ManageClientModels/RateInsurance/GetManageRateInsuranceResult";
import { GetManageRateInsuranceDetail } from "@app/shared/CSDAModels/ManageClientModels/RateInsurance/GetManageRateInsuranceDetail";
import { GetManageParametersGetResult } from "@app/shared/CSDAModels/GetManageParametersGetResult";
import { GetManageParametersGetDetail } from "@app/shared/CSDAModels/GetManageParametersGetDetail";
import { GetManageParametersResult } from "@app/shared/CSDAModels/GetManageParametersResult";
import { spCSDAManageParametersGetResult } from "@app/shared/CSDAModels/spCSDAManageParametersGetResult";
import { GetManageParametersDetail } from "@app/shared/CSDAModels/GetManageParametersDetail";
import { GetClientContactsDetail } from "@app/shared/CSDAModels/GetClientContactsDetail";
import { GetClientContactsResult } from "@app/shared/CSDAModels/GetClientContactsResult";
import { GetManageUserRightsListDetail } from "@app/shared/CSDAModels/ManageClientModels/UserRights/GetManageUserRightsListDetail";
import { GetManageUserRightsListResult } from "@app/shared/CSDAModels/ManageClientModels/UserRights/GetManageUserRightsListResult";
import { spCSDAManageUserRightsResult } from "@app/shared/CSDAModels/ManageClientModels/UserRights/spCSDAManageUserRightsResult";
import { GetManageUserRightsDetail } from "@app/shared/CSDAModels/ManageClientModels/UserRights/GetManageUserRightsDetail";
import { GetManageUserRightsResult } from "@app/shared/CSDAModels/ManageClientModels/UserRights/GetManageUserRightsResult";
import { of } from 'rxjs';
import { GetManageSurchargeExResult } from "@app/shared/CSDAModels/GetManageSurchargeExResult";
import { GetManageSurchargeExDetail } from "@app/shared/CSDAModels/GetManageSurchargeExDetail";
import { MoGlobexAddRatesSubchargeEx } from "@app/shared/CSDAModels/MoGlobexAddRatesSubchargeEx";
import { ManageSurchargeExResult } from "@app/shared/CSDAModels/ManageSurchargeExResult";
import { ManageSurchargeExDetail } from "@app/shared/CSDAModels/ManageSurchargeExDetail";
import { ManageSurchargeExApplyResult } from "@app/shared/CSDAModels/ManageSurchargeExApplyResult";
import { ManageSurchargeExApplyDetail } from "@app/shared/CSDAModels/ManageSurchargeExApplyDetail";

@Injectable({
    providedIn: 'root'
})

export class ManageClientsService implements OnInit {
    clientDeactivated = new Subject<void>();
    divisionDeactivated = new Subject<void>();
    users: GetManageUserListResult;
    currentUsersRights: UsersRights[];
    language: Language;

    constructor(
        private httpClient: HttpClient,
        private validationService: ValidationService,
        private translateService: TranslateService
    ) { }

    ngOnInit(): void {
    }

    //#region ' Clients '
    //------------------------
    // Get the list of clients
    //------------------------

    deactivateClient() {
        this.clientDeactivated.next();
    }

    deactivateDivision() {
        this.divisionDeactivated.next();
    }

    public getClients(): Observable<GetManageClientListResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageClientListDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            USR_ID: this.currentUsersRights[0].Uri_Usr_Ref
        };

        return this.httpClient.post<GetManageClientListResult>(environment.CSDAEndPoint + "/CSDA/ManageClientGetListAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message, "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }

    //--------------------------
    // Save the client
    //--------------------------
    public saveClient(client: spCSDAManageClientGetListResult): Observable<GetManageClientResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageClientDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            Client: client,
            IsDelete: false
        };

        return this.httpClient.post<GetManageClientResult>(environment.CSDAEndPoint + "/CSDA/ManageClientAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message.replace('MES - ', ''), "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }

    //--------------------------
    // Delete the client
    //--------------------------
    public deleteClient(client: spCSDAManageClientGetListResult): Observable<GetManageClientResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageClientDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            Client: client,
            IsDelete: true
        };

        return this.httpClient.post<GetManageClientResult>(environment.CSDAEndPoint + "/CSDA/ManageClientAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message.replace('MES - ', ''), "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }
    //#endregion
    //#region ' Divisions '
    //------------------------
    // Get the list of divisions
    //------------------------
    public getDivisions(CLT_Id: number): Observable<GetManageDivisionListResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageDivisionListDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            USR_ID: this.currentUsersRights[0].Uri_Usr_Ref,
            CLT_Id: CLT_Id
        };

        return this.httpClient.post<GetManageDivisionListResult>(environment.CSDAEndPoint + "/CSDA/ManageDivisionGetListAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message, "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }

    //--------------------------
    // Save the division
    //--------------------------
    public saveDivision(Division: spCSDAManageDivisionGetListResult): Observable<GetManageDivisionResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));
        Division.Div_CLT_Ref = 1;

        const Detail: GetManageDivisionDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            Division: Division,
            IsDelete: false
        };

        return this.httpClient.post<GetManageDivisionResult>(environment.CSDAEndPoint + "/CSDA/ManageDivisionAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message.replace('MES - ', ''), "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }

    //--------------------------
    // delete the division
    //--------------------------
    public deleteDivision(Division: spCSDAManageDivisionGetListResult): Observable<GetManageDivisionResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageDivisionDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            Division: Division,
            IsDelete: true
        };

        return this.httpClient.post<GetManageDivisionResult>(environment.CSDAEndPoint + "/CSDA/ManageDivisionAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message.replace('MES - ', ''), "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }
    //#endregion
    //#region ' Users '
    //------------------------
    // Get the list of users
    //------------------------
    public getUsers(Div_Id): Observable<GetManageUserListResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageUserListDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            USR_ID: this.currentUsersRights[0].Uri_Usr_Ref,
            Div_Id: Div_Id
        };

        return this.httpClient.post<GetManageUserListResult>(environment.CSDAEndPoint + "/CSDA/ManageUserGetListAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message, "info");
                    }
                    
                    this.users = data !== undefined ? data : null;
                    return this.users;
                })
            );
    }

    //--------------------------
    // Save the user
    //--------------------------
    public saveUser(user: spCSDAManageUserGetListResult): Observable<GetManageUserResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageUserDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            User: user,
            IsDelete: false
        };

        return this.httpClient.post<GetManageUserResult>(environment.CSDAEndPoint + "/CSDA/ManageUserAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message.replace('MES - ', ''), "info");
                    }

                    return data !== undefined ? data : null;
                }),
                catchError((err) => {
                    return throwError(<GetManageUserResult>{});
                })
            )
    }

    //--------------------------
    // delete the user
    //--------------------------
    public deleteUser(user: spCSDAManageUserGetListResult): Observable<GetManageUserResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageUserDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            User: user,
            IsDelete: true
        };

        return this.httpClient.post<GetManageUserResult>(environment.CSDAEndPoint + "/CSDA/ManageUserAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message.replace('MES - ', ''), "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }
    //#endregion
    //#region ' Rates '
    //------------------------
    // Get the list of rates
    //------------------------
    public getRates(Div_Id): Observable<GetManageRateListResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageRateListDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            USR_ID: this.currentUsersRights[0].Uri_Usr_Ref,
            Div_Id: Div_Id
        };

        return this.httpClient.post<GetManageRateListResult>(environment.CSDAEndPoint + "/CSDA/ManageRateGetListAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message, "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }

    //--------------------------
    // Save the rate
    //--------------------------
    public saveRate(rate: spCSDAManageRateGetListResult): Observable<GetManageRateResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageRateDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            Rate: rate,
            IsDelete: false
        };

        return this.httpClient.post<GetManageRateResult>(environment.CSDAEndPoint + "/CSDA/ManageRateAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message.replace('MES - ', ''), "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }

    //--------------------------
    // delete the rate
    //--------------------------
    public deleteRate(rate: spCSDAManageRateGetListResult): Observable<GetManageRateResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageRateDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            Rate: rate,
            IsDelete: true
        };

        return this.httpClient.post<GetManageRateResult>(environment.CSDAEndPoint + "/CSDA/ManageRateAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message.replace('MES - ', ''), "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }
    //#endregion
    //#region ' Surcharge Ex '
   //------------------------
    // Get the list of rates
    //------------------------
    public getSurchargeEx(MGA_ID, MGA_DIV_REF): Observable<GetManageSurchargeExResult> {
        const Detail: GetManageSurchargeExDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            MGA_ID: MGA_ID,
            MGA_DIV_REF: MGA_DIV_REF
        };

        return this.httpClient.post<GetManageSurchargeExResult>(environment.CSDAEndPoint + "/CSDA/ManageSurchargeExListAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message, "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }
    public saveSurchargeEx(SurchargeEx: MoGlobexAddRatesSubchargeEx): Observable<ManageSurchargeExResult> {
        const Detail: ManageSurchargeExDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            SurchargeEx: SurchargeEx
        };

        return this.httpClient.post<ManageSurchargeExResult>(environment.CSDAEndPoint + "/CSDA/ManageSurchargeExAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message.replace('MES - ', ''), "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }
    public applySurchargeExs(SurchargeExs: MoGlobexAddRatesSubchargeEx[], CarrierName: string): Observable<ManageSurchargeExApplyResult> {
        const Detail: ManageSurchargeExApplyDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            SubchargeExs: SurchargeExs,
            CarrierName: CarrierName
        };

        return this.httpClient.post<ManageSurchargeExApplyResult>(environment.CSDAEndPoint + "/CSDA/ManageSurchargeExApplyAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message.replace('MES - ', ''), "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }
    //#endregion
    //#region ' Rates Insurance '
    //--------------------------------
    // Get the list of rates insurance
    //--------------------------------
    public getRateInsurances(Div_Id): Observable<GetManageRateInsuranceListResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageRateInsuranceListDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            USR_ID: this.currentUsersRights[0].Uri_Usr_Ref,
            Div_Id: Div_Id
        };

        return this.httpClient.post<GetManageRateInsuranceListResult>(environment.CSDAEndPoint + "/CSDA/ManageRateInsuranceGetListAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message, "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }

    //--------------------------
    // Save the rate Insurance
    //--------------------------
    public saveRateInsurance(rateInsurance: spCSDAManageRateInsuranceGetListResult): Observable<GetManageRateInsuranceResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageRateInsuranceDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            RateInsurance: rateInsurance,
            IsDelete: false
        };

        return this.httpClient.post<GetManageRateInsuranceResult>(environment.CSDAEndPoint + "/CSDA/ManageRateInsuranceAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message.replace('MES - ', ''), "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }

    //--------------------------
    // Delete the rate Insurance
    //--------------------------
    public deleteRateInsurance(rateInsurance: spCSDAManageRateInsuranceGetListResult): Observable<GetManageRateInsuranceResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageRateInsuranceDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            RateInsurance: rateInsurance,
            IsDelete: true
        };

        return this.httpClient.post<GetManageRateInsuranceResult>(environment.CSDAEndPoint + "/CSDA/ManageRateInsuranceAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message.replace('MES - ', ''), "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }
    //#endregion
    //#region ' Parameters '
    //--------------------------------
    // Get the list client contacts
    //--------------------------------
    public getClientContacts(AccountNumber: any): Observable<GetClientContactsResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetClientContactsDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            AccountNumber: AccountNumber
        };

        return this.httpClient.post<GetClientContactsResult>(environment.CSDAEndPoint + "/CSDA/ManageParametersGetClientContactsAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message, "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }
    //--------------------------------
    // Get the list of Parameters DPA
    //--------------------------------
    public getParameters(Div_Id: any): Observable<GetManageParametersGetResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageParametersGetDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            DIV_ID: Div_Id
        };

        return this.httpClient.post<GetManageParametersGetResult>(environment.CSDAEndPoint + "/CSDA/ManageParametersGetAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message, "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }
    //--------------------------
    // Save the Parameters DPA
    //--------------------------
    public saveParameters(DIV_ID: number, parameters: spCSDAManageParametersGetResult): Observable<GetManageParametersResult> {
        this.currentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

        const Detail: GetManageParametersDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            Parameters: parameters,
            DIV_ID: DIV_ID
        };

        return this.httpClient.post<GetManageParametersResult>(environment.CSDAEndPoint + "/CSDA/ManageParametersAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message.replace('MES - ', ''), "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }
    //#endregion
    //#region ' User Rights '
    getUserRights(UserId: any): Observable<GetManageUserRightsListResult> {

        const Detail: GetManageUserRightsListDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            USR_ID: UserId
        };

        return this.httpClient.post<GetManageUserRightsListResult>(environment.CSDAEndPoint + "/CSDA/ManageUserRightsGetListAsync", Detail)
            .pipe(
                map(data => {
                    var validation = this.validationService.validate(data);

                    if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                    }

                    if (validation.message !== "") {
                        Swal.fire('', validation.message.replace('MES - ', ''), "info");
                    }

                    return data !== undefined ? data : null;
                })
            );
    }
    updateUserRight(UserRight: spCSDAManageUserRightsResult): Observable<GetManageUserRightsResult> {

        const Detail: GetManageUserRightsDetail = {
            Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
            IsDevelopmentEnvironment: false,
            UserRight: UserRight
        };

        return this.httpClient.post<GetManageUserRightsResult>(environment.CSDAEndPoint + "/CSDA/ManageUserRightsAsync", Detail)
            .pipe(
                map((response: HttpResponse<any> | GetManageUserRightsResult) => {
                    if (response instanceof HttpResponse) {
                        Swal.fire('', response.statusText, "error");
                    } else {
                      var validation = this.validationService.validate(response);
            
                      if (validation.isValid === false) {
                        Swal.fire('', validation.message, "error");
                        return null;
                      }
            
                      return response !== undefined ? response : null;
                    }
                })
            );
    }
    //#endregion
}
