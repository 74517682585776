import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { BaseComponentDirective } from "@app/core/base.component";
import { AlertType } from "@app/core/enums/alert-type";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "@app/core/services/language.service";
import { ValidationService } from "@app/core/services/validation.service";
import { OrderResult } from "@app/shared/CSDAModels/OrderResult";
import Swal from "sweetalert2";
import { VoidShipmentDetail } from "@app/shared/CSDAModels/VoidShipmentDetail";
import { Carriers } from "@app/core/enums/Carriers";
import { ShipmentSummaryComponent } from "../shipment-summary/shipment-summary.component";
import { ShipmentService } from "@app/features/active-orders/services/shipment.service";
import { ActionService } from "@app/core/services/action.service";
import { DatePipe } from "@angular/common";
import { CreatePickupDialogComponent } from "../create-pickup-dialog-box/create-pickup-dialog/create-pickup-dialog.component";
import { Language } from "@app/core/enums/Language";
import { CreatePickupsDetail } from "@app/shared/CSDAModels/CreatePickupsDetail";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router, RouterModule } from "@angular/router";
import { StorageService } from "@app/core/services/storage.service";
import { Pickups } from "@app/shared/CSDAModels/Pickups";
import { PickupResult } from "@app/shared/CSDAModels/PickupResult";


@Component({
  selector: 'app-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss']
  // retrait de cette ligne pour regler le probleme de processing qui s'affichait lorsqu'on cliquait sur cancel lors de la creation d'un pickup - Task 4505
  //,changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShipmentDetailsComponent extends BaseComponentDirective {

  @Input() shpId: number;
  shipment: OrderResult;
  $shipment: Observable<OrderResult>;
  carriers: Carriers;
  currentLanguage: Language;
  isProcessing = true;
  readonly alertTypeEnum = AlertType;
  public hideSectionsByPat: boolean = true;
  $pickupExist: BehaviorSubject<boolean>;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public validationService: ValidationService,
    private storageService: StorageService,
    private languageService: LanguageService,
    private shipmentService: ShipmentService,
    private actionService: ActionService) {
    super();
    this.$pickupExist = new BehaviorSubject(false);
  }

  onInit(): void {
    this.$shipment = this.shipmentService.GetOrderSummary(this.shpId).pipe(tap((result) => {
      if (result !== null) {
        this.shipment = result;

        if(!this.shipment.Pickup) {
          var pickup: Pickups = {
            PKU_ID: null,
            PKU_CreateDate: null,
            PKU_PickupDate: null,
            PKU_ReadyByTime: '',
            PKU_HoursFrom: '',
            PKU_HoursTo: '',
            PKU_Instructions: '',
            PKU_IsResidential: false,
            PKU_Reference: '',
            PKU_Language: '',
            PKU_Quantity: null,
            PKU_Weight: null,
            PKU_ResultCarrierCode: '',
            PKU_ResultLocation: '',
            PKU_ResultPickupDate: '',
            PKU_ResultReadyByTime: '',
            PKU_ResultConfirmationNumber: '',
            PKU_RequestorCompanyName: '',
            PKU_RequestorAddress: '',
            PKU_RequestorCountry: '',
            PKU_RequestorCity: '',
            PKU_RequestorPostalCode: '',
            PKU_RequestorContactName: '',
            PKU_RequestorContactPhone: '',
            PKU_IsVoided: false,
            PKU_Carrier: '',
          }

          this.shipment.Pickup = pickup;
        }
        else {
          this.$pickupExist.next(true);
        }
      }
      this.isProcessing = false;
    }));
  }

  onDestroy(): void { }

  /** See shipment waybill in a new Tab 
   */
  seeWaybill(): void {
    this.actionService.seeWaybill(this.shipment.Order.ORD_ServiceLabel);
  }

  /** Create a pickup for the shipment
   * @param shipment - The shipment response
   */
  createPickup(): void {

    /** Display the spinner while processing */
    this.isProcessing = true;

    /** Set the dialog box configuration */
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    /** Open dialog to enter the pickup details */
    this.dialog.open(CreatePickupDialogComponent, dialogConfig).afterClosed().subscribe(dialogResult => {

      if (dialogResult !== false) {
        let pickup: CreatePickupsDetail = {
          ShipmentIds: [this.shipment.Order.ORD_ID.toString()],
          PickupDate: this.datepipe.transform(dialogResult.pickupDate, 'yyyy-MM-dd'),
          ReadyByTime: dialogResult.readyByTime,
          BusinessHoursFrom: dialogResult.businessHoursFrom,
          BusinessHoursTo: dialogResult.businessHoursTo,
          Instructions: dialogResult.instructions,
          CustomerReference: dialogResult.customerReference,
          IsDevelopmentEnvironment: false,
          Language: this.getCurrentLanguage(),
          IsNoMessage: false
        }

        /** Take the only pickup in the list to unpadate UI informations */
        this.shipmentService.createPickups(pickup).subscribe((pickupResponses) => {

          if (pickupResponses !== null && pickupResponses.PickupResults !== null && pickupResponses.PickupResults.length > 0) {
            this.shipment.Pickup.PKU_ResultConfirmationNumber = pickupResponses.PickupResults[0].PickupConfirmationNumber;
            this.shipment.Pickup.PKU_PickupDate = pickupResponses.PickupResults[0].PickupDate;
            this.shipment.Pickup.PKU_ReadyByTime = pickupResponses.PickupResults[0].ReadyByTime;

            if(pickupResponses.PickupResults[0].ServiceWaybill)
              this.shipment.Order.ORD_ServiceLabel = pickupResponses.PickupResults[0].ServiceWaybill;
          }
          this.shipment.Pickup.PKU_ResultConfirmationNumber !== '' ? this.$pickupExist.next(true) : this.$pickupExist.next(false);
          this.isProcessing = false;
        });
      }
      else {
        this.isProcessing = false;
      }
    });

  }

  /** Get the current language
  * @returns The enum of the current language
  */
  getCurrentLanguage(): Language {
    if (this.translateService.currentLang === "fr") {
      return this.currentLanguage = Language.French;
    }
    else {
      return this.currentLanguage = Language.English;
    }
  }

  /** Open the shipment summary in dialog box
   * @param shipment - The shipment response
   */
  goToShipmentSummary(): void {

    this.isProcessing = true;

    /** Display the shipment summary */
    var window = this.dialog.open(ShipmentSummaryComponent, {
      data: {
        orderSummary: this.shipment
      },
      height: '95vh',
      width: '768px'
    });

    /** Remove the processing overlay when the window is closed  */
    window.afterClosed().subscribe(result => { if (result) { return true; } else { return false; } })
      ? this.isProcessing = false
      : this.isProcessing = true;
  }

  getNotified(): void {
    /** Redirect to the carrier tracking details in a new tab */
    window.open(this.shipment.Order.ORD_TrackingURL, '_blank');
  }

  /**  Cancel a shipment	*/
  voidShipment(): void {

    /** Open a message box to confirm the void of the shipment request */
    Swal.fire({
      title: this.translateService.instant("app.misc.voidshipmentconfirm"),
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {

      /** If the void of the shipment request is confirmed */
      if (result.isConfirmed) {

        /** Open a message box that demands the customer reference */
        Swal.fire({
          title: "Please enter your customer reference",
          icon: "question",
          input: 'text',
          inputAttributes: { autocapitalize: 'off' },
          showCancelButton: true,
          cancelButtonText: 'Annuler',
          confirmButtonText: 'OK',

          inputValidator: (value) => {
            if (!value) {
              return this.translateService.instant("app.mandatory.mandatoryInputField")
            }
          }
        }).then((sweetAlertResult) => {
          /** If the confirmation button is clicked the shipement is void */
          if (sweetAlertResult.isConfirmed) {
            this.isProcessing = true;

            const voidShipmentDetail: VoidShipmentDetail = {
              TrackingNumber: this.shipment.Order.ORD_TrackingNumber,
              CustomerReference: sweetAlertResult.value,
              Language: this.languageService.GetCurrentLanguage(),
              IsDevelopmentEnvironment: false,
            }

            this.shipmentService.voidShipment(voidShipmentDetail).subscribe((data) => {
              this.isProcessing = false;
              var validation = this.validationService.validate(data);

              if (validation.message !== "") {
                Swal.fire('', validation.message, "info");
              }

              let path = this.storageService.getShipmentOrigin();
              void this.router.navigate([path]);
            });
          }
        })
      }
    });
  }
}
