<div [@fadeInAnimation]="loginState">
  <!-- Main view -->
  <mat-drawer-container class="main-container" autosize>

    <mat-toolbar class="main-toolbar">

      <!-- Toolbar menu icon and title -->
      <div class="main-toolbar__navigation">

        <!-- Menu icon -->
        <button mat-icon-button (click)="onDrawerChange()" *ngIf="showIcon">
          <mat-icon>menu</mat-icon>
        </button>

        <!-- Title -->
        <div class="toolbarTitle"> {{title}}<span class="version">{{ version }}</span></div>
      </div>

      <!-- Toolbar action buttons -->
      <div class="main-toolbar__actions">

        <!-- Change user -->
        <div class="actionButton__pad" *ngIf="!isGuest">
          <button mat-raised-button class="actionButton" (click)="openProfile()">
            <span class="button-content">
              <mat-icon class="main-toolbar__actions--icon material-icons">person</mat-icon>
              {{getUser()}}
            </span>
          </button>
        </div>
        

        <!-- Logout button -->
        <div class="actionButton__pad" *ngIf="!isGuest">
          <button mat-raised-button class="actionButton" (click)="logout()">
            <span class="button-content">
              <mat-icon class="main-toolbar__actions--icon material-icons">power_settings_new</mat-icon>
              {{ 'app.menu.logout' | translate }}
            </span>
          </button>
        </div>

        <!-- Language button -->
        <div class="actionButton__pad">
          <button mat-raised-button class="actionButton" (click)="switchLanguage()">
            <span class="button-content">
              <mat-icon class="main-toolbar__actions--icon material-icons">language</mat-icon>
              {{displayedLanguage}}
            </span>          
          </button>
        </div>
      </div>
    </mat-toolbar>

    <!-- Side menu -->
    <mat-drawer #drawer [opened]="!isShowForMobile" class="sidenav" [mode]="isShowForMobile ? 'over' : 'side'"
      (closedStart)="onDrawerClosed()" *ngIf="!isGuest">
      <div class="logo-wrapper" (click)="onDrawerChange()">
        <div class="content">
          <div class="icon"></div>
        </div>
        <div class="expand-menu-icon">
          <mat-icon mat-list-icon [inline]="true">menu</mat-icon>
        </div>
      </div>
      <app-main-menu></app-main-menu>
    </mat-drawer>

    <!-- Principal content of the page -->
    <div class="sidenav-content">
      <router-outlet></router-outlet>
    </div>

  </mat-drawer-container>
</div>
