import { MatCardModule as MatCardModule } from "@angular/material/card";
import { MatButtonModule as MatButtonModule } from "@angular/material/button";
import { MatMenuModule as MatMenuModule } from "@angular/material/menu";
import { MatInputModule as MatInputModule } from "@angular/material/input";
import { MatListModule as MatListModule } from "@angular/material/list";
import { MatProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSlideToggleModule as MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCheckboxModule as MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule as MatRadioModule } from "@angular/material/radio";
import { MatDialogModule as MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule as MatTabsModule } from "@angular/material/tabs";
import { MatSelectModule as MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule as MatSnackBarModule } from "@angular/material/snack-bar";
import { MatProgressBarModule as MatProgressBarModule } from "@angular/material/progress-bar";
import { MatFormFieldModule as MatFormFieldModule } from "@angular/material/form-field";
import { MatTableModule as MatTableModule } from "@angular/material/table";
import { MatPaginatorModule as MatPaginatorModule } from "@angular/material/paginator";
import { MatTooltipModule as MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule as MatAutocompleteModule } from '@angular/material/autocomplete';

import { NgModule } from "@angular/core";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatNativeDateModule } from "@angular/material/core";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatBadgeModule } from "@angular/material/badge";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from '@angular/material/stepper';
import { MomentDateModule } from '@angular/material-moment-adapter';

const materialModules = [
	MatTooltipModule,
	MatCardModule,
	MatMenuModule,
	MatButtonModule,
	MatListModule,
	MatProgressSpinnerModule,
	MatSlideToggleModule,
	MatCheckboxModule,
	MatRadioModule,
	MatDialogModule,
	MatTabsModule,
	MatInputModule,
	MatSelectModule,
	MatSnackBarModule,
	MatProgressBarModule,
	MatFormFieldModule,
	MatTableModule,
	MatPaginatorModule,
	MatAutocompleteModule,

	MatGridListModule,
	MatIconModule,
	MatSidenavModule,
	MatToolbarModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatButtonToggleModule,
	MatBadgeModule,
	MatSortModule,
	MatStepperModule,
	MomentDateModule,
];

@NgModule({
	imports: [ ...materialModules ],
	exports: [ ...materialModules ]
})
export class MaterialModule {}
