import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Carrier } from "@app/core/enums/carrier";
import { Quote } from "@app/shared/CSDAModels/Quote";
import { StorageService } from "@app/core/services/storage.service";
import { QuoteDetail } from "@app/shared/CSDAModels/QuoteDetail";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { CostBreakdownComponent } from "@app/shared/components/cost-breakdown/cost-breakdown.component";
import { UnitOfMeasure } from "@app/core/enums/unit-of-measure";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { Carriers } from "@app/core/enums/Carriers";
import { Router } from "@angular/router";

@Component({
  selector: 'app-step2-quotes',
  templateUrl: './step2-quotes.component.html',
  styleUrls: ['./step2-quotes.component.scss']
})

export class Step2QuotesComponent implements OnInit {
  @Input() quotes: Quote[];
  @Input() order: QuoteDetail;
  @Input() message: string;
  @Output() selectedQuote = new EventEmitter<Quote>(); 

  isQuickQuote = sessionStorage.getItem("quoteType") == "51";
  isGuest = false;

  public carrier = Carrier;
  constructor(
    private storageService: StorageService,
    public dialog: MatDialog,
		private translateService: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isGuest = sessionStorage.getItem("isGuest") !== null;
    this.message = this.message.split("<br>").join("\n");
  }

  viewQuote(event: Quote): void {
    if(this.isGuest) {
      this.router.navigate(["/account/registration"]);
      return;
    }

    if(this.order.IsSignatureRequired && event.IsSignatureUnavailable) {
      Swal.fire({
        title: this.translateService.instant("app.misc.confirmNoSignatureAvailable"),
        icon: "question",
        showDenyButton: true,
        denyButtonText: this.translateService.instant("app.misc.cancel"),
        confirmButtonText: this.translateService.instant("app.misc.continue"),
        allowOutsideClick: () => !Swal.isLoading()
      }).then((sweetAlertResult) => {
        if (sweetAlertResult.isConfirmed) {
          this.selectedQuote.emit(event)
        }
      })
    }
    else
      this.selectedQuote.emit(event);
  }

  returnToPreviousStep() {
    this.storageService.setGoToStepTwo("false");
  }

  getCostBreakdown(Quote: Quote) {
    /** Display the shipment summary */

    Quote.BillableWeight = this.getTotalBillingWeight();
    Quote.UnitOfMeasure = this.order.UnitOfMeasure;
    
    var window = this.dialog.open(CostBreakdownComponent, {
      data: {
        quote: Quote
      },
      height: '55vh',
      width: '500px'
    });
  }

  getTotalBillingWeight(): number {
    var billingWeight = 0;
    var volumetricWeight = 0;
    var NeedConversion = this.order.UnitOfMeasure == UnitOfMeasure.Imperial;
    
    if (this.order.Packages.length > 0) {

      this.order.Packages.forEach((pk) => {

        volumetricWeight = this.ConvertToMetric(NeedConversion, false, pk.Depth) * this.ConvertToMetric(NeedConversion, false, pk.Width) * this.ConvertToMetric(NeedConversion, false, pk.Height) / 5000;

        /** Round up to the nearest 0.5kg value */
        let roundedVolumeWeight = Math.ceil(volumetricWeight * 2) / 2;
        roundedVolumeWeight = Math.max(0.5, roundedVolumeWeight);

        billingWeight = billingWeight + (pk.Quantity == null ? 1 : Math.max(1, pk.Quantity)) * (this.ConvertToMetric(NeedConversion, true, pk.Weight) > roundedVolumeWeight ? this.ConvertToMetric(NeedConversion, true, pk.Weight) : roundedVolumeWeight);
      });
    }

    if(this.order.UnitOfMeasure == UnitOfMeasure.Imperial)
      return this.ConvertToImperial(true, billingWeight);
    else
      return billingWeight;
  }

  ConvertToMetric(NeedConversion: boolean, IsWeight: boolean, ValueToConvert: number): number {
    if (!NeedConversion)
        return ValueToConvert;

    let weightImperialToMetricFactor = 0.45359237;
    let dimImperialToMetricFactor = 2.54;
    let result: number;

    if (IsWeight)
        result = ValueToConvert * weightImperialToMetricFactor;
    else
        result = ValueToConvert * dimImperialToMetricFactor;

    return Math.round(result * 100) / 100;
  }

  ConvertToImperial(IsWeight: boolean, ValueToConvert: number): number {
    let weightMetricToImperialFactor = 2.20462;
    let dimMetricToImperialFactor = 0.393701;
    let result: number;

    if (IsWeight)
        result = ValueToConvert * weightMetricToImperialFactor;
    else
        result = ValueToConvert * dimMetricToImperialFactor;

    return Math.round(result * 100) / 100;
  }
  checkForNonDoc(Quote: Quote) {
    return this.isQuickQuote && Quote.ServiceType == 'ECONOMY SELECT';
  }
  getCurrency(Quote: Quote) {
    // return Quote.CurrencyCode;
  }
}
