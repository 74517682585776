import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";
import { animate, style, transition, trigger } from "@angular/animations";
import { MatDialogRef } from "@angular/material/dialog";
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button'
import { SharedModule } from "@app/shared/shared.module";
import { CommonModule } from "@angular/common";
import { Profile } from "@app/shared/CSDAModels/Profile";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env";
import { ValidationService } from "@app/core/services/validation.service";
import { GetProfileDetail } from "@app/shared/CSDAModels/GetProfileDetail";
import { GetProfileResult } from "@app/shared/CSDAModels/GetProfileResult";
import { Language as LanguageEnum } from "@app/core/enums/Language";
import { SaveProfileDetail } from "@app/shared/CSDAModels/SaveProfileDetail";
import { NgxMaskDirective } from 'ngx-mask'
import { map } from "rxjs";
import Swal from "sweetalert2";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css'],
    imports: [MatDialogModule, MatButtonModule, ReactiveFormsModule, SharedModule, CommonModule, NgxMaskDirective],
    animations: [
        trigger('fadeInAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('600ms', style({ opacity: 1 }))
            ])
        ])
    ]
})

export class ProfileComponent implements OnInit, AfterViewInit {
  @ViewChild('userName') userName: ElementRef;
  
  loginState: string = "in";
  isLoading: boolean = false;
  form: FormGroup;
  CurrentProfile = new Profile();
  currentLanguage: LanguageEnum;
  isProcessing: boolean = false;
  phoneMask: string = "(000) 000-0000";

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ProfileComponent>,
    private translateService: TranslateService,
    private httpClient: HttpClient,
    private validationService: ValidationService,
    private cdr: ChangeDetectorRef
  ) { }
  
  ngAfterViewInit(): void {
  }
  
  ngOnInit() {
    this.isProcessing = true;
    this.CurrentProfile = new Profile();
    this.CurrentProfile.UserName = '';
    this.CurrentProfile.Password = '';
    this.CurrentProfile.Email = '';
    this.CurrentProfile.Phone = '';
    
    this.form = this.fb.group({
      UserName: [this.CurrentProfile.UserName, [Validators.required]],
      Password: [this.CurrentProfile.Password, [Validators.required]],
      Email: [this.CurrentProfile.Email, [Validators.required]],
      Phone: [this.CurrentProfile.Phone],
    });

    this.getProfile();
  }

  getCurrentLanguage(): LanguageEnum {
		if (this.translateService.currentLang === "fr") {
			return this.currentLanguage = LanguageEnum.French;
		}
		else {
			return this.currentLanguage = LanguageEnum.English;
		}
	}

	getProfile() {
    var ProfileDetail: GetProfileDetail = {
      Language : this.getCurrentLanguage(),
      IsDevelopmentEnvironment: false
    }

		this.httpClient.post<GetProfileResult>(environment.CSDAEndPoint + "/CSDA/GetProfile", ProfileDetail)
			.pipe(
				map((data) => {
					var validation = this.validationService.validate(data);

					if (validation.isValid === false) {
						Swal.fire('', validation.message, "error");
					}

					if (validation.message !== "") {
						Swal.fire('', validation.message, "info");
					}

          this.CurrentProfile = data.ProfileData;
				})
			).subscribe(() => {
        this.initializeForm();
        this.isProcessing = false;
      });
	}

  initializeForm() {
    setTimeout(() => {
      this.form = this.fb.group({
        UserName: [this.CurrentProfile.UserName, [Validators.required]],
        Password: [this.CurrentProfile.Password, [Validators.required]],
        Email: [this.CurrentProfile.Email, [Validators.required]],
        Phone: [this.CurrentProfile.Phone],
      });
    });
    
    setTimeout(() => {
      this.userName.nativeElement.select();
    }, 0);
  }

  save() {
    if (this.form.valid) {
      this.isProcessing = true;

      var profileToSave: Profile = {
        UserName: this.form.get('UserName').value,
        Password: this.form.get('Password').value,
        Email: this.form.get('Email').value,
        Phone: this.form.get('Phone').value
      }

      var saveProfileDetail: SaveProfileDetail = {
        Language: this.getCurrentLanguage(),
        IsDevelopmentEnvironment: false,
        ProfileToSave: profileToSave
      }

      this.httpClient.post<GetProfileResult>(environment.CSDAEndPoint + "/CSDA/SaveProfile", saveProfileDetail)
			.pipe(
				map((data) => {
					var validation = this.validationService.validate(data);

					if (validation.isValid === false) {
						Swal.fire('', validation.message, "error");
					}

					if (validation.message !== "") {
						Swal.fire('', validation.message, "info");
					}

          this.CurrentProfile = data.ProfileData;
				})
			).subscribe(() => {
        this.isProcessing = false;
      });

      // this.dialogRef.close(this.form.value);
    }
  }  
  /** Close the dialog box */
  close() {
    this.dialogRef.close(false);
  }
}
