import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import $ from 'jquery';

@Component({
    selector: 'app-volume-calculator',
    templateUrl: './volume-calculator.component.html',
    styleUrls: ['./volume-calculator.component.css'],
    standalone: false
})

export class VolumeCalculatorComponent implements OnInit {
  isFrench: boolean = this.translateService.currentLang === "fr";
  
  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    /** Subscribe to the translate service to update the title when the language changes */
    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
        this.isFrench = this.translateService.currentLang === "fr";
        this.initCalculator();
    });

    this.initCalculator();
  }

  initCalculator() {
    $(() => {
      // header on scroll add class
      $(window).on('scroll', function() {
          if ($(this).scrollTop() > 1) {  
              $('.header').addClass("sticky");
          } else {
              $('.header').removeClass("sticky");
          }
      });
  
      // add the class .sticky to the header when the page is scrolled even if it is reloaded
      if ($(window).scrollTop() > 1) {  
          $('.header').addClass("sticky");
      }
  
      // add a class on the hamburger menu when clicked .header__hamburger
      $('.header__hamburger').on('click', function(e) {
          e.preventDefault();
          $(this).toggleClass('active');
          $('.header__menu-mobile').toggleClass('open');
          $('.header').toggleClass('mobile');
      });
  
      // .header__menu-mobile .menu-item-has-children is clicked, toggle the sub-menu
      $('.header__menu-mobile .menu-item-has-children > a').on('click', function(e) {
          e.preventDefault();
          $(this).parent().toggleClass('active');
      });
  
      // remove the class open on the mobile menu if the window is resized
      $(window).on('resize', function() {
          if (window.innerWidth > 1160) {
              $('.header__menu-mobile').removeClass('open');
              $('.header__hamburger').removeClass('active');
          }
      });
  
      // Show hide search popup
      $('.search-item-mobile').on('click', function(e) {
          e.preventDefault();
          $('.search-popup').show();
      });
  
      $('.search-button').on('click', function() {
          $('.search-popup').addClass('open');
      });
  
      $('.search-popup__close').on('click', function(e) {
          e.preventDefault();
          $('.search-popup').removeClass('open');
          $('body').removeClass('search-open');
      });
  
      $(document).on('keyup', function(e) {
          if (e.key === 'Escape') {
              $('.search-popup').removeClass('open');
              $('.follow-popup').removeClass('active');
              $('.calc-popup').removeClass('active');
          }
      });
  
      // close all popup when click outside
      $(document).on('click', function(e) {
          if (!$(e.target).closest('.search-form').length && !$(e.target).closest('.search-button').length) {
              $('.search-popup').removeClass('open');
          }
          if (!$(e.target).closest('.follow-popup').length) {
              $('.follow-popup').removeClass('active');
              $('.follow-popup .toggle').removeClass('active');
          }
          if (!$(e.target).closest('.calc-popup').length) {
              $('.calc-popup').removeClass('active');
              $('.calc-popup .toggle').removeClass('active');
          }
      });
  
      // On submit package-follow-form prevent default and redirect to another page in new tab
      $('.package-follow-form').on('submit', function(e) {
          e.preventDefault();
  
          let num = $(this).find('textarea').val() as string;
          // replace new line in num by a comma
          num = num.replace(/\n/g, ',');
          // replace space by a comma
          num = num.replace(/\s/g, ',');
          // remove the last comma
          num = num.replace(/,\s*$/, "");
          
          let url: string;
          if ($('[lang="fr-CA"]').length > 0) {
              url = `https://cloud.globexcourier.com/CCWeb4/Tracking/TrackingInfo?OrderNo=${num}&Lang=F`;
          } else {
              url = `https://cloud.globexcourier.com/CCWeb4/Tracking/TrackingInfo?OrderNo=${num}&Lang=E`;
          }
          window.open(url, '_blank');
      });
  
      // toggle .calendar-accordeon-content on .calendar-accordeon click
      $('.calendar-accordeon').on('click', function() {
          $(this).toggleClass('active');
          $(this).find('.calendar-accordeon-content').slideToggle();
      });
  
      // calculator functionality
      $('input[name=dimension]').on('change', sum);
      $("#weight-length, #weight-width, #weight-height").on("keyup mouseup", sum);
  
      function sum() {
          const length = parseFloat($("#weight-length").val() as string);
          const width = parseFloat($("#weight-width").val() as string);
          const height = parseFloat($("#weight-height").val() as string);
  
          if (length && width && height) {
              const dimension = $('input[name=dimension]:checked', '#weight-form').val() as string;
              const magic = 5000;
              let volWeight: number;
  
              if (dimension === 'in') {
                  // convert to cm
                  volWeight = (length * 2.54) * (width * 2.54) * (height * 2.54) / magic;
              } else {
                  volWeight = length * width * height / magic;
              }
  
              // round to next half, e.g. 2.1 --> 2.5
              volWeight = Math.ceil(volWeight * 2) / 2;
  
              const siteLang = $('html').attr('lang');
              let volWeightStr: string;
              if (siteLang === 'fr-CA') {
                  volWeightStr = volWeight.toString().replace(".", ",");
              } else {
                  volWeightStr = volWeight.toString();
              }
              
              $(".output-result").html(`${volWeightStr} kg`);
  
              // if value empty remove class active from output text
              if (volWeightStr === '') {
                  $(".output-text").removeClass('active');
              } else {
                  $(".output-text").addClass('active');
              }
          }
      }
  
      // calculator and follow popup toggle
      $('.calc-popup .toggle').on('click', function() {
          $('.calc-popup').toggleClass('active');
          $(this).toggleClass('active');
          if ($('.calc-popup').hasClass('active')) {
              $('.calc-popup').css('z-index', '99999999');
          } else {
              setTimeout(() => {
                  $('.calc-popup').attr('style', '');
              }, 300);
          }
      });
  
      $('.follow-popup .toggle').on('click', function() {
          $('.follow-popup').toggleClass('active');
          $(this).toggleClass('active');
          if ($('.follow-popup').hasClass('active')) {
              $('.follow-popup').css('z-index', '99999999');
          } else {
              setTimeout(() => {
                  $('.follow-popup').attr('style', '');
              }, 300);
          }
      });
  
      // Cartes
      $('.map-tabs .tab').on('click', function() {
          $('.map-tabs .tab').removeClass('active');
          $(this).addClass('active');
          $('.map-content .map').removeClass('active');
          $('.map-content .map').eq($(this).index()).addClass('active');
      });
  
      // when clicking on .menu-item.calculator add class active to the .calc-popup
      $('.menu-item.calculator').on('click', function(e) {
          e.preventDefault();
          $('.calc-popup').toggleClass('open');
      });
  
      $(document).on('click', function(e) {
          if (!$(e.target).closest('.calc-popup').length && !$(e.target).closest('.menu-item.calculator').length) {
              $('.calc-popup').removeClass('open');
          }
      });
    });      
  }
}
