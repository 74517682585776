import { MAT_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions as MatTooltipDefaultOptions } from "@angular/material/tooltip";

import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CopyOrderDetail } from "@app/shared/CSDAModels/CopyOrderDetail";
import { Language } from "@app/core/enums/Language";
import { environment } from "@env";
import Swal from "sweetalert2";

const matTooltipDefaultOptions: MatTooltipDefaultOptions = {
  disableTooltipInteractivity: true,
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0
};

@Component({
    selector: 'app-utilities',
    templateUrl: './Utilities.component.html',
    styleUrls: ['./Utilities.component.scss'],
    providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipDefaultOptions }],
    standalone: false
})
export class UtilitiesComponent implements OnInit {
  isProcessing: boolean = false;
  OrderID: number | null;
  IsCopyToLocal: boolean = false;

  constructor(
    private httpClient: HttpClient,
  ) { }

  ngOnInit() {
  }

  copyOrder() {
    this.isProcessing = true;

    var Detail: CopyOrderDetail = {
      Language: Language.English,
      IsDevelopmentEnvironment: false,
      OrderID: this.OrderID,
      IsCopyToLocal: this.IsCopyToLocal
    }
    
    this.httpClient.post<string>(environment.CSDAEndPoint + "/CSDA/CopyOrderFromAzureToLocal", Detail).subscribe(data => {
      this.isProcessing = false;

      Swal.fire({
        icon: "info",
        title: data,
        confirmButtonText: 'OK'
      }).then((sweetAlertResult) => {});
    },
    error => {
      this.isProcessing = false;

      Swal.fire({
        icon: "info",
        title: error,
        confirmButtonText: 'OK'
      }).then((sweetAlertResult) => {});
    })
  }  
}
