// Ref: Building a reusable dialog module with Angular Material -> https://itnext.io/building-a-reusable-dialog-module-with-angular-material-4ce406117918
// Ref: How to round to at 2 decimal -> https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
// Ref: WHY Angular doesn't like jQuery -> https://blog.bitsrc.io/how-to-use-jquery-with-angular-when-you-absolutely-have-to-42c8b6a37ff9

import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource as MatTableDataSource } from "@angular/material/table";
import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, ChangeDetectionStrategy, Inject, HostListener, OnInit, LOCALE_ID } from '@angular/core';
import { Countries } from "@app/shared/CSDAModels/Countries";
import { OrderResult } from "@app/shared/CSDAModels/OrderResult";
import { Provinces } from "@app/shared/CSDAModels/Provinces";
import { spGetAddressListForDivisionResult } from "@app/shared/CSDAModels/spGetAddressListForDivisionResult";
import { TypeOfCurrency } from "@app/core/enums/type-of-currency";
import { CurrencyPipe } from '@angular/common';

@Component({
    selector: 'app-proformat',
    templateUrl: './proformat.component.html',
    styleUrls: ['./proformat.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProformatComponent implements OnInit {

  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'ODP_Quantity',
    'ODP_Description',
    'ODP_ManufactureCountryCode',
    'ODP_HSCode',
    'ODP_Value',
    'totalValue'
  ];

  totalValue = 0;
  countriesList: Countries[];
  statesProvincesList: Provinces[];
  addressesList: spGetAddressListForDivisionResult[];

  constructor(
    private mdDialogRef: MatDialogRef<ProformatComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderResult,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    @Inject(LOCALE_ID) private locale: string, // Inject locale
    private currencyPipe: CurrencyPipe    
  ) { }

  ngOnInit(): void {
		this.countriesList = JSON.parse(sessionStorage.getItem("Countries")) as Countries[];
		this.statesProvincesList = JSON.parse(sessionStorage.getItem("Provinces")) as Provinces[];
    this.addressesList = JSON.parse(sessionStorage.getItem("AddressesList")) as spGetAddressListForDivisionResult[];

    this.dataSource = new MatTableDataSource(this.data.DutiableProducts);

    this.data.DutiableProducts.forEach(item => {
      this.totalValue = this.totalValue + (item.ODP_Value * item.ODP_Quantity);
    })
  }

  toNumber(value) {
    return parseInt(value);
  }
  getLocaleForCurrency(currency: string): string | null {
    switch (currency) {
      case "USD":
        return 'en-US';
      case "CAD":
        return 'en-CA';
      case "KWD":
        return 'ar-KW';
      case "BHD":
        return 'ar-BH';
      case "OMR":
        return 'ar-OM';
      case "JOD":
        return 'ar-JO';
      case "GBP":
        return 'en-GB';
      case "KYD":
        return 'en-KY';
      case "EUR":
        return 'fr-FR'; // Or 'de-DE', 'it-IT', etc., depending on your preference
      case "CHF":
        return 'de-CH';
      default:
        return null; // Or a default locale if needed
    }
  }  
  formatCurrency(value: number, currencyCode: string | null): string {
    if (!currencyCode) {
      return value.toFixed(2) + ' (Currency Unknown)';
    }
  
    const locale = this.getLocaleForCurrency(currencyCode);
    if (!locale) {
      return value.toFixed(2) + ' ' + currencyCode;
    }
  
    try {
      return this.currencyPipe.transform(value, currencyCode, 'symbol', '1.2-2', locale) || '';
    } catch (error) {
      // Fallback to just value + code if formatting fails
      return value.toFixed(2) + ' ' + currencyCode;
    }
  }
	//-------------------------------------------------------------------
	// Used to get the name of the state since the dataSource have the id
	//-------------------------------------------------------------------
	getStateName(CON_Code: string, PRV_Code: string) {
		var country: Countries = this.countriesList.find(c => c.CON_Code == CON_Code);
    var CON_ID: number = -1;

		if(country)
			CON_ID = country.CON_ID;

		var province: Provinces = this.statesProvincesList.find(p => p.PRV_Code == PRV_Code && p.PRV_CON_REF == CON_ID);

		if(province)
			return province.PRV_Name;
		else
			return "";
	}

	//---------------------------------------------------------------------
	// Used to get the name of the country since the dataSource have the id
	//---------------------------------------------------------------------
	getCountryName(CON_Code: string) {
		var country: Countries = this.countriesList.find(c => c.CON_Code == CON_Code);

		if(country)
			return country.CON_Name;
		else
			return "";
	}

  //---------------------------------------------------------------------------
  // When the user do a choice in the list, the name of the company will be set
  //---------------------------------------------------------------------------
  getCompanyName(val: string | number): string {
    if(typeof val === "number")
    {
      const Element: spGetAddressListForDivisionResult = this.addressesList.find(f => f.Row == val);
      return Element.CompanyName;
    }
    else
      return val;
  }

  public cancel() {
    this.close(false);
  }

  public close(value) {
    this.mdDialogRef.close(value);
  }

  public confirm() {
    window.print();
    this.close(true);
  }

  @HostListener("keydown.esc")
  public onEsc() {
    this.close(false);
  }
}
