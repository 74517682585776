<!-- English -->
<div *ngIf="!isFrench">
  <div class="calc-popup popup" style="z-index: 99999999;">
    <div class="toggle">
      <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 162 162">
        <defs>
          <style>
            .cls-1,
            .cls-2 {
              fill: #fff;
            }

            .cls-1 {
              opacity: 0;
            }
          </style>
        </defs>
        <title>calc-icon</title>
        <rect class="cls-1" width="162" height="162"></rect>
        <path class="cls-2"
          d="M-641.73-223.45q0-31.29,0-62.58c0-5.87,1.73-7.66,7.52-7.66q42.16,0,84.32,0c5.73,0,7.7,1.92,7.7,7.61q0,62.77,0,125.55c0,5.49-2,7.47-7.45,7.47q-42.36,0-84.7,0c-5.58,0-7.36-1.82-7.36-7.43q0-31.47,0-63Zm49.38-26.43h30c4.58,0,6.57-1.59,6.81-6a119.45,119.45,0,0,0,0-13.11c-.25-4.31-2.16-5.85-6.51-5.85h-60c-4.46,0-6.16,1.5-6.34,5.93-.17,4.11-.14,8.24,0,12.36.13,5.14,1.75,6.67,6.83,6.68Zm22.77,81.67c3.37,0,6.74,0,10.11,0,2.6.05,4-1,4-3.7q0-10.11,0-20.22c0-2.5-1.28-3.71-3.67-3.73-6.86,0-13.73,0-20.6,0-2.26,0-3.4,1.29-3.4,3.56q0,10.31,0,20.6c0,2.54,1.37,3.53,3.81,3.48C-576.07-168.26-572.82-168.21-569.58-168.21Zm-31.54-13.71c0-3.36-.08-6.73,0-10.09.07-2.46-.9-3.82-3.41-3.83-6.85,0-13.71,0-20.56,0-2.21,0-3.41,1.17-3.4,3.52q0,10.27,0,20.55c0,2.54,1.18,3.58,3.67,3.57q10.08-.08,20.18,0c2.55,0,3.59-1.13,3.52-3.64C-601.19-175.19-601.12-178.56-601.12-181.92Zm31.79-25.21c3.37,0,6.73-.06,10.1,0,2.73.06,3.8-1.24,3.78-3.87-.06-6.6,0-13.21,0-19.81,0-2.45-1-3.72-3.56-3.72q-10.28,0-20.56,0c-2.55,0-3.57,1.25-3.56,3.69,0,6.73,0,13.46,0,20.19,0,2.51,1.23,3.58,3.72,3.52C-576.06-207.19-572.69-207.13-569.33-207.13Zm-31.79-13.72c0-3.37-.1-6.74,0-10.11.09-2.62-1.13-3.58-3.59-3.56q-10.12.06-20.23,0c-2.54,0-3.58,1.14-3.56,3.65q.09,10.11,0,20.23c0,2.55,1.13,3.56,3.65,3.53q10.11-.1,20.23,0c2.6,0,3.57-1.11,3.49-3.62C-601.2-214.1-601.12-217.48-601.12-220.85Z"
          transform="translate(672.96 304.37)"></path>
      </svg> <svg xmlns="http://www.w3.org/2000/svg" width="37.325" height="37.325" viewBox="0 0 37.325 37.325">
        <g id="Group_2" data-name="Group 2" transform="translate(-37 -37)">
          <rect id="Rectangle_1" data-name="Rectangle 1" width="45.9" height="6.885"
            transform="translate(41.868 37) rotate(45)" fill="#090909"></rect>
          <rect id="Rectangle_3" data-name="Rectangle 3" width="45.9" height="6.885"
            transform="translate(37 69.456) rotate(-45)" fill="#090909"></rect>
        </g>
      </svg>
    </div>
    <div class="calc-popup__container popup">
      <div class="form__title">Volumetric weight calculator</div>
      <div class="form__text">
        <p>To calculate the volumetric weight of your box, enter the three dimensions. The chargeable weight will be the
          higher of the gross weight and the volumetric weight.</p>
      </div>
      <form id="weight-form" class="clearfix">
        <div class="form-row input-format">
          <div class="label">Unit of measure:</div>
          <div class="radio"><input type="radio" name="dimension" value="cm" checked="">cm</div>
          <div class="radio"><input type="radio" name="dimension" value="in">inches</div>
        </div>
        <div class="form-row">
          <div class="weight-quarter label">Length</div>
          <div class="weight-quarter"><input type="number" min="0" class="weight-number" id="weight-length"></div>
        </div>
        <div class="form-row">
          <div class="weight-quarter label">Width</div>
          <div class="weight-quarter"><input type="number" min="0" class="weight-number" id="weight-width"></div>
        </div>
        <div class="form-row">
          <div class="weight-quarter label">Height</div>
          <div class="weight-quarter"><input type="number" min="0" class="weight-number" id="weight-height"></div>
        </div>
        <div class="form__legend">For a more accurate estimate, round fractions to the next integer.</div>
        <div class="output-text">Volumetric weight: </div>
        <div class="output-result"></div>
      </form>
    </div>
  </div>
</div>

<!-- French -->
<div *ngIf="isFrench">
  <div class="calc-popup popup" style="z-index: 99999999;">
    <div class="toggle">
      <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 162 162">
        <defs>
          <style>
            .cls-1,
            .cls-2 {
              fill: #fff;
            }

            .cls-1 {
              opacity: 0;
            }
          </style>
        </defs>
        <title>calc-icon</title>
        <rect class="cls-1" width="162" height="162"></rect>
        <path class="cls-2"
          d="M-641.73-223.45q0-31.29,0-62.58c0-5.87,1.73-7.66,7.52-7.66q42.16,0,84.32,0c5.73,0,7.7,1.92,7.7,7.61q0,62.77,0,125.55c0,5.49-2,7.47-7.45,7.47q-42.36,0-84.7,0c-5.58,0-7.36-1.82-7.36-7.43q0-31.47,0-63Zm49.38-26.43h30c4.58,0,6.57-1.59,6.81-6a119.45,119.45,0,0,0,0-13.11c-.25-4.31-2.16-5.85-6.51-5.85h-60c-4.46,0-6.16,1.5-6.34,5.93-.17,4.11-.14,8.24,0,12.36.13,5.14,1.75,6.67,6.83,6.68Zm22.77,81.67c3.37,0,6.74,0,10.11,0,2.6.05,4-1,4-3.7q0-10.11,0-20.22c0-2.5-1.28-3.71-3.67-3.73-6.86,0-13.73,0-20.6,0-2.26,0-3.4,1.29-3.4,3.56q0,10.31,0,20.6c0,2.54,1.37,3.53,3.81,3.48C-576.07-168.26-572.82-168.21-569.58-168.21Zm-31.54-13.71c0-3.36-.08-6.73,0-10.09.07-2.46-.9-3.82-3.41-3.83-6.85,0-13.71,0-20.56,0-2.21,0-3.41,1.17-3.4,3.52q0,10.27,0,20.55c0,2.54,1.18,3.58,3.67,3.57q10.08-.08,20.18,0c2.55,0,3.59-1.13,3.52-3.64C-601.19-175.19-601.12-178.56-601.12-181.92Zm31.79-25.21c3.37,0,6.73-.06,10.1,0,2.73.06,3.8-1.24,3.78-3.87-.06-6.6,0-13.21,0-19.81,0-2.45-1-3.72-3.56-3.72q-10.28,0-20.56,0c-2.55,0-3.57,1.25-3.56,3.69,0,6.73,0,13.46,0,20.19,0,2.51,1.23,3.58,3.72,3.52C-576.06-207.19-572.69-207.13-569.33-207.13Zm-31.79-13.72c0-3.37-.1-6.74,0-10.11.09-2.62-1.13-3.58-3.59-3.56q-10.12.06-20.23,0c-2.54,0-3.58,1.14-3.56,3.65q.09,10.11,0,20.23c0,2.55,1.13,3.56,3.65,3.53q10.11-.1,20.23,0c2.6,0,3.57-1.11,3.49-3.62C-601.2-214.1-601.12-217.48-601.12-220.85Z"
          transform="translate(672.96 304.37)"></path>
      </svg> <svg xmlns="http://www.w3.org/2000/svg" width="37.325" height="37.325" viewBox="0 0 37.325 37.325">
        <g id="Group_2" data-name="Group 2" transform="translate(-37 -37)">
          <rect id="Rectangle_1" data-name="Rectangle 1" width="45.9" height="6.885"
            transform="translate(41.868 37) rotate(45)" fill="#090909"></rect>
          <rect id="Rectangle_3" data-name="Rectangle 3" width="45.9" height="6.885"
            transform="translate(37 69.456) rotate(-45)" fill="#090909"></rect>
        </g>
      </svg>
    </div>
    <div class="calc-popup__container popup">
      <div class="form__title">Calculateur de poids volumétrique</div>
      <div class="form__text">
        <p>Afin de calculer le poids volumétrique de votre pièce, entrez les trois dimensions. Le poids facturé sera le
          plus élevé entre le poids brut et le poids volumétrique.</p>
      </div>
      <form id="weight-form" class="clearfix">
        <div class="form-row input-format">
          <div class="label">Unité de mesure :</div>
          <div class="radio"><input type="radio" name="dimension" value="cm" checked="">cm</div>
          <div class="radio"><input type="radio" name="dimension" value="in">pouces</div>
        </div>
        <div class="form-row">
          <div class="weight-quarter label">Longueur</div>
          <div class="weight-quarter"><input type="number" min="0" class="weight-number" id="weight-length"></div>
        </div>
        <div class="form-row">
          <div class="weight-quarter label">Largeur</div>
          <div class="weight-quarter"><input type="number" min="0" class="weight-number" id="weight-width"></div>
        </div>
        <div class="form-row">
          <div class="weight-quarter label">Hauteur</div>
          <div class="weight-quarter"><input type="number" min="0" class="weight-number" id="weight-height"></div>
        </div>
        <div class="form__legend">Pour une estimation plus exacte, arrondissez les fractions à l’entier suivant.</div>
        <div class="output-text">Poids volumétrique: </div>
        <div class="output-result"></div>
      </form>
    </div>
  </div>
</div>