import { MatDialogModule as MatDialogModule } from '@angular/material/dialog';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from "./admin-routing.module";
import { SharedModule } from "@app/shared/shared.module";
import { AngularSplitModule } from "angular-split";
import { RightsComponent } from "../Rights/rights.component";
import { UtilitiesComponent } from "../Utilities/Utilities.component";
import { StatisticsComponent } from "../statistics/statistics.component";
import { NgApexchartsModule } from 'ng-apexcharts';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    AngularSplitModule,
    MatDialogModule,
    NgApexchartsModule
  ],
  declarations: [AdminComponent, RightsComponent, UtilitiesComponent, StatisticsComponent]
})
export class AdminModule { }
