<div style="text-align:center;background-color:rgb(99, 99, 99);color:white;border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;box-shadow: 2px 3px 2px darkgray;">
    <h3>DIVISIONS</h3>
</div>
<div style="margin-left:8px;margin-right:8px;overflow: auto;">
  <div class="table-header" style="margin-bottom:8px;float: right">
    <!-- <button mat-raised-button color="warn" mat-button (click)="removeSelectedRows()" style="margin-right:5px">
      {{ 'app.manageClients.removerows' | translate }}
      <mat-icon>
        delete
      </mat-icon>
    </button> -->
    <div class="table-header arrow-text" style="margin-bottom:12px;text-align: center;font-size: .9em;font-weight: bold;color:rgb(30, 10, 214)">
      <span>{{ 'app.manageClients.infoDivisionsGrid' | translate }}</span>
    </div>
    <button mat-raised-button color="primary" mat-button (click)="addRow()" [disabled]="editing" style="float:right">
      {{ 'app.manageClients.addrow' | translate }}
      <mat-icon>
        add
      </mat-icon>
    </button>
  </div>
  <table mat-table matSort matSortActive="Div_Description" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource" style="width:100%;font-size:6px !important" #DivisionsTable>
    <ng-container [matColumnDef]="col.key" *ngFor="let col of COLUMNS_SCHEMA">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="col.key == 'isEdit'" [ngStyle]="{'text-align': col.type == 'checkbox' ? 'center' : 'normal'}">
        <div *ngIf="translateService.currentLang == 'fr'">{{ col.label }}</div>
        <div *ngIf="translateService.currentLang != 'fr'">{{ col.labelEn }}</div>
      </th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'text-align': col.type.startsWith('checkbox') ? 'center' : 'normal'}">
        <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
          <ng-container *ngSwitchCase="'isSelected'">
            <mat-checkbox (change)="element.isSelected = $event.checked"></mat-checkbox>
          </ng-container>
          <div class="btn-edit" *ngSwitchCase="'isEdit'">
            <div *ngIf="!editing">
              <button mat-button class="button-edit" (click)="editDivision(element)" matTooltip="{{ 'app.importOrders.ttEdit' | translate }}" matTooltipPosition="above">
                <mat-icon>
                  edit
                </mat-icon>
              </button>
              <button mat-button class="button-remove" (click)="removeRow(element)" matTooltip="{{ 'app.misc.deactivate' | translate }}" matTooltipPosition="above">
                <mat-icon>
                  toggle_off
                </mat-icon>
            </button>
            </div>
          </div>
          <span *ngSwitchCase="'date'">
            {{ element[col.key] | date: 'mediumDate' }}
          </span>
          <span *ngSwitchCase="'checkboxProd'">
            <div *ngIf="element[col.key]" style="margin-top:8px">
              <mat-icon>
                check_box
              </mat-icon>
            </div>
            <div *ngIf="!element[col.key]" style="margin-top:8px">
              <mat-icon>
                check_box_outline_blank
              </mat-icon>
            </div>
          </span>
          <span *ngSwitchCase="'checkboxTemplate'">
            <div *ngIf="element[col.key]" style="margin-top:8px">
              <mat-icon>
                check_box
              </mat-icon>
            </div>
            <div *ngIf="!element[col.key]" style="margin-top:8px">
              <mat-icon>
                check_box_outline_blank
              </mat-icon>
            </div>
          </span>
          <span *ngSwitchDefault>
            {{ element[col.key] }}
          </span>
        </div>
        <div [ngSwitch]="col.type" *ngIf="element.isEdit">
          <div *ngSwitchCase="'isSelected'"></div>
          <div class="btn-edit" *ngSwitchCase="'isEdit'" style="white-space: nowrap;/*position: absolute;left:80%;top:13%;*/">
            <button mat-flat-button color="warn" (click)="cancelEdit(element)" matTooltip="{{ 'app.importOrders.ttCancel' | translate }}" matTooltipPosition="above">
              <mat-icon>
                cancel
              </mat-icon>
            </button>
            <button mat-flat-button color="primary" (click)="element.isEdit = saveChanges(element)" style="margin-left:5px" matTooltip="{{ 'app.importOrders.ttSave' | translate }}" matTooltipPosition="above">
              <mat-icon>
                done
              </mat-icon>
            </button>
          </div>
          <mat-form-field *ngSwitchCase="'listCountries'" >
            <mat-select [(value)]="element[col.key]">
              <mat-option *ngFor="let c of countriesList" [value]="c.CON_Code">
                {{ c.CON_Name }}
              </mat-option>
            </mat-select>
          </mat-form-field>          
          <mat-form-field *ngSwitchCase="'listCurrencies'" >
            <mat-select [(value)]="element[col.key]">
              <mat-option *ngFor="let c of currenciesList" [value]="c.CUR_Code">
                {{ c.CUR_Code }}
              </mat-option>
            </mat-select>
          </mat-form-field>          
          <mat-checkbox *ngSwitchCase="'checkboxProd'" [(ngModel)]="element[col.key]" [checked]="element[col.key].Div_AllowProdEnv"></mat-checkbox>
          <mat-checkbox *ngSwitchCase="'checkboxTemplate'" [(ngModel)]="element[col.key]" [checked]="element[col.key]?.Div_IsTemplate || false" [disabled]="element[col.key]"></mat-checkbox>          
          <mat-form-field class="form-input" *ngSwitchCase="'textModificator'">
            <input [type]="text" matInput [(ngModel)]="element[col.key]" [disabled]="true" />
          </mat-form-field>
          <mat-form-field class="form-input" *ngSwitchDefault>
            <input [type]="col.type" matInput [(ngModel)]="element[col.key]" />
          </mat-form-field>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="highlight(row)" [ngClass]="{'highlightTableColor': selectedDivisionId == row.Div_Id}" style="cursor:pointer !important"></tr>
  </table>
</div>

<!-- Overlay displayed while the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>
