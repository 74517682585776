import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { Carrier } from "@app/core/enums/carrier";
import { TranslateService } from "@ngx-translate/core";
import { spCSDAGetOrdersListResult } from "@app/shared/CSDAModels/spCSDAGetOrdersListResult";
import { OrderResult } from "@app/shared/CSDAModels/OrderResult";
import { OrdersSurcharges } from "@app/shared/CSDAModels/OrdersSurcharges";
import { OrdersTaxes } from "@app/shared/CSDAModels/OrdersTaxes";
import { Quote } from "@app/shared/CSDAModels/Quote";
import { QuoteSurcharge } from "@app/shared/CSDAModels/QuoteSurcharge";
import { QuoteTaxe } from "@app/shared/CSDAModels/QuoteTaxe";
import { UnitOfMeasure } from "@app/core/enums/unit-of-measure";

@Component({
  selector: 'app-cost-breakdown',
  templateUrl: './cost-breakdown.component.html',
  styleUrls: ['./cost-breakdown.component.scss']
})
export class CostBreakdownComponent implements OnInit {
  isFrench: Boolean = this.translateService.currentLang === "fr";
  taxable: boolean = true;
  addedFees: boolean = true;
  additionnalFees: QuoteSurcharge[] = [];
  additionnalFeesDataSource = new MatTableDataSource([]);
  feesTableColumns: string[] = [
    "AdditionnalFeesType",
    "Fees"
  ];

  taxes: QuoteTaxe[] = [];
  taxesDataSource = new MatTableDataSource([]);
  taxesTableColumns: string[] = [
    "TaxesType",
    "Taxe"
  ];

  unitOfMeasure = UnitOfMeasure;

  constructor(
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {
      quote: Quote
    }    
  ) { }

  ngOnInit(): void {
    
    /** Create the data table for the additionnal fees and removing the 0 values */
    if (this.data.quote != null) {
      this.data.quote.Price.Surcharges.forEach(element => {
        if (element && element.Amount !== 0) {
          this.additionnalFees.push(element);
        }
      });
      this.additionnalFeesDataSource = new MatTableDataSource(this.additionnalFees);
    }
    else {
      this.addedFees = false;
    }
    
    // Expérimental
    this.data.quote.Price.Taxes.sort((a, b) => {
      if(a.Type < b.Type) {
        return -1;
      }
      if(a.Type > b.Type) {
        return 1;
      }
      return 0;
    });

    /** Create the data table for the taxes and removing the 0 values*/
    if (this.data.quote.Price.Taxes != null) {
      this.data.quote.Price.Taxes.forEach(element => {
        if (element && element.Amount !== 0) {
          this.taxes.push(element);
        }
      });
      this.taxesDataSource = new MatTableDataSource(this.taxes);
    }
    else {
      this.taxable = false;
    }    
  }
  /** Get the subtotal price of the shipment, to the base price we add all the additionnal fees 
     * @return The estimation of the subtotal before taxes
     */
  getShipmentSubtotalPrice(): number {

    /** The estimate start with the base price */
    var estimate = this.data.quote.Price.BasePrice;

    /** Then all the additionnal fees are added */
    if (this.data.quote.Price.Surcharges.length > 0) {
      this.data.quote.Price.Surcharges.forEach((item) => estimate = estimate + item.Amount)
    }

    return estimate;
  }

  changeTaxLang(taxName: string) {
    if(this.isFrench) {
      if(taxName === "GST")
        return "TPS";

      if(taxName === "QST")
        return "TVQ";

      if(taxName === "PST")
        return "TVP";

      if(taxName === "PSTQST")
        return "TVPTVQ";

      return taxName;
    }
    else
      return taxName;
  }
}
