<div class="processing-screen">
  <div class="overlay"></div>
  <div class="processing-card">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>{{ message }}</mat-card-title>
      </mat-card-header>
      <mat-card-content class="processing-card-content">
        <mat-progress-bar class="processing-card-progressBar" mode="indeterminate"></mat-progress-bar>
      </mat-card-content>
    </mat-card>
  </div>
</div>
