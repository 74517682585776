
<div class="table-container">
    <!-- Search bar -->
    <div class="table-container__actions">
        <div class="table-container__actions__filter">
            <mat-form-field>
                <input matInput type="text" [(ngModel)]="searchInput" (keyup)="applySearch($event.target.value)"
                    placeholder="{{ 'app.misc.search' | translate }}" />
                <button mat-button *ngIf="searchInput" matSuffix mat-icon-button aria-label="Clear"
                    (click)="applySearch('')">
                    <mat-icon>
                        close
                    </mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
    <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="PAC_Name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'app.customPackages.name' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [attr.data-label]="'app.customPackages.name' | translate">
                <span class="dots" *ngIf="row.PAC_Action != 'UPDATE' && row.PAC_Action != 'ADD'">
                    {{ row.PAC_Name }}
                </span>
                <mat-form-field appearance="outline" *ngIf="row.PAC_Action == 'UPDATE' || row.PAC_Action == 'ADD'">
                    <mat-label>{{'app.customPackages.name' | translate}}</mat-label>
                    <input matInput required maxlength="100" id="{{row.PAC_Name}}" [(ngModel)]="row.PAC_Name"
                        [ngModelOptions]="{standalone: true}" name="{{row.PAC_Name}}" autocomplete="customPackages"
                        placeholder=" {{'app.customPackages.name' | translate}}" />
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PAC_PackageType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'app.customPackages.packageType' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [attr.data-label]="'app.customPackages.packageType' | translate">
                <span class="dots" *ngIf="row.PAC_Action != 'UPDATE' && row.PAC_Action != 'ADD'">
                    {{ getPackageTypeName(row.PAC_PackageType) }}
                </span>
                <mat-form-field appearance="outline" *ngIf="row.PAC_Action == 'UPDATE' || row.PAC_Action == 'ADD'">
                    <mat-label> {{'app.customPackages.packageType' | translate}}</mat-label>
                    <mat-select [(ngModel)]="row.PAC_PackageType">
                        <mat-option [value]="pacType.code" *ngFor="let pacType of packageTypes">{{pacType.name |
                            translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PAC_Description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'app.customPackages.description' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [attr.data-label]="'app.customPackages.description' | translate">
                <span class="dots" *ngIf="row.PAC_Action != 'UPDATE' && row.PAC_Action != 'ADD'">
                    {{ row.PAC_Description }}
                </span>
                <mat-form-field appearance="outline" *ngIf="row.PAC_Action == 'UPDATE' || row.PAC_Action == 'ADD'">
                    <mat-label>{{'app.customPackages.description' | translate}}</mat-label>
                    <input matInput maxlength="200" id="{{row.PAC_Description}}" [(ngModel)]="row.PAC_Description"
                        [ngModelOptions]="{standalone: true}" name="{{row.PAC_Description}}"
                        autocomplete="customPackages" placeholder=" {{'app.customPackages.description' | translate}}" />
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PAC_Quantity">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'app.customPackages.quantity' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [attr.data-label]="'app.customPackages.quantity' | translate">
                <span class="dots" *ngIf="row.PAC_Action != 'UPDATE' && row.PAC_Action != 'ADD'">
                    {{ row.PAC_Quantity }}
                </span>
                <mat-form-field appearance="outline" *ngIf="row.PAC_Action == 'UPDATE' || row.PAC_Action == 'ADD'">
                    <mat-label>{{'app.customPackages.quantity' | translate}}</mat-label>
                    <input matInput id="{{row.PAC_Quantity}}" [(ngModel)]="row.PAC_Quantity"
                        [ngModelOptions]="{standalone: true}" name="{{row.PAC_Quantity}}" autocomplete="customPackages"
                        class="text-right-align" type="text" pattern="[0-9.]*"
                        placeholder="{{'app.customPackages.quantity' | translate}}" />
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PAC_Length">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'app.customPackages.depth' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [attr.data-label]="'app.customPackages.depth' | translate">
                <span class="dots" *ngIf="row.PAC_Action != 'UPDATE' && row.PAC_Action != 'ADD'">
                    {{ row.PAC_Length }}
                </span>
                <mat-form-field appearance="outline" *ngIf="row.PAC_Action == 'UPDATE' || row.PAC_Action == 'ADD'">
                    <mat-label>{{'app.customPackages.depth' | translate}}</mat-label>
                    <span matSuffix *ngIf="row.UnitOfMeasure == 0">&nbsp;kg</span>
                    <span matSuffix *ngIf="row.UnitOfMeasure == 1">&nbsp;lbs</span>
                    <input matInput id="{{row.PAC_Length}}" [(ngModel)]="row.PAC_Length"
                        [ngModelOptions]="{standalone: true}" name="{{row.PAC_Length}}" autocomplete="customPackages"
                        class="text-right-align" type="text" pattern="[0-9.]*"
                        placeholder="{{'app.customPackages.depth' | translate}}" />
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PAC_Width">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'app.customPackages.width' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [attr.data-label]="'app.customPackages.width' | translate">
                <span class="dots" *ngIf="row.PAC_Action != 'UPDATE' && row.PAC_Action != 'ADD'">
                    {{ row.PAC_Width }}
                </span>
                <mat-form-field appearance="outline" *ngIf="row.PAC_Action == 'UPDATE' || row.PAC_Action == 'ADD'">
                    <mat-label>{{'app.customPackages.width' | translate}}</mat-label>
                    <span matSuffix *ngIf="row.UnitOfMeasure == 0">&nbsp;kg</span>
                    <span matSuffix *ngIf="row.UnitOfMeasure == 1">&nbsp;lbs</span>
                    <input matInput id="{{row.PAC_Width}}" [(ngModel)]="row.PAC_Width"
                        [ngModelOptions]="{standalone: true}" name="{{row.PAC_Width}}" autocomplete="customPackages"
                        class="text-right-align" type="text" pattern="[0-9.]*"
                        placeholder="{{'app.customPackages.width' | translate}}" />
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PAC_Height">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'app.customPackages.height' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [attr.data-label]="'app.customPackages.height' | translate">
                <span class="dots" *ngIf="row.PAC_Action != 'UPDATE' && row.PAC_Action != 'ADD'">
                    {{ row.PAC_Height }}
                </span>
                <mat-form-field appearance="outline" *ngIf="row.PAC_Action == 'UPDATE' || row.PAC_Action == 'ADD'">
                    <mat-label>{{'app.customPackages.height' | translate}}</mat-label>
                    <span matSuffix *ngIf="row.UnitOfMeasure == 0">&nbsp;kg</span>
                    <span matSuffix *ngIf="row.UnitOfMeasure == 1">&nbsp;lbs</span>
                    <input matInput id="{{row.PAC_Height}}" [(ngModel)]="row.PAC_Height"
                        [ngModelOptions]="{standalone: true}" name="{{row.PAC_Height}}" autocomplete="customPackages"
                        class="text-right-align" type="text" pattern="[0-9.]*"
                        placeholder="{{'app.customPackages.height' | translate}}" />
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PAC_Weight">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'app.customPackages.weight' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [attr.data-label]="'app.customPackages.weight' | translate">
                <span class="dots" *ngIf="row.PAC_Action != 'UPDATE' && row.PAC_Action != 'ADD'">
                    {{ row.PAC_Weight }}
                </span>
                <mat-form-field appearance="outline" *ngIf="row.PAC_Action == 'UPDATE' || row.PAC_Action == 'ADD'">
                    <mat-label>{{'app.customPackages.weight' | translate}}</mat-label>
                    <span matSuffix *ngIf="row.UnitOfMeasure == 0">&nbsp;kg</span>
                    <span matSuffix *ngIf="row.UnitOfMeasure == 1">&nbsp;lbs</span>
                    <input matInput id="{{row.PAC_Weight}}" [(ngModel)]="row.PAC_Weight"
                        [ngModelOptions]="{standalone: true}" name="{{row.PAC_Weight}}" autocomplete="customPackages"
                        class="text-right-align" type="text" pattern="[0-9.]*"
                        placeholder="{{'app.customPackages.weight' | translate}}" (change)="validateDryIceWeight(row)"/>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PAC_DryIceWeight">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'app.customPackages.dryIceWeight' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [attr.data-label]="'app.customPackages.dryIceWeight' | translate">
                <span class="dots" *ngIf="row.PAC_Action != 'UPDATE' && row.PAC_Action != 'ADD'">
                    {{ row.PAC_DryIceWeight }}
                </span>
                <mat-form-field appearance="outline" *ngIf="row.PAC_Action == 'UPDATE' || row.PAC_Action == 'ADD'">
                    <mat-label>{{'app.customPackages.dryIceWeight' | translate}}</mat-label>
                    <span matSuffix *ngIf="row.UnitOfMeasure == 0">&nbsp;kg</span>
                    <span matSuffix *ngIf="row.UnitOfMeasure == 1">&nbsp;lbs</span>
                    <input matInput id="{{row.PAC_DryIceWeight}}" [(ngModel)]="row.PAC_DryIceWeight"
                        [ngModelOptions]="{standalone: true}" name="{{row.PAC_DryIceWeight}}" autocomplete="customPackages"
                        class="text-right-align" type="text" pattern="[0-9.]*"
                        placeholder="{{'app.customPackages.dryIceWeight' | translate}}" (change)="validateDryIceWeight(row)" />
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="UnitOfMeasure">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'app.misc.unitOfMeasure' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [attr.data-label]="'app.misc.unitOfMeasure' | translate">
                <span class="dots" *ngIf="row.PAC_Action != 'UPDATE' && row.PAC_Action != 'ADD'">
                    {{ getUOMName(row.UnitOfMeasure) }}
                </span>
                <mat-form-field appearance="outline" *ngIf="row.PAC_Action == 'UPDATE' || row.PAC_Action == 'ADD'">
                    <mat-label> {{'app.misc.unitOfMeasure' | translate}}*</mat-label>
                    <mat-select [(ngModel)]="row.UnitOfMeasure">
                        <mat-option [value]="measure.code" *ngFor="let measure of measures">{{measure.name | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Actions
            </mat-header-cell>
            <mat-cell *matCellDef="let row" style="max-height: 10px;">
                <div *ngIf="row.PAC_Action == 'UPDATE' || row.PAC_Action == 'ADD'" style="margin-top: 12px">
                    <span style="cursor: pointer;margin-right: 5px;margin-top: 10px;"
                        matTooltip="{{'app.misc.cancel' | translate}}" matTooltipPosition="above"
                        (click)="onCancel(row)">
                        <mat-icon style="color:red" class="material-icons">
                            cancel
                        </mat-icon>
                    </span>
                    <span style="cursor: pointer;;margin-top: 10px;" matTooltip="{{'app.misc.save' | translate}}"
                        matTooltipPosition="above" (click)="onSaveCustomPackage(row)">
                        <mat-icon style="color: blue" class="material-icons">
                            save
                        </mat-icon>
                    </span>
                </div>
                <div *ngIf="row.PAC_Action != 'UPDATE' && row.PAC_Action != 'ADD'" style="margin-top: 10px">
                    <span style="margin-right: 10px;cursor:pointer" matTooltip="{{'app.misc.edit' | translate}}"
                        matTooltipPosition="above" (click)="onModifyCustomPackage(row)">
                        <mat-icon style="color: blue" class="material-icons">
                            edit
                        </mat-icon>
                    </span>
                    <span style="cursor:pointer" matTooltip="{{'app.misc.delete' | translate}}"
                        matTooltipPosition="above" (click)="onRemoveCustomPackage(row)">
                        <mat-icon style="color:red" class="material-icons">
                            delete
                        </mat-icon>
                    </span>
                </div>
            </mat-cell>
        </ng-container>

        <!-- Table header definition -->
        <mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true">
        </mat-header-row>

        <!-- Table row definition -->
        <mat-row *matRowDef="let row; columns: displayedColumns">
        </mat-row>
    </mat-table>
</div>
<br>
<button mat-raised-button color="primary" mat-button (click)="onAddCustomPackage()" [disabled]="!enableAdd">
    {{ 'app.customPackages.addCustomPackage' | translate }}
    <mat-icon class="material-icons">
        add
    </mat-icon>
</button>

<!-- The overlay to display when the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>