<div [@fadeInAnimation]>
  <h1>{{ 'app.profile.title' | translate }}</h1>
    <form [formGroup]="form">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{ 'app.profile.userName' | translate }}</mat-label>
        <input #userName matInput required placeholder="{{ 'app.profile.userName' | translate }}" autocomplete="new-username" formControlName="UserName">
      </mat-form-field>
    
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{ 'app.profile.password' | translate }}</mat-label>
        <input matInput required placeholder="{{ 'app.profile.password' | translate }}" autocomplete="new-password" formControlName="Password" type="password">
      </mat-form-field>
    
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{ 'app.profile.email' | translate }}</mat-label>
        <input matInput required placeholder="{{ 'app.profile.email' | translate }}" autocomplete="new-email" formControlName="Email">
      </mat-form-field>
    
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{ 'app.profile.phone' | translate }}</mat-label>
        <input required matInput type="text" mask="{{phoneMask}}" placeholder="{{ 'app.profile.phone' | translate }}" autocomplete="new-phone" formControlName="Phone">
      </mat-form-field>
    </form>

    <div class="button-container">
      <button mat-raised-button color="primary" (click)="save()">{{ 'app.profile.save' | translate }}</button>
      <button mat-raised-button color="warn" (click)="close()">{{ 'app.profile.cancel' | translate }}</button>
    </div>
</div>

<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate "></app-processing-overlay>