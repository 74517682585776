import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "./core/services/storage.service";
import { Router } from '@angular/router';
import { AuthenticationService } from "./core/services/authentication.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    standalone: false
})
export class AppComponent {
	title = "cubiShippingDashboard";
	constructor(
		public translate: TranslateService,
		public storageService: StorageService,
		public router: Router,
		private authService: AuthenticationService
	) {
		translate.addLangs(["en", "fr"]);
		const lang = this.storageService.getLanguage()
		lang ? this.translate.use(lang) : translate.setDefaultLang("en");
	}

	ngOnInit() {
		{
			const token = this.authService.currentTokenValue;
			if (!token || !this.authService.isAuthenticated())
				this.authService.loginWithToken(token);
			else
				void this.router.navigate(['/account/login']);		
		}
	}	
}
