import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Language } from "@app/core/enums/Language";
import { ValidationService } from "@app/core/services/validation.service";
import { GetImportTemplateDetail } from "@app/shared/CSDAModels/GetImportTemplateDetail";
import { environment } from "@env";
import { TranslateService } from "@ngx-translate/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  constructor(
    private httpClient: HttpClient,
		private translateService: TranslateService,
    private validationService: ValidationService,
  ) { }

  //------------------------
  // Get the list of clients
  //------------------------
  public getTemplate(): any {

    const Detail: GetImportTemplateDetail = {
      Language: this.translateService.currentLang === "fr" ? Language.French : Language.English,
      IsDevelopmentEnvironment: false
    };

    //--------------------------------------------------------------------------------------------------------------------------------------
    // Après 2 longues journées de recherche intensive pour comprendre pourquoi j'avais une erreur de Observable.  Voici le lien qui m'a aidé
    // https://www.concretepage.com/angular/angular-httpclient-post#:~:text=%7D%0A%20)%3B%0A%7D-,HttpClient,-.post%20and%20Response
    // J'ai donc ajouté , { observe: 'body', responseType: 'blob'}
    // L'erreur était : You provided 'undefined' where a stream was expected. You can provide an Observable, Promise, Array, or Iterable.
    //--------------------------------------------------------------------------------------------------------------------------------------
    return this.httpClient.post(environment.CSDAEndPoint + "/CSDA/GetImportTemplate", Detail, { observe: 'body', responseType: 'blob'})
        .pipe(
            map(data => {
                var validation = this.validationService.validate(data);

                if (validation.isValid === false) {
                    Swal.fire('', validation.message, "error");
                    return null;
                }

                if (validation.message !== "") {
                    Swal.fire('', validation.message, "info");
                }

                return data;
            })          
        );
  }
}
