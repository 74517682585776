import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

import { AuthenticationService } from "../services/authentication.service";

@Injectable({
	providedIn: "root"
})
export class AuthGuard  {
	constructor(private router: Router, private authenticationService: AuthenticationService) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.authenticationService.currentTokenValue) {
			return true;
		}

		void this.router.navigate(["/account/login"]);
		return false;
	}
}
