import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GetShopifyProductsResult } from "@app/shared/CSDAModels/GetShopifyProductsResult";
import { environment } from "@env";
import { RefreshProductsResult } from "@app/shared/CSDAModels/RefreshProductsResult";
import { ValidationService } from "@app/core/services/validation.service";
import Swal from "sweetalert2";
import { map } from "rxjs/operators";
import { Observable, of, from, BehaviorSubject } from "rxjs";
import { TranslateService } from '@ngx-translate/core';
import { switchMap, tap } from 'rxjs/operators';


@Injectable({
	providedIn: "root"
})
export class ProductService {
	public isLoading$ = new BehaviorSubject<boolean>(false);

	constructor(
		private httpClient: HttpClient,
		private validationService: ValidationService,
		private translateService: TranslateService,
	) { }

	public getShopifyProducts(): Observable<GetShopifyProductsResult> {
		return this.httpClient.post(
			environment.CSDAEndPoint + "/CSDA/GetShopifyProducts", null
		).pipe(
			map((data: GetShopifyProductsResult) => {
				return data;
			})
		);
	}

	public fetchProducts(searchText: string): Observable<RefreshProductsResult> {
		if (searchText.length < 3 && searchText.length > 0) {
			Swal.fire('', this.translateService.instant('app.shopifyProducts.minimum'), "info");
			return of(null);
		}
	
		if (!searchText) {
			return from(Swal.fire({
				title: "Confirmation",
				text: this.translateService.instant("app.shopifyProducts.bigListQuestion"),
				icon: "question",
				showDenyButton: true,
				denyButtonText: this.translateService.instant("app.misc.no"),
				confirmButtonText: this.translateService.instant("app.misc.yes"),
			})).pipe(
				switchMap(result => {
					if (result.isConfirmed) {
						this.isLoading$.next(true);
						return this.loadData(searchText);
					} else {
						return of(null);
					}
				}),
				tap(() => this.isLoading$.next(false))
			);
		} else {
			this.isLoading$.next(true);
			return this.loadData(searchText).pipe(
				tap(() => this.isLoading$.next(false))
			);
		}
	}

	public loadData(searchText: string): Observable<RefreshProductsResult> {
		return this.httpClient.post<RefreshProductsResult>(environment.CSDAEndPoint + "/CSDA/RefreshProducts?searchText=" + encodeURIComponent(searchText), {}, { responseType: 'json' })
		.pipe(
			map(data => {
				var validation = this.validationService.validate(data);

				if (validation.isValid === false) {
					Swal.fire('', validation.message, "error");
					return null;
				}

				if (validation.message !== "") {
					Swal.fire('', validation.message, "info");
				}

				return data !== undefined ? data : null;
			})
		);	
	}
}
