<div style="display: flex; justify-content: space-between; flex-wrap: wrap; gap: 5px; width: 100%; height: auto;margin-top:40px">
  <!-- Chart 1 -->
  <div style="flex: 1; min-width: 49%; max-width: 49%; height: 560px; border: 2px solid green;">
    <div>
      <div class="title">TOP 10 ACTIVE ORDERS</div>
      <apx-chart
        [series]="chartOptionsActiveOrders.series"
        [chart]="chartOptionsActiveOrders.chart"
        [labels]="chartOptionsActiveOrders.labels"
        [dataLabels]="chartOptionsActiveOrders.dataLabels"
        [colors]="chartOptionsActiveOrders.colors"
        [tooltip]="chartOptionsActiveOrders.tooltip"
        [legend]="chartOptionsActiveOrders.legend"
        [title]="chartOptionsActiveOrders.title">
      </apx-chart>
    </div>
  </div>

  <!-- Chart 2 -->
  <div style="flex: 1; min-width: 49%; max-width: 49%; height: 560px; border: 2px solid green;">
    <div>
      <div class="title">TOP 5 INVOICE AMOUNT</div>
      <apx-chart
        [series]="chartOptionsInvoiceAmounts.series"
        [chart]="chartOptionsInvoiceAmounts.chart"
        [labels]="chartOptionsInvoiceAmounts.labels"
        [dataLabels]="chartOptionsInvoiceAmounts.dataLabels"
        [colors]="chartOptionsInvoiceAmounts.colors"
        [tooltip]="chartOptionsInvoiceAmounts.tooltip"
        [legend]="chartOptionsInvoiceAmounts.legend"
        [title]="chartOptionsInvoiceAmounts.title"
        [grid]="chartOptionsInvoiceAmounts.grid"
        [xaxis]="chartOptionsInvoiceAmounts.xaxis"
        [yaxis]="chartOptionsInvoiceAmounts.yaxis">
      </apx-chart>
    </div>
  </div>
</div>

<div style="display: flex; justify-content: space-between; flex-wrap: wrap; width: 100%; height: auto; gap: 5px; margin-top: 20px;">
  <div *ngFor="let chart of chartOptionsTotals; let i = index" style="flex: 1; min-width: 24%; max-width: 24%; height: 250px; border: 2px solid green;">
    <apx-chart
      [series]="chart.series"
      [chart]="chart.chart"
      [labels]="chart.labels"
      [colors]="chart.colors"
      [plotOptions]="chart.plotOptions"
      [dataLabels]="chart.dataLabels"
      [tooltip]="chart.tooltip"
      [legend]="chart.legend"
      [responsive]="chart.responsive">
    </apx-chart>
  </div>
</div>




<app-processing-overlay *ngIf="isProcessing" [message]="'app.misc.processing' | translate"></app-processing-overlay>
