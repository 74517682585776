<form (ngSubmit)="onSubmit()" #addressEditorForm="ngForm" >
    <div class="address-editor-css-main">
        <div class="address-editor-css-main__row">
            <div class="address-editor-css-main__card">
                <mat-card appearance="outlined">
                    <mat-card-title>
                        {{ "app.addressEditor.addressTitle" | translate }}
                    </mat-card-title>
                    <br/>
                    <mat-card-content>
                        <div class="form">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.companyName' | translate}}</mat-label>
                                <input 
                                    matInput 
                                    required 
                                    autocomplete="shipper"
                                    maxlength="100" 
                                    placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.companyName' | translate}}"
                                    id="ADD_Company"
                                    [(ngModel)]="AddressContainerModel.Address.ADD_Company"
                                    name="ADD_Company"
                                />
                            </mat-form-field>

                            <!-- Country input line with selector -->
                            <mat-form-field appearance="outline">
                                <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.countryName' | translate}}</mat-label>
                                <mat-select
                                    matInput
                                    required
                                    id="ADD_CON_Ref"
                                    [(ngModel)]="AddressContainerModel.Address.ADD_CON_Ref"
                                    name="ADD_CON_Ref"
                                    (selectionChange)="changeCountryEvent()">
                                    <mat-option [value]="country.CON_ID" *ngFor="let country of countriesList">
                                        {{country.CON_Name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                
                            <!-- Google search box -->
                            <mat-form-field class="full-width" [appearance]="appearance">
                                <mat-label style="color: rgb(175, 149, 0);">{{'app.misc.searchAddress' | translate}}</mat-label>
                                <!------------------------------------------------------------------------------------------------------------------------
                                -- Pour désactiver le autofill du browser quand un input est géré par
                                -- Google Places AutoComplete faut ajouter onfocus comme vous le voyez ici-bas
                                -- https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3/56632769#56632769
                                ------------------------------------------------------------------------------------------------------------------------->
                                <input
                                    ngx-google-places-autocomplete
                                    matInput
                                    placeholder="{{'app.misc.searchAddress' | translate}}"
                                    class="form-control"
                                    autocomplete="shipperGoogle"
                                    onfocus="this.setAttribute('autocomplete', 'shipperGoogle');"
                                    #SearchAddress
                                />                                
                            </mat-form-field>
                
                            <!-- Street No, Street Name and Unit -->
                            <div class="form__row">
                                <!-- Street name input line -->
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.streetName' | translate}}</mat-label>
                                    <input
                                        matInput
                                        required
                                        maxlength="100"
                                        id="ADD_StreetName"
                                        [(ngModel)]="AddressContainerModel.Address.ADD_StreetName"
                                        name="ADD_StreetName"
                                        autocomplete="shipper"
                                        placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.streetName' | translate}}"
                                    />
                                </mat-form-field>
                
                                <!-- Unit -->
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.unit' | translate}}</mat-label>
                                    <input
                                        matInput
                                        maxlength="100"
                                        id="ADD_Unit"
                                        [(ngModel)]="AddressContainerModel.Address.ADD_Unit"
                                        name="ADD_Unit"
                                        placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.unit' | translate}}"
                                        autocomplete="shipper"
                                        #UnitElement
                                    />
                                </mat-form-field>
                            </div>
                
                            <!-- City input line -->
                            <mat-form-field appearance="outline">
                                <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.cityName' | translate}}</mat-label>
                                <input
                                    matInput
                                    required
                                    maxlength="100"
                                    id="ADD_City"
                                    [(ngModel)]="AddressContainerModel.Address.ADD_City"
                                    name="ADD_City"
                                    autocomplete="shipper"
                                    placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.cityName' | translate}}"
                                />
                            </mat-form-field>
                
                            <!-- Postal code and province input line -->
                            <div class="form__row">
                                <!-- Postal Code -->
                                <div style="width:50%">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'app.shopifyOrders.details.shipperConsigneeDetails.zipCode' | translate}}</mat-label>
                                    <input
                                        matInput
                                        required
                                        maxlength="50"
                                        id="ADD_Zip"
                                        [(ngModel)]="AddressContainerModel.Address.ADD_Zip"
                                        name="ADD_Zip"
                                        autocomplete="shipper"
                                        placeholder="{{'app.shopifyOrders.details.shipperConsigneeDetails.zipCode' | translate}}"
                                    />
                                </mat-form-field>
                                </div>
                
                                <!-- List of states / provinces -->
                                <div style="width:49%">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'app.misc.state' | translate}}</mat-label>
                                    <mat-select
                                        matInput
                                        matNativeControl
                                        required
                                        id="ADD_PRV_Ref"
                                        [(ngModel)]="AddressContainerModel.Address.ADD_PRV_Ref"
                                        name="ADD_PRV_Ref"
                                    >
                                        <mat-option [value]="state.PRV_ID" *ngFor="let state of FilteredStateProvinces">
                                            {{state.PRV_Name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                </div>
                            </div>
                    
                            <!-- Special instructions -->
                            <mat-form-field appearance="outline">
                                <mat-label>{{'app.shopifyOrders.details.specialInstruction' | translate}}</mat-label>
                                <input
                                    matInput
                                    maxlength="90"
                                    id="ADD_SpecialInstructions"
                                    [(ngModel)]="AddressContainerModel.Address.ADD_SpecialInstructions"
                                    name="ADD_SpecialInstructions"
                                    autocomplete="shipper"
                                    placeholder="{{ 'app.shopifyOrders.details.specialInstruction' | translate }}"
                                />
                            </mat-form-field>

                            <!-- Tax ID -->
                            <mat-form-field appearance="outline">
                                <mat-label>{{'app.misc.taxID' | translate}}</mat-label>
                                <textarea
                                    matInput
                                    maxlength="90"
                                    id="ADD_TaxID"
                                    [(ngModel)]="AddressContainerModel.Address.ADD_TaxID"
                                    name="ADD_TaxID"
                                    autocomplete="shipper"
                                    placeholder="{{ 'app.misc.taxID' | translate }}"
                                >
                                </textarea>
                            </mat-form-field>

                            <mat-checkbox
                                appearance="none" style="margin-left:8px;margin-bottom:22px"
                                matInput
                                id="ADD_IsResidential"
                                [(ngModel)]="AddressContainerModel.Address.ADD_IsResidential"
                                name="ADD_IsResidential"
                            >
                                {{'app.shopifyOrders.details.isResidential' | translate}}
                            </mat-checkbox>

                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <!----------------------------------
        -- Contains the Contacts Detail Form
        ----------------------------------->
        <div class="address-editor-css-main__row ">
            <div class="address-editor-css-main__card">
                <mat-card appearance="outlined">
                    <!-- Card title -->
                    <mat-card-title>
                        {{ "app.addressEditor.contactsTitle" | translate }}
                    </mat-card-title>
                    <br/>
                    <mat-card-content>
                        <div class="form">
                            <ng-container *ngFor="let contact of AddressContainerModel.Contacts; let count = count">
                                <div class="form__row">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'app.addressEditor.name' | translate}}</mat-label>
                                        <input
                                            matInput
                                            required
                                            maxlength="100"
                                            id="{{ADC_Name}}"
                                            [(ngModel)]="contact.ADC_Name" [ngModelOptions]="{standalone: true}"
                                            name="{{ADC_Name}}"
                                            autocomplete="shipper"
                                            placeholder="{{'app.addressEditor.name' | translate}}"
                                        />
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'app.addressEditor.email' | translate}}</mat-label>
                                        <input
                                            matInput
                                            required
                                            maxlength="200"
                                            id="{{ADC_Email}}"
                                            [(ngModel)]="contact.ADC_Email" [ngModelOptions]="{standalone: true}"
                                            name="{{ADC_Email}}"
                                            autocomplete="shipper"
                                            placeholder="{{'app.addressEditor.email' | translate}}"
                                        />
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'app.addressEditor.phone' | translate}}</mat-label>
                                        <input
                                            matInput
                                            required
                                            maxlength="50"
                                            id="{{ADC_Phone}}"
                                            [(ngModel)]="contact.ADC_Phone" [ngModelOptions]="{standalone: true}"
                                            name="{{ADC_Phone}}"
                                            autocomplete="shipper"
                                            placeholder="{{'app.addressEditor.phone' | translate}}"
                                        />
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'app.addressEditor.extension' | translate}}</mat-label>
                                        <input
                                            matInput
                                            maxlength="50"
                                            id="{{ADC_PhoneExt}}"
                                            [(ngModel)]="contact.ADC_PhoneExt" [ngModelOptions]="{standalone: true}"
                                            name="{{ADC_PhoneExt}}"
                                            autocomplete="shipper"
                                            placeholder="{{'app.addressEditor.extension' | translate}}"
                                        />
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'app.addressEditor.fax' | translate}}</mat-label>
                                        <input
                                            matInput
                                            maxlength="50"
                                            id="{{ADC_Fax}}"
                                            [(ngModel)]="contact.ADC_Fax" [ngModelOptions]="{standalone: true}"
                                            name="{{ADC_Fax}}"
                                            autocomplete="shipper"
                                            placeholder="{{'app.addressEditor.fax' | translate}}"
                                        />
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'app.addressEditor.pager' | translate}}</mat-label>
                                        <input
                                            matInput
                                            maxlength="50"
                                            id="{{ADC_Pager}}"
                                            [(ngModel)]="contact.ADC_Pager" [ngModelOptions]="{standalone: true}"
                                            name="{{ADC_Pager}}"
                                            autocomplete="shipper"
                                            placeholder="{{'app.addressEditor.pager' | translate}}"
                                        />
                                    </mat-form-field>

                                    <button
                                        #menuContacts="matMenuTrigger"
                                        type="button"
                                        mat-mini-fab
                                        [matMenuTriggerFor]="menu"
                                        color="primary"
                                        style="margin-left:12px;margin-top: 5px;"
                                        [ngStyle]="{'background-color': contact.ADC_Typ_Ref != 26 ? 'green' : '#5388ee'}"
                                    >
                                        <mat-icon>
                                            more_vert
                                        </mat-icon>
                                    </button>

                                    <mat-menu
                                        #menu="matMenu"
                                        xPosition="before"
                                        style="margin-left:5px;margin-right: 5px;padding:10px"
                                    >
                                        <div 
                                            mat-menu-item (click)="onRemoveContact(contact)"
                                            [disabled]="count == 1"
                                        >
                                            <mat-icon color="primary"> delete </mat-icon>
                                            <span>{{ "app.addressEditor.deleteContact" | translate }}</span>
                                        </div>
                                        <div style="margin-left: 15px;margin-top: 10px">
                                            <div class="inline-flex-container">
                                                <mat-icon color="primary">notifications</mat-icon>
                                                <mat-checkbox
                                                    appearance="none"
                                                    matInput
                                                    required
                                                    id="{{ADC_EmailNotification}}"
                                                    [(ngModel)]="contact.ADC_EmailNotification"
                                                    [ngModelOptions]="{standalone: true}"
                                                    name="{{ADC_EmailNotification}}"
                                                    autocomplete="shipper"
                                                >
                                                    {{'app.addressEditor.emailNotification' | translate}}
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                        <mat-divider style="margin-top:15px;margin-bottom:15px"></mat-divider>
                                        <mat-label style="margin-left:55px">{{'app.addressEditor.defaultAddressTypeLabel' | translate}}</mat-label>
                                        <div class="custom-menu-item" style="display:inline-flex" mat-menu-item (click)="$event.stopPropagation();$event.preventDefault">
                                            <mat-icon color="primary" style="margin-right: 15px;"> check </mat-icon>
                                            <mat-form-field appearance="outline">
                                                <mat-select
                                                    matInput
                                                    id="{{contact.ADC_Typ_Ref}}"
                                                    [(ngModel)]="contact.ADC_Typ_Ref" [ngModelOptions]="{standalone: true}"
                                                    name="{{contact.ADC_Typ_Ref}}"
                                                    autocomplete="shipper"
                                                    placeholder="{{'app.addressEditor.defaultAddressTypeLabel' | translate}}"
                                                    (selectionChange)="onChangeDefaultAddress(contact, menuContacts)"
                                                >
                                                    <mat-option 
                                                        [value]="type.Typ_Id" 
                                                        *ngFor="let type of DefaultAddressTypes"
                                                    >
                                                        {{ storageService.getLanguage() === "en" ? type.Typ_Name_En : type.Typ_Name_Fr }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>                                        
                                    </mat-menu>
                                </div>
                            </ng-container>
                
                            <!-- Add contact button -->
                            <div class="form__row form__row--end">
                                <button type="button" mat-raised-button style="max-height: 35px;" color="primary" (click)="onAddContact()">
                                    {{'app.addressEditor.addContact' | translate}}
                                    <mat-icon class="material-icons">
                                        add_circle
                                    </mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <!-- Save address button -->
        <button type="submit" style="max-height: 35px;width: 220px;margin-left:10px" mat-raised-button color="primary">
            {{ 'app.addressEditor.saveAddress' | translate }}
            <mat-icon class="material-icons">
                save
            </mat-icon>
        </button>                
    </div>
</form>

<!-- The overlay to display when the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>
  