import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { ValidationService } from "@app/core/services/validation.service";
import { Language } from "@app/core/enums/Language";
import { GetStatsDetail } from "@app/shared/CSDAModels/GetStatsDetail";
import { GetStatsResult } from "@app/shared/CSDAModels/GetStatsResult";
import { spCSDAGetActiveOrdersByUserResult } from "@app/shared/CSDAModels/spCSDAGetActiveOrdersByUserResult";
import { environment } from "@env";
import Swal from "sweetalert2";
import { spCSDAGetInvoiceAmountsByUserResult } from "@app/shared/CSDAModels/spCSDAGetInvoiceAmountsByUserResult";
import { spCSDAGetTotalsStatsResult } from "@app/shared/CSDAModels/spCSDAGetTotalsStatsResult";

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  standalone: false
})

export class StatisticsComponent implements OnInit, AfterViewInit, OnDestroy  {
  isProcessing: boolean = true;
  chartOptionsActiveOrders: any;
  chartOptionsInvoiceAmounts: any;
  chartOptionsTotals: any;

  activeOrdersByUsers: spCSDAGetActiveOrdersByUserResult[];
  invoiceAmountsByUsers: spCSDAGetInvoiceAmountsByUserResult[];
  totalsStats: spCSDAGetTotalsStatsResult;

  constructor(
		private translateService: TranslateService,
    private httpClient: HttpClient,
    private validationService: ValidationService,
  ) {}
  
  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.getChartOptionsActiveOrders();
    this.getChartOptionsInvoiceAmounts();
    this.initializeChartOptionsTotals();

    var statsDetail: GetStatsDetail = {
      //--------------------
      // Deep clone Products
      //--------------------
      Language: Language.English,
      IsDevelopmentEnvironment: false
    }

    this.httpClient.post<GetStatsResult>(environment.CSDAEndPoint + "/CSDA/GetStats", statsDetail).subscribe(data => {
      var validation = this.validationService.validate(data);

      if (validation.isValid === false) {
        this.isProcessing = false;
        Swal.fire('', validation.message, "error");
      }
      else {
        this.activeOrdersByUsers = data.ActiveOrdersByUsers;
        this.invoiceAmountsByUsers = data.InvoiceAmountsByUsers;
        this.totalsStats = data.TotalsStats;
        
        const seriesActiveOrders = this.activeOrdersByUsers.map(user => user.ActiveOrders);
        const labelsActiveOrders = this.activeOrdersByUsers.map(user => `${user.USR_FirstName} ${user.USR_LastName} / ${user.Div_Description}`);
    
        this.chartOptionsActiveOrders.series = seriesActiveOrders;
        this.chartOptionsActiveOrders.labels = labelsActiveOrders;
        
        const colors = ['#FF5733', '#13521f', '#3357FF', '#8C8C8C', '#FF33A6', '#007975', '#8E33FF', '#FFA633', '#FF3333', '#00140b'];

        const seriesInvoiceAmounts = this.invoiceAmountsByUsers.map((user, index) => ({
          x: `${user.Div_Description}`,
          y: user.TotalInvoice,
          customData: user,
          fillColor: colors[index % colors.length]
        }));
        
        this.chartOptionsInvoiceAmounts.series = [
          {
            name: 'Invoice Amount',
            data: seriesInvoiceAmounts,
          },
        ];

        this.getChartOptionsTotals();
        this.isProcessing = false;
      }
    });    
  }

  getChartOptionsActiveOrders() {
    this.chartOptionsActiveOrders = {
      series: [],
      chart: {
        type: 'pie',
        height: 450
      },
      labels: [],
      dataLabels: {
        enabled: true,
        formatter: function (val: number) {
          return `${val.toFixed(0)}%`;
        },
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['white']
        }
      },
      tooltip: {
        enabled: true,
        theme: 'dark',
        y: {
          formatter: function (val: number) {
            return `${val.toFixed(0)} orders`;
          }
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            minAngleToShowLabel: 0
          }
        }
      },      
      legend: {
        // show: false,
        position: 'right',
        fontSize: '10px',
        labels: {
          colors: ['#000']
        },
        onItemHover: {
          highlightDataSeries: false,
        },        
      },
      colors: ['#FF5733', '#13521f', '#3357FF', '#8C8C8C', '#FF33A6', '#007975', '#8E33FF', '#FFA633', '#FF3333', '#00140b']
    };
  }

  getChartOptionsInvoiceAmounts() {
    this.chartOptionsInvoiceAmounts = {
      series: [],
      labels: [],
      chart: {
        type: 'bar',
        height: 450,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: false,
        },
      },
      grid: {
        show: true, // Enable grid lines
        borderColor: '#ccc', // Light gray grid lines
        strokeDashArray: 0, // Solid grid lines
        position: 'back', // Ensure grid is rendered behind bars
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: number) {
          return `$${(val / 1e6).toFixed(2)}M`;
        },
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['#FFFFFF'],
        },
        offsetY: 3,
      },
      tooltip: {
        enabled: true,
        theme: 'dark',
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const item = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          return `
            <div style="padding: 8px; background: #000; color: #fff; border-radius: 4px;">
              <strong>${item.customData.USR_FirstName} ${item.customData.USR_LastName}</strong><br>
              Division: ${item.customData.Div_Description}<br>
              Invoice: $${item.customData.TotalInvoice.toLocaleString()}<br>
              Orders count: ${item.customData.TotalOrders}
            </div>
          `;
        },
      },
      xaxis: {
        labels: {
          style: {
            fontSize: '12px',
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontWeight: 'bold',
            colors: ['#000'], // Font color
          },
        },
        axisBorder: {
          show: true, // Ensure x-axis border is visible
          color: '#ccc',
        },
        axisTicks: {
          show: true, // Show tick marks on the x-axis
          color: '#ccc',
        },
      },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            return `$${(val / 1e6).toFixed(2)}M`;
          },
          style: {
            colors: ['#000'],
            fontSize: '12px',
            fontWeight: 'bold',
          },
        },
        axisBorder: {
          show: true, // Ensure y-axis border is visible
          color: '#ccc',
        },
        axisTicks: {
          show: true, // Show tick marks on the y-axis
          color: '#ccc',
        },
      },
      colors: [
        '#FF5733', '#13521f', '#3357FF', '#8C8C8C', '#FF33A6', '#007975', '#8E33FF', '#FFA633', '#FF3333', '#00140b',
      ],
      legend: {
        show: false,
      },
    };
  }
  
  initializeChartOptionsTotals() {
    const emptyLabels = ['Quotes', 'Shipments', 'Completed', 'Cancelled'];
    const emptyColors = ['#CCCCCC', '#AAAAAA', '#888888', '#666666'];

    // Create 4 placeholder donut charts
    this.chartOptionsTotals = Array(4).fill(null).map((_, index) => ({
      series: [0], // Placeholder value
      chart: {
        type: 'donut',
        height: 250
      },
      labels: [emptyLabels[index]], // Single label per chart
      colors: [emptyColors[index]], // Single color per chart
      plotOptions: {
        pie: {
          donut: {
            size: '65%', // Bigger will make the hole bigger and the donut smaller
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '16px',
                offsetY: -10 // Position the label above the value
              },
              value: {
                show: true,
                fontSize: '22px',
                // formatter: () => `${stats[index]}` // Show the value in the center
              },
              total: {
                show: false // Disable the "total" calculation across all charts
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: false // Disable default percentages (100%)
      },
      legend: {
        show: false // Remove legends since labels are in the center
      },
      tooltip: {
        enabled: false // Completely disable tooltips
      }, 
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    }));
  }

  getChartOptionsTotals() {
    const labels = ['Quotes', 'Shipments', 'Completed', 'Cancelled'];
    const colors = ['#FF6384', '#36A2EB', '#6e5b2a', '#4BC0C0'];
    const stats = [
      this.totalsStats.Quotes,
      this.totalsStats.Shipments,
      this.totalsStats.Completed,
      this.totalsStats.Cancelled,
    ];
  
    this.chartOptionsTotals.forEach((chart, index) => {
      chart.series = [stats[index]];
      chart.labels = [labels[index]];
      chart.colors = [colors[index]];
      chart.plotOptions.pie.donut.labels.total.label = labels[index];
      chart.plotOptions.pie.donut.labels.total.formatter = () => `${stats[index]}`;
    });
  }

  ngOnDestroy() {
    const chartElement = document.querySelector('.apexcharts-canvas');

    if (chartElement)
      chartElement.remove();
  }
}
