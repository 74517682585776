import { MatTableDataSource as MatTableDataSource } from "@angular/material/table";

import { Router } from "@angular/router";
import { MatSort, MatSortable } from "@angular/material/sort";
import { Component, ViewChild } from "@angular/core";
import { MatPaginator as MatPaginator } from "@angular/material/paginator";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { BaseComponentDirective } from "@app/core/base.component";
import { HeaderService } from "@app/core/services/header.service";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ShopifyOrdersTableModel } from "@app/features/shopify-orders/models/shopify-orders-table-model";
import { ShopifyOrdersService } from "../services/shopify-orders.service";
import { ThirdPartyService } from "@app/core/services/third-party.service";
import { Carrier } from "@app/core/enums/carrier";
import { StorageService } from "@app/core/services/storage.service";
import Swal from "sweetalert2";

@Component({
    selector: "app-shopify-orders",
    templateUrl: "./shopify-orders.component.html",
    styleUrls: ["./shopify-orders.component.scss"],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', maxHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: false
})

export class ShopifyOrdersComponent extends BaseComponentDirective {
  /** Variables for the data table */
  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [
    "orderNumber",
    "destinationName",
    "shippingService",
    "trackingNumber",
    "actions",
  ];
  expandedElement: ShopifyOrdersTableModel | null;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  carrier = Carrier;

  /** The search input value */
  searchInput = "";

  /** Variable used to display the overlay when the app is processing something */
  isProcessing = true;

  /** Constructor of the DashboardViewComponent class */
  constructor(
    private headerService: HeaderService,
    private translateService: TranslateService,
    private createShipmentService: ShopifyOrdersService,
    private router: Router,
    private thirdPartyService: ThirdPartyService,
    private storageService: StorageService
  ) {
    super();
  }

  /** Set parameters on the initialisation os the page */
  onInit(): void {
    /** Set the page title */
    this.headerService.setTitle(this.translateService.instant("app.menu.shopifyOrdersPageTitle"));

    /** Subscribe to the translate service to update the title when the language changes */
    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.headerService.setTitle(this.translateService.instant("app.menu.shopifyOrdersPageTitle"));
    });

    /** Get the shopify orders list */
    this.subscribe(
      this.createShipmentService.getShopifyOrders().subscribe((shopifyOrders) => {
        if (shopifyOrders !== null) {
          this.dataSource = new MatTableDataSource(shopifyOrders);
          this.dataSource.sort = this.sort;
          this.sort.sort(({ id: 'trackingNumber', start: 'asc' }) as MatSortable);
          this.dataSource.paginator = this.paginator;
          this.paginator._intl.itemsPerPageLabel = 'Items : ';
        }
        this.isProcessing = false;
      })
    )
  }

  /** Override for the onDestroy method */
  onDestroy(): void {
    this.translateService.onLangChange.unsubscribe;
  }

  /** Find the specified string in the data table
  * @param searchValue - The partial or complet word to find
  */
  applySearch(searchValue: string): void {

    /** If the filter value is an empty string we clear the seach bar */
    if (!searchValue) {
      this.searchInput = '';
    }

    /** Then we apply filter */
    this.dataSource.filter = searchValue.trim().toLowerCase();
  }

  /** Open a stepper to start constructing the shipment
   * @param row - The order response corresponding to the selected row
   */
  navigateToConstructShipment(row: ShopifyOrdersTableModel): void {
    sessionStorage.setItem("quoteType", '8'); // 8 = Quotes, 51 = Quick Quotes
    this.storageService.setShipmentOrigin('/shopify-orders')
    this.thirdPartyService.setOrderFromShopify(row.OriginalModel);
    this.storageService.setGoToStepTwo("false");
    void this.router.navigate(['/orders', 'CreateShipment']);
  }

	showEditProductsComponent() {
    this.router.navigate(['/products']);
	}

}
