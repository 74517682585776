import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { PaymentCardComponent } from './payment-card.component';
import { PaymentCardService } from './service/payment-card.service';
import { PaymentCardNumberPipe } from './pipe/payment-card-number/payment-card-number.pipe';
import { ValidThruPipe } from './pipe/valid-thru/valid-thru.pipe';
import { StopFormSubmissionDirective } from "./domain/StopFormSubmission.directive";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [ReactiveFormsModule, CommonModule, MatDialogModule, TranslateModule],
  declarations: [PaymentCardComponent, PaymentCardNumberPipe, ValidThruPipe, StopFormSubmissionDirective],
  providers: [PaymentCardService],
  exports: [PaymentCardComponent, StopFormSubmissionDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NgPaymentCardModule {}
