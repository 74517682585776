import { MatDialog as MatDialog, MatDialogConfig as MatDialogConfig } from "@angular/material/dialog";

import { Injectable } from '@angular/core';
import { default as CARD_TYPES, CardTypesContainer } from '../domain/card-types';
import { Month } from '../domain/month.enum';
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ValidationService } from "@app/core/services/validation.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "@env";
import { Observable, of } from "rxjs";
import { PayWithCreditCardResult } from "@app/shared/CSDAModels/PayWithCreditCardResult";
import { PaymentCardComponent } from "../payment-card.component";
import { Subject } from 'rxjs';
import { LanguageService } from "@app/core/services/language.service";
import { VoidCreditCardTransactionResult } from "@app/shared/CSDAModels/VoidCreditCardTransactionResult";
import { Language } from "@app/core/enums/Language";
import Swal from "sweetalert2";

@Injectable()
export class PaymentCardService {
  private static readonly cardTypes: CardTypesContainer = CARD_TYPES;
  private spinnerSubject = new Subject<boolean>();
  spinnerState = this.spinnerSubject.asObservable();

  showSpinner() {
    this.spinnerSubject.next(true);
  }

  hideSpinner() {
    this.spinnerSubject.next(false);
  }

  constructor(
    public dialog: MatDialog,
    private httpClient: HttpClient,
    private validationService: ValidationService,
    private translateService: TranslateService,
		private languageService: LanguageService,

  ) {}

  /**
   * Return card type based on card number
   */
  public static getCardType(ccNum: string): string | null {
    for (const [key, val] of Array.from(PaymentCardService.cardTypes.entries())) {
      if (
        ccNum
          .split(new RegExp('[ \\-]'))
          .join('')
          .match(val)
      ) {
        return key;
      }
    }
    return null;
  }

  /**
   * Return months in numerical format
   */
  public static getMonths(): Array<Month> {
    const months: Array<Month> = [];
    for (const key of Object.keys(Month)) {
      months.push(Month[key]);
    }
    return months;
  }

  /**
   * Return years based on current year
   */
  public static getYears(): Array<number> {
    const years: Array<number> = [];
    const year = new Date().getFullYear();
    for (let i = -2; i < 5; i++) {
      years.push(year + i);
    }
    return years;
  }

	public payWithCreditCard(creditCardDetails: any): Observable<PayWithCreditCardResult> {
    this.showSpinner();
		return this.httpClient.post<PayWithCreditCardResult>(environment.CSDAEndPoint + "/CSDA/PayWithCreditCard", creditCardDetails)
    .pipe(
      map(data => {
          var validation = this.validationService.validate(data);

          if (validation.isValid === false) {
              Swal.fire('', validation.message, "error");
              return null;
          }

          if (validation.message !== "") {
              Swal.fire('', validation.message, "info");
          }

          return data !== undefined ? data : null;
      })
    );
	}

  public voidCreditCardTransaction(txnId: string): Observable<VoidCreditCardTransactionResult> {
    this.showSpinner();
		var Detail = {
			TxnId: txnId,
			Language: this.languageService.GetCurrentLanguage()
		}

		return this.httpClient.post<VoidCreditCardTransactionResult>(environment.CSDAEndPoint + "/CSDA/VoidCreditCardTransaction", Detail)
    .pipe(
      map(data => {
          var validation = this.validationService.validate(data);
          this.hideSpinner();

          if (validation.isValid === false) {
              Swal.fire('', validation.message, "error");
              return null;
          }

          if (validation.message !== "") {
              Swal.fire('', validation.message, "info");
          }

          return data !== undefined ? data : null;
      })
    );
	}

  executePayment(price: number, language: Language): Observable<PayWithCreditCardResult> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '600px';

    return this.dialog.open(PaymentCardComponent, dialogConfig).afterClosed().pipe(
      switchMap(dialogResult => {
        if (dialogResult !== false) {
          const creditCardDetail = {
            CardNumber: dialogResult.cardNumber,
            CardHolder: dialogResult.cardHolder,
            CardExpirationMonth: dialogResult.expirationMonth,
            CardExpirationYear: dialogResult.expirationYear,
            CardCVV: dialogResult.cvv,
            Price: price,
            Language: language
          };
          this.showSpinner();
          return this.payWithCreditCard(creditCardDetail).pipe(
            map(result => {
              this.hideSpinner();
              return result;
            }),
            catchError(error => {
              this.hideSpinner();
              Swal.fire({
                title: '',
                text: this.translateService.instant("app.payment.creditCardProblemWithPayment"),
                icon: 'info',
                heightAuto: false
              });
              return of({
                PaymentSuccess: false,
                PaymentTxnId: null,
                PaymentMessage: error.message
              });
            })
          );
        } else {
          this.hideSpinner();
          return of({
            PaymentSuccess: false,
            PaymentTxnId: null,
            PaymentMessage: this.translateService.instant("app.payment.paymentCancelByTheUser")
          });
        }
      })
    );
  }
}