import { Component, OnInit } from '@angular/core';
import { HeaderService } from "@app/core/services/header.service";
import { environment } from "@env";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { saveAs } from 'file-saver';
import Swal from "sweetalert2";
import { ImportService } from "./import.service";

@Component({
    templateUrl: './import.component.html',
    styleUrls: ['./import.component.scss'],
    standalone: false
})

export class ImportComponent implements OnInit {
  isProcessing: boolean = false;

  constructor(
		private translateService: TranslateService,
		private headerService: HeaderService,
    private importService: ImportService
  ) { }

  ngOnInit(): void {
    //-------------------
    // Set the page title
    //-------------------
    this.headerService.setTitle(this.translateService.instant("app.importOrders.title"));

    //---------------------------------------------------------------------------------
    // Subscribe to the translate service to update the title when the language changes
    //---------------------------------------------------------------------------------
    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.headerService.setTitle(this.translateService.instant("app.importOrders.title"));
    });
  }
  
  getTemplate() {
    this.isProcessing = true;
    this.importService.getTemplate().subscribe(
      (data) => {
        this.isProcessing = false;
        saveAs(data, "Orders.xlsx");
      }
      ,err => {
        this.isProcessing = false;
        Swal.fire("", err.message, "error");
      }
    );    
  }
}
