//#region Imports & Component definition
import { MatCheckboxChange as MatCheckboxChange } from "@angular/material/checkbox";
import { MatCardModule as MatCardModule } from "@angular/material/card";
import { MAT_DATE_FORMATS } from '@angular/material/core';

import { Component, Input, OnInit, ElementRef, EventEmitter, NgZone, Output, ViewChild, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { PackageType } from "@app/core/enums/PackageType";
import { UnitOfMeasure } from "@app/core/enums/unit-of-measure";
import { CustomDictionariesService } from "@app/core/services/custom-dictionaries.service";
import { QuoteDetail } from "@app/shared/CSDAModels/QuoteDetail";
import { Countries } from "@app/shared/CSDAModels/Countries";
import { Provinces } from "@app/shared/CSDAModels/Provinces";
import { spGetAddressListForDivisionResult } from "@app/shared/CSDAModels/spGetAddressListForDivisionResult";
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from "rxjs/operators";
import { CreateNewShipmentService } from "../../services/create-new-shipment.service";
import { GetCustomPackagesDetail } from "@app/shared/CSDAModels/GetCustomPackagesDetail";
import { Language } from "@app/core/enums/Language";
import { Packages } from "@app/shared/CSDAModels/Packages";
import { AddressBookService } from "../../../address-book/services/address-book.service";
import { TranslateService } from "@ngx-translate/core";
import { AddModifyAddressDetail } from "@app/shared/CSDAModels/AddModifyAddressDetail";
import { HttpClient } from "@angular/common/http";
import { ValidationService } from "@app/core/services/validation.service";
import { ProformatService } from "@app/shared/components/proformat/proformat/proformat.service";

import PlaceResult = google.maps.places.PlaceResult;
import AutocompleteOptions = google.maps.places.AutocompleteOptions;

import Swal from "sweetalert2";
import { AddressContainer } from "@app/shared/CSDAModels/AddressContainer";
import { StorageService } from "@app/core/services/storage.service";
import { Products } from "@app/shared/CSDAModels/Products";
import { TypeOfCurrency } from "@app/core/enums/type-of-currency";
import { INVOICE_TYPE } from "@app/core/enums/INVOICE_TYPE";
import { DOCUMENT_TYPE } from "@app/core/enums/DOCUMENT_TYPE";
import { AddModifyAddressResult } from "@app/shared/CSDAModels/AddModifyAddressResult";
import { OrderResult } from "@app/shared/CSDAModels/OrderResult";
import { ReasonForExport } from "@app/core/enums/ReasonForExport";
import { ShipmentAddressModel } from "@app/shared/CSDAModels/ShipmentAddressModel";
import { ShipmentDutiableRow } from "@app/shared/CSDAModels/ShipmentDutiableRow";
import { Carriers } from "@app/core/enums/Carriers";
import { PaperLessCountries } from "@app/shared/CSDAModels/PaperLessCountries";
import { UtilsService } from "@app/core/services/utils.service";

interface changeCountryCallback { (form: FormGroup): void; }

export enum Appearance {
  STANDARD = 'standard',
  FILL = 'fill',
  OUTLINE = 'outline',
};

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
    //encapsulation: ViewEncapsulation.None,
    selector: 'app-step1-shipment-form',
    templateUrl: './step1-shipment-form.component.html',
    styleUrls: ['./step1-shipment-form.component.scss'],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
    ],
    standalone: false
})
//#endregion

export class Step1ShipmentFormComponent implements OnInit {
  //#region Inputs, Outputs and ViewChild
  //private renderer: Renderer2;
  @Input() public parentForm: FormGroup;
  @Input() public order: QuoteDetail;
  @Input() appearance: string | Appearance = Appearance.OUTLINE;
  @Input() requiredFileType:string;
  @Output() onAutocompleteSelected: EventEmitter<PlaceResult> = new EventEmitter<PlaceResult>();
  @Input() shipperSearchAddress: PlaceResult | string;
  @Output() onChange: EventEmitter<PlaceResult | string | null> = new EventEmitter<PlaceResult | string | null>();
  @ViewChild("shipperSearchAddress") shipperSearchAddressElement: ElementRef;
  @ViewChild("consigneeSearchAddress") consigneeSearchAddressElement: ElementRef;
  @ViewChild("iorSearchAddress") iorSearchAddressElement: ElementRef;
  @ViewChild("shipperUnitElement") shipperUnitElement: ElementRef;
  @ViewChild("consigneeUnitElement") consigneeUnitElement: ElementRef;
  @ViewChild("iorUnitElement") iorUnitElement: ElementRef;
  @ViewChild('ins') insElement: ElementRef;
  @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;

  //#endregion
  //#region Properties
  audio = new Audio();
  isExpanded = false;
  isQuickQuote = sessionStorage.getItem("quoteType") == "51";
  uploadDocumentIndex = -1;
  isProcessing = false;
  disableShipperSaveAddress = false;
  disableConsigneeSaveAddress = false;
  disableIorSaveAddress = false;
  goToStepTwo: boolean = false;
  globalCurrency: TypeOfCurrency;
  isGuest: boolean = false;

  phoneMaskOrigin: string = "(000) 000-0000";
  phoneMaskDesctination: string = "(000) 000-0000";
  phoneMaskIOR: string = "(000) 000-0000";

  /** Variables for the date picker */
  minDate: Date = new Date(new Date().getTime());
  maxDate: Date = new Date(new Date().getTime());
  
  //--------------------------
  // Flags
  //--------------------------
  productFormVisible: boolean = false;
  documentOnlyChecked: boolean = false;
  insuranceRequiredSelected: boolean = false;
  metricMeasureDisplayed: boolean = true;
  
  //-------------------------------------------
  // Mapping to display the combo box selection
  //-------------------------------------------
  currency = this.customDictionaries.currency;
  exportReason = this.customDictionaries.exportReason;
  debitor = this.customDictionaries.debitor;
  yesNo = this.customDictionaries.yesNo;
  packageTypes = this.customDictionaries.packageTypes;
  measures = this.customDictionaries.measures;
  invoiceTypes = this.customDictionaries.invoiceTypes;
  documentTypes = this.customDictionaries.documentTypes;
  vehicleTypes = this.customDictionaries.vehicleTypes;

  //--------------------------------------------------------------------------------------------------
  // The arrays of addresses filtered based on the auto complete search box
  // as well as the list of addresses, countries and states which were loaded when the user logged in.
  // See authentication.service.ts in app/core/services
  //--------------------------------------------------------------------------------------------------
  originAddresses: Observable<any>
  destinationAddresses: Observable<any>
  iorAddresses: Observable<any>
  addressesList: spGetAddressListForDivisionResult[];
  countriesList: Countries[];
  statesProvincesList: Provinces[];
  shipperFilteredStateProvinces: Provinces[];
  consigneeFilteredStateProvinces: Provinces[];
  iorFilteredStateProvinces: Provinces[];
  autoCompleteOptions: AutocompleteOptions = {};
  customPackages: Packages[];
  customProducts: Products[];
  isPaperLess: boolean;
  isPaperlessAvailable: boolean;

  isInvoiceTypeSystem: boolean;
  fileName = '';
  uploadProgress:number;
  uploadSub: Subscription;
  forceReference: boolean;
  isDryIce: boolean = false;

  //#endregion
  //#region Constructor
  /** Constructor of the  Step1ShipmentFormComponent class */
  constructor(
    private utilsService: UtilsService,
    private customDictionaries: CustomDictionariesService,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef,
    private httpClient: HttpClient,
    private validationService: ValidationService,
    private createNewShipmentService: CreateNewShipmentService,
		private proformatService: ProformatService,
    private addressBookService: AddressBookService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private http: HttpClient,
    //private el: ElementRef,
    //rendererFactory: RendererFactory2
    ) {
      //this.renderer = rendererFactory.createRenderer(null, null);
  }
  //#endregion
  //#region OnInit
  /** Enable display and set default values of the forms */
  ngAfterViewInit() {
    setTimeout(() => {
      this.isGuest = sessionStorage.getItem("isGuest") !== null;
      this.audio.src = "assets/audio/click-sound.mp3";
      this.audio.load();

      this.isDryIce = this.order.IsDryIce;
      
      //--------------------------------------------------------------------------------------------------
      // keep the lists addresses, countries and states/provinces from the user session which was obtained
      // when the user logged in.  See in app/core/services/authentication.service.ts
      //--------------------------------------------------------------------------------------------------
      this.addressesList = JSON.parse(sessionStorage.getItem("AddressesList")) as spGetAddressListForDivisionResult[];
      this.countriesList = JSON.parse(sessionStorage.getItem("Countries")) as Countries[];
      this.statesProvincesList = JSON.parse(sessionStorage.getItem("Provinces")) as Provinces[];

      this.changeCountryEvent(true);
      this.changeCountryEvent(false);

      //this.onGetExistingPackages();
      
      // Set the visibility of the insurance value and currency to Visible if true
      this.insuranceRequiredSelected = this.options().controls['IsInsurance'].value;

      // Set the variable to know if we have PaperLess in order for the other controls to show
      // as well as knowing if the invoice type is SYSTEM
      this.isPaperLess = this.dutiable().controls["PaperLess"].value;
      this.isInvoiceTypeSystem = this.dutiable().controls["InvoiceType"].value === 0;

      if(this.isInvoiceTypeSystem) {
        for(var element=0;element<this.documents().length;element++) {
          var document = this.documents().at(element) as FormGroup;
          document.controls["documentType"].setValue(1)
        };
      }
      // Set the product of the custom section 
      this.documentOnlyChecked = this.options().controls['IsDocumentOnly'].value;
      this.products().enable();

      //----------------------------------------------------------------------
      // Sylvie en teams : If it's document only we remove the product section
      // in order to avoid the validation
      //----------------------------------------------------------------------
      if(this.documentOnlyChecked === true){
        this.products().disable();
        this.products().clear();
        this.products().push(this.newProduct( '', '', '', '', null, null))
      }

      /** Set the unit measure value by default */
      if(this.options().controls['UnitOfMeasure'].value === UnitOfMeasure.Metric){
        this.metricMeasureDisplayed = true;
      }
      else{
        this.metricMeasureDisplayed = false;
      }

      //----------------------------------------------------------------------------------------
      // Creating an event on valueChanges for the origin and destination addresses company name
      // in order to populate the list of addresses for the auto complete box
      // One of the example on the web was incorrect due to switchMap.  Sylvie helped by changing
      // for map and the problem was solved.
      //----------------------------------------------------------------------------------------
      this.originAddresses = this.shipper().controls['companyName'].valueChanges
      .pipe(
        startWith(''),
        map(val => {
          var returnValue = null;

          if(val != null)
            if(val.length != null)
            returnValue = val.length >= 1 ? this.filter(val) : null;
          
          return returnValue;        
        })
      )

      this.destinationAddresses = this.consignee().controls['companyName'].valueChanges
      .pipe(
        startWith(''),
        map(val => {
          var returnValue = null;

          if(val != null)
            if(val.length != null)
            returnValue = val.length >= 1 ? this.filter(val) : null;
          
          return returnValue;
        })
      )

      this.iorAddresses = this.ior().controls['companyName'].valueChanges
      .pipe(
        startWith(''),
        map(val => {
          var returnValue = null;

          if(val != null)
            if(val.length != null)
            returnValue = val.length >= 1 ? this.filter(val) : null;
          
          return returnValue;        
        })
      )

      this.forceAndLockQuantityForDryIce(this.isDryIce && !this.documentOnlyChecked);
      this.removeDryIceWeightValidation(!(this.isDryIce ?? false));
      this.cd.detectChanges();
    }, 0);
  }
  ngOnInit(): void {
    this.maxDate.setFullYear(2050);  // Set the year to 2050
    this.maxDate.setMonth(11);  // Set to December
    this.maxDate.setDate(31);
  
    this.isGuest = sessionStorage.getItem("isGuest") !== null;
    this.goToStepTwo = this.storageService.getGoToStepTwo();
    this.customPackages = JSON.parse(sessionStorage.getItem("customPackages")) as Packages[];
    this.customProducts = JSON.parse(sessionStorage.getItem("customProducts")) as Products[];

    // Set the Max Date for the delivery
    this.maxDate.setDate(this.maxDate.getDate()+10)

    var getCustomPackagesDetail: GetCustomPackagesDetail = {
        Language: Language.English,
        IsDevelopmentEnvironment: false
    }

    //----------------------------------------------------------------
    // Disable the save address buttons when we come from a duplicate.
    //----------------------------------------------------------------
    if (this.storageService.getShipmentOrigin() === '/active-orders' || this.storageService.getOrderId() != "null") {
      this.disableShipperSaveAddress = true;
      this.disableConsigneeSaveAddress = true;
    }

    if(this.shipper().controls['companyName'].value)
      this.disableShipperSaveAddress = true;

    if(this.consignee().controls['companyName'].value)
      this.disableConsigneeSaveAddress = true;
  }
  //#endregion
  //#region Address AutoComplete
  //---------------------------------------------------------------------------------------
  // Gets the list of addresses based on the requested search term
  // Thanx to Sylvie for the typeof.  When we filter the first
  // time it's the value of text we enter in the control but
  // when we click an address in the list we are with the unique number of the complex type 
  // because the value changed hence we check what type of val we have
  // and only perform a filter if the value is string
  //---------------------------------------------------------------------------------------
  filter(val: string): spGetAddressListForDivisionResult[] {
    if(typeof val === "string")
      return this.addressesList.filter(f => f.CompanyName.toLowerCase().includes(val.toLowerCase()) || f.PersonName.toLowerCase().includes(val.toLowerCase()))
  }

  //-------------------------------------------------------------------------
  // When the user select an address in the auto complete box, this is called
  // in order to populate all the address fields
  //-------------------------------------------------------------------------
  SelectedAddress(IsOrigin: boolean | null, option: any) {
    if(option != null)
    {
      const Element: spGetAddressListForDivisionResult = this.addressesList.find(f => f.Row == option.option.value);

      //--------------------------------------------------------------
      // Fill all fields from the appropriate section
      // The condition below is the flag to get the right form to fill
      // either it'S the shipper or the consignee
      //--------------------------------------------------------------
      const AddressSection: FormGroup = IsOrigin == null ? this.ior() : (IsOrigin ? this.shipper() : this.consignee());
      
      //-------------------------------------------------------------------------------
      // We don't set the Company Name here because selecting in the list automatically
      // change the value of the control
      //-------------------------------------------------------------------------------
      AddressSection.controls["personName"].setValue(Element.PersonName);
      AddressSection.controls["phone"].setValue(Element.Phone);
      AddressSection.controls["extension"].setValue(Element.PhoneExtension);
      AddressSection.controls["email"].setValue(Element.Email);
      AddressSection.controls["isResidential"].setValue(Element.IsResidential);
      AddressSection.controls["countryID"].setValue(Element.CountryID);
      AddressSection.controls["countryName"].setValue(Element.CountryName);
      AddressSection.controls["zipCode"].setValue(Element.ZipCode);
      AddressSection.controls["stateID"].setValue(Element.StateID);
      AddressSection.controls["stateName"].setValue(Element.StateName);
      AddressSection.controls["cityName"].setValue(Element.City);
      AddressSection.controls["unit"].setValue(Element.Unit);
      AddressSection.controls["streetName"].setValue(Element.StreetName);
      AddressSection.controls["specialInstruction"].setValue(Element.SpecialInstructions);
      AddressSection.controls["taxID"].setValue(Element.TaxID);

      if(IsOrigin == null) {
        this.changeIorCountryEvent();
        this.disableIorSaveAddress = true;
      }
      else {
        this.changeCountryEvent(IsOrigin);

        if(IsOrigin)
          this.disableShipperSaveAddress = true;
        else
          this.disableConsigneeSaveAddress = true;
      }
    }
  }

  //----------------------------------------------------------------------------------------
  // Necessary to use the this which access the method that gets the selected address detail
  // This is the one called from the HTML
  //----------------------------------------------------------------------------------------
  public GetDisplay(IsOrigin: boolean) {
    return (val: any) => this.Display(val, IsOrigin);
  }

  //---------------------------------------------------------------------------
  // When the user do a choice in the list, the name of the company will be set
  //---------------------------------------------------------------------------
  private Display(option: spGetAddressListForDivisionResult, IsOrigin: boolean): string {
    //--------------------------------------------------------------------------------------------------
    // Same case as for the filter,  here the first time the option is a string
    // but upon selecting an element of the list the value changes for the unique id of the complex type
    // because the html [value] element is option.Row
    //
    // Without knowing if there's a better solution this is considered a workaround
    //--------------------------------------------------------------------------------------------------
    if(typeof option === "number")
    {
      const Element: spGetAddressListForDivisionResult = this.addressesList.find(f => f.Row == option);
      return Element.CompanyName;
    }
    
    if(option)
    {
      if(typeof option === "string")
        return option;
      else
        return option.CompanyName;
    }
    else
      return "";
  }
  //#endregion
  //#region Countries & States / Provinces handling
  changeCountryEvent(IsOrigin: boolean, CallBack: changeCountryCallback = null): void {
    const Section: FormGroup = IsOrigin ? this.shipper() : this.consignee();

    //----------------------------------- 
    // Gets our selected country instance
    //----------------------------------- 
    const selectedCountry = this.countriesList.find(c => c.CON_ID == Section.controls['countryID'].value);

    if(this.isQuickQuote)
    {
      // Task #5057: Added the condition
      if(selectedCountry.CON_ZipEmpty) {
        Section.controls["zipCode"].setValue(null);
        Section.controls["zipCode"].disable();
      }
      else {
        Section.controls["zipCode"].enable();
      }

      return;
    }

    this.isPaperlessAvailable = this.GetPaperlessAvailability();

    // Suggested by the AI but it crashes on this.renderer saying "Cannot read properties of null (reading 'setAttribute')'""
    // Instead I use onfocus on the inputs in the HTML.  I commented the import and the variable renderer and the codes in the constructor
    // const inputElement = this.el.nativeElement.querySelector(IsOrigin ? '#shipperSearchAddress' : '#consigneeSearchAddress');
    // this.renderer.setAttribute(inputElement, 'autocomplete', 'shipperGoogle');

    var htmlInputElement: HTMLInputElement = null;

    if((IsOrigin && this.shipperSearchAddressElement) || (!IsOrigin && this.consigneeSearchAddressElement))
      htmlInputElement = (IsOrigin ? this.shipperSearchAddressElement : this.consigneeSearchAddressElement).nativeElement;
    
    //------------------------------------------
    // Set the visibility of the customs section
    //------------------------------------------

    //----------------------------------------------------------------------------------------
    //NOTE: Is this true if for instance the shipper is in France and the consigne in Thailand
    //      Maybe the Customs section needs to be available ?
    //----------------------------------------------------------------------------------------
    this.productFormVisible = this.shipper().controls['countryID'].value !== this.consignee().controls['countryID'].value;

    if(selectedCountry) {
      
      if(selectedCountry.CON_Code == "CA" || selectedCountry.CON_Code == "US" ) {
        if(IsOrigin)
          this.phoneMaskOrigin = "(000) 000-0000";
        else
          this.phoneMaskDesctination = "(000) 000-0000";
      } else {
        if(IsOrigin)
          this.phoneMaskOrigin = "000000000000000";
        else
          this.phoneMaskDesctination = "000000000000000";
      }

      const options: AutocompleteOptions = {
        types: ['address'],
        componentRestrictions: {country: selectedCountry.CON_Code},
      };
      
      this.autoCompleteOptions = Object.assign(this.autoCompleteOptions, options);

      if(IsOrigin)
        this.initGoogleMapsAutocomplete(true);
      else
        this.initGoogleMapsAutocomplete(false);

      //---------------------------------------------------------
      // By default we enable the zip code and state controls
      // Bellow the code will check if they needs to be disabled
      //---------------------------------------------------------
      Section.controls["zipCode"].enable();
      Section.controls["stateID"].enable();

      if(htmlInputElement)
        htmlInputElement.disabled = false;

      //---------------------------------------------------------------------------------------------
      // Our Countries table contains a flag to tell us if we need to enter a zip code and a province
      // In case the CON_ZipEmpty is true we disable the zip code and state controls
      //---------------------------------------------------------------------------------------------
      if(selectedCountry.CON_ZipEmpty) {
        Section.controls["zipCode"].setValue(null);
        Section.controls["stateID"].setValue(null);
        Section.controls["zipCode"].disable();
        Section.controls["stateID"].disable();
      }
      else {
        if(selectedCountry.CON_StateMandatory) {
          //---------------------------------------------------------
          // Get the states / provinces based on the selected country
          //---------------------------------------------------------
          if(IsOrigin) {
            this.shipperFilteredStateProvinces = this.statesProvincesList.filter(f => f.PRV_CON_REF === Section.controls['countryID'].value);

            //----------------------------------------------------------------
            // If we don't have any states listed we disable the state control
            //----------------------------------------------------------------
            if(this.shipperFilteredStateProvinces.length == 0)
              Section.controls["stateID"].disable();
          }
          else {
            this.consigneeFilteredStateProvinces = this.statesProvincesList.filter(f => f.PRV_CON_REF === Section.controls['countryID'].value);

            //----------------------------------------------------------------
            // If we don't have any states listed we disable the state control
            //----------------------------------------------------------------
            if(this.consigneeFilteredStateProvinces.length == 0)
              Section.controls["stateID"].disable();
          }
        }
        else {
          Section.controls["stateID"].setValue(null);
          Section.controls["stateID"].disable();
        }
      }
    }
    else {
      if(htmlInputElement)
        htmlInputElement.disabled = true;
    }

    //-----------------------------------
    // Set the visibility of the products
    //-----------------------------------
    if (this.productFormVisible === true) {
      this.products().enable();
      this.onGetExistingProducts();
    }
    else {
      this.products().clear();
      this.products().disable();
    }

    if(CallBack)
      CallBack.call(Section);
  }
  changeIorCountryEvent(CallBack: changeCountryCallback = null): void {

    if(this.isQuickQuote)
      return;

    const Section: FormGroup = this.ior();
    
    // Suggested by the AI but it crashes on this.renderer saying "Cannot read properties of null (reading 'setAttribute')'""
    // Instead I use onfocus on the inputs in the HTML.  I commented the import and the variable renderer and the codes in the constructor
    // const inputElement = this.el.nativeElement.querySelector(IsOrigin ? '#shipperSearchAddress' : '#consigneeSearchAddress');
    // this.renderer.setAttribute(inputElement, 'autocomplete', 'shipperGoogle');

    var htmlInputElement: HTMLInputElement = null;

    if(this.iorSearchAddressElement)
      htmlInputElement = this.iorSearchAddressElement.nativeElement;
    
    //----------------------------------- 
    // Gets our selected country instance
    //----------------------------------- 
    const selectedCountry = this.countriesList.find(c => c.CON_ID == Section.controls['countryID'].value);

    if(selectedCountry) {
      
      if(selectedCountry.CON_Code == "CA" || selectedCountry.CON_Code == "US" ) {
        this.phoneMaskIOR = "(000) 000-0000";
      } else {
        this.phoneMaskIOR = "000000000000000";
      }

      const options: AutocompleteOptions = {
        types: ['address'],
        componentRestrictions: {country: selectedCountry.CON_Code},
      };
      
      this.autoCompleteOptions = Object.assign(this.autoCompleteOptions, options);
      this.initGoogleMapsAutocomplete(null);

      //---------------------------------------------------------
      // By default we enable the zip code and state controls
      // Bellow the code will check if they needs to be disabled
      //---------------------------------------------------------
      Section.controls["zipCode"].enable();
      Section.controls["stateID"].enable();

      if(htmlInputElement)
        htmlInputElement.disabled = false;

      //---------------------------------------------------------------------------------------------
      // Our Countries table contains a flag to tell us if we need to enter a zip code and a province
      // In case the CON_ZipEmpty is true we disable the zip code and state controls
      //---------------------------------------------------------------------------------------------
      if(selectedCountry.CON_ZipEmpty) {
        Section.controls["zipCode"].setValue(null);
        Section.controls["stateID"].setValue(null);
        Section.controls["zipCode"].disable();
        Section.controls["stateID"].disable();
      }
      else {
        if(selectedCountry.CON_StateMandatory) {
          //---------------------------------------------------------
          // Get the states / provinces based on the selected country
          //---------------------------------------------------------
          this.iorFilteredStateProvinces = this.statesProvincesList.filter(f => f.PRV_CON_REF === Section.controls['countryID'].value);

          //----------------------------------------------------------------
          // If we don't have any states listed we disable the state control
          //----------------------------------------------------------------
          if(this.iorFilteredStateProvinces.length == 0)
            Section.controls["stateID"].disable();
        }
        else {
          Section.controls["stateID"].setValue(null);
          Section.controls["stateID"].disable();
        }
      }
    }
    else {
      if(htmlInputElement)
        htmlInputElement.disabled = true;
    }

    if(CallBack)
      CallBack.call(Section);
  }
  //#endregion
  //#region Methods to get access to the form groups
  toggleCollapse() {
    this.isExpanded = !this.isExpanded;
  }
  shipper(): FormGroup {
    return this.parentForm.get("shipperForm") as FormGroup
  }
  consignee(): FormGroup {
    return this.parentForm.get("consigneeForm") as FormGroup
  }
  ior(): FormGroup {
    return this.parentForm.get("iorForm") as FormGroup
  }
  options(): FormGroup {
    return this.parentForm.get("optionForm") as FormGroup;
  }
  productForm(): FormArray {
    return this.parentForm.get("productForm") as FormArray;
  }
  dutiable(): FormGroup {
    return this.parentForm.get("dutiableForm") as FormGroup;
  }
  packages(): FormArray {
    return this.parentForm.get("packageForm") as FormArray;
  }
  //#endregion
  //#region Handling products, Packages
  /* Get the parent products form array */
  products(): FormArray {
    return this.parentForm.get("productForm") as FormArray;
  }
  /* Add a new product to the products form array */
  public onAddProduct(): void {
    this.products().push(this.newProduct());
  }
  /* Get the dutiable products list on order */
  public onGetExistingProducts(): void {

    this.products().clear();

    if (this.order.Dutiable !== undefined && this.order.Dutiable !== null) {
      for (var i = 0; i < this.order.Dutiable.ShipmentDutiableRows.length; i++) {
        this.products().push(
          this.newProduct(
            this.order.Dutiable.ShipmentDutiableRows[i].ItemName,
            this.order.Dutiable.ShipmentDutiableRows[i].ItemDescription,
            this.order.Dutiable.ShipmentDutiableRows[i].HSCode,
            this.order.Dutiable.ShipmentDutiableRows[i].CountryOfOrigin,
            this.order.Dutiable.ShipmentDutiableRows[i].Quantity,
            this.order.Dutiable.ShipmentDutiableRows[i].Weight,
            this.order.Dutiable.ShipmentDutiableRows[i].DeclaredUnitValue
          ));
      }
    }
  }
  /* Add a new product form group in the products form array */
  newProduct(name = '', desc = '', hs = '', count = '', qt = null, weight = null, price = null): FormGroup {

    return this.fb.group({
      name: [name],
      description: [desc, [productValidator(this.productFormVisible, this.documentOnlyChecked)]],
      hsCode: [hs],
      countryOfOrigin: [count, [productValidator(this.productFormVisible, this.documentOnlyChecked)]],
      qty: [qt, [productValidator(this.productFormVisible, this.documentOnlyChecked)]],
      unitPrice: [price, [productValidator(this.productFormVisible, this.documentOnlyChecked)]]
      ,weight: [weight, [productValidator(this.productFormVisible, this.documentOnlyChecked)]]
    })
  }

  //---------------------------------------------------------
  // Remove a product form group from the products form array
  //---------------------------------------------------------
  onRemoveProduct(empIndex: number): void {
    this.products().removeAt(empIndex);
  }
  
  /* Get the packages list on order */
  public onGetExistingPackages(): void {
    this.packages().clear();
    if (this.order.Packages !== undefined && this.order.Packages !== null) {
      for (var i = 0; i < this.order.Packages.length; i++) {
        this.packages().push(
          this.newPackage(0,
            this.order.Packages[i].PackageType,
            this.order.Packages[i].Quantity,
            this.order.Packages[i].Weight,
            this.order.Packages[i].Depth,
            this.order.Packages[i].Width,
            this.order.Packages[i].Height,
            this.order.Packages[i].DryIceWeight
          ));
      }
    }
  }
  /* Add a new package form group in the packages form array */
  public onAddPackage(): void {
    if (this.documentOnlyChecked === false) {
      this.packages().push(this.newPackage(0, PackageType.CUSTOM, null, null, null, null, null, null));
    }
    else {
      this.packages().push(this.newPackage(0, PackageType.ENVELOPPE, null, 1, 1, 1, 1, 0));
    }
  }
  /* Create a new package form group */
  newPackage(
    customPackage: number,
    packageType: PackageType,
    quantity: number | null,
    weight: number | null,
    depth: number | null,
    width: number | null,
    height: number | null,
    dryIceWeight: number | null): FormGroup {
    return this.fb.group({
      customPackage: [customPackage],
      packageType: [packageType],
      quantity: [quantity, Validators.required],
      weight: [weight, [packageValidator(this.documentOnlyChecked), Validators.min(0.5)]],
      depth: [depth, [packageValidator(this.documentOnlyChecked), Validators.min(0.5)]],
      width: [width, [packageValidator(this.documentOnlyChecked), Validators.min(0.5)]],
      height: [height, [packageValidator(this.documentOnlyChecked), Validators.min(0.5)]],
      dryIceWeight: [dryIceWeight, [packageValidator(this.documentOnlyChecked), Validators.min(0.5)]]
    })
  }

  /* Remove a package form group from the packages forms array at the passed in index */
  onRemovePackage(empIndex: number): void {
    this.packages().removeAt(empIndex);
  }
  onDuplicatePackage(empIndex: number): void {
    this.packages().push(this.newPackage(
      0, 
      (<FormGroup>this.packages().controls[empIndex]).controls["packageType"].value, 
      (<FormGroup>this.packages().controls[empIndex]).controls["quantity"].value, 
      (<FormGroup>this.packages().controls[empIndex]).controls["weight"].value, 
      (<FormGroup>this.packages().controls[empIndex]).controls["depth"].value, 
      (<FormGroup>this.packages().controls[empIndex]).controls["width"].value, 
      (<FormGroup>this.packages().controls[empIndex]).controls["height"].value,
      (<FormGroup>this.packages().controls[empIndex]).controls["dryIceWeight"].value
    ));
    
    this.forceAndLockQuantityForDryIce(this.isDryIce && !this.documentOnlyChecked);
  }  
  //#endregion
  //#region Handling PaperLess
  //---------------------------------------------
  // Get the parent documents form array
  //---------------------------------------------
  documents(): FormArray {
    return this.parentForm.get("documentForm") as FormArray;
  }

  //----------------------------------------------------------
  // Add a new document form group in the documents form array
  //----------------------------------------------------------
  newDocument(documentType = DOCUMENT_TYPE.OTHER, fileName = "", content = ""): FormGroup {
    return this.fb.group({
      documentType: DOCUMENT_TYPE.OTHER,
      fileName: null,
      content: null
    })
  }

  //--------------------------------------------------------------------------------
  // There's an hidden input for that which we reference using ViewChild and perform
  // a click to request a file from the user's computer
  //--------------------------------------------------------------------------------
  onUploadDocument(empIndex: number): void {
    this.uploadDocumentIndex = empIndex;
    this.fileUpload.nativeElement.dispatchEvent(new MouseEvent('click'));
  }

  //--------------------------------------------------------------------------------------------------------
  // Once a file have been selected from the user this even from the hidden input will be triggered
  // Some code has been taken from here : https://www.bezkoder.com/angular-material-12-image-upload-preview/
  //--------------------------------------------------------------------------------------------------------
  onFileSelected(event: { target: { files: File[]; }; }) {
    const file:File = event.target.files[0];
      
    if (file) {
      var document = this.documents().at(this.uploadDocumentIndex) as FormGroup;
      document.controls["fileName"].setValue(file.name);
      const reader = new FileReader();
      
      //---------------------------------------------------------------------------
      // Here the split gets only the base64 value because the filereader
      // gets the value usable for the web like data:image/png;base64,...
      // Since the carriers wants only the base64 value, we strip the decoration
      // That means we will need to forge it again when trying to view the document
      //---------------------------------------------------------------------------
      reader.onload = (e: any) => {
        document.controls["content"].setValue(e.target.result.split(",")[1]);
      };

      reader.readAsDataURL(file);
    }
  }  

  //------------------------------------------
  // Disable View button if no file is present
  //------------------------------------------
  onDocumentNotUploaded(empIndex: number) : boolean {
    var document = this.documents().at(empIndex) as FormGroup;
    return document.controls["fileName"].value == null;
  }

  //----------------------------------------
  // Preview a document
  //----------------------------------------
  onViewDocument(empIndex: number): void {
    var document = this.documents().at(empIndex) as FormGroup;
    var fileName = document.controls["fileName"].value;
    var content = document.controls["content"].value;

    if(fileName && content) {
      var availableExtensions = "PDF,PNG,JPG,JPEG,BMP,TIFF";
      var extension = fileName.split('.').pop();

      if(availableExtensions.indexOf(extension.toUpperCase()) != -1) {
        var mimeType = "png,jpg,jpeg,bmp".indexOf(extension) != -1 ? "image" : "application"
        var documentToWrite = "";

        //------------------------------------------------------------------------------
        // Here I handle the word documents but if we need more mime type I will have to 
        // implement the json which contains all the possible mime type
        // https://stackoverflow.com/a/50860387/2966506
        //------------------------------------------------------------------------------
        if(extension == "doc")
          extension = "msword";

        if(extension == "docx")
          extension = "vnd.openxmlformats-officedocument.wordprocessingml.document";

        if(mimeType == "image") {
          documentToWrite = 
          '<div style="display:flex;justify-content:center;width:100%;height:100%;">' + 
          '<embed' + (mimeType == "image" ? "" : "width=100% height=100%")
          + ' type="'+ mimeType + '/' + extension + '"'
          + ' src="data:' + mimeType + '/' + extension +';base64,'
          + content
          + '"></embed></div>'
        } else {
          documentToWrite = 
          '<embed width=100% height=100%'
          + ' type="'+ mimeType + '/' + extension + '"'
          + ' src="data:' + mimeType + '/' + extension +';base64,'
          + content
          + '"></embed>'
        }

        window.open("", extension.toUpperCase(), `dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,resizable,width=${screen.width},height=${screen.height}`).document.write(documentToWrite);
      } else {
        Swal.fire({
          icon: "info",
          title: this.translateService.instant("app.misc.previewPLTNotSupported"),
          confirmButtonText: 'OK'
        });        
      }
    }
  }
  
  //----------------------------------------
  // Delete a document
  //----------------------------------------
  onDeleteDocument(empIndex: number): void {
    if(this.documents().length == 1) {
      var document = this.documents().at(empIndex) as FormGroup;
      document.controls["documentType"].setValue(this.isInvoiceTypeSystem ? 1 : 0);
      document.controls["fileName"].setValue(null);
      document.controls["content"].setValue(null);
    }
    else {
      this.documents().removeAt(empIndex);
    }
  }
  
  //----------------------------------------
  // Add a document
  //----------------------------------------
  onAddDocument(empIndex: number): void {
    this.documents().push(this.newDocument());
    this.IsAddUnAvailable(empIndex);
  }
  
  //-----------------------------------------------------------------
  // When the user select YES or NO for PaperLess we keep track of
  // this change in order to signal the UI to show additional parameters
  //-----------------------------------------------------------------
  changePaperLessOption(status: boolean): void {
    this.isPaperLess = status;
    this.isInvoiceTypeSystem = this.dutiable().controls["InvoiceType"].value === 0;
    this.dutiable().controls["InvoiceType"].setValue(0);
    this.documents().clear();
    this.onAddDocument(0);
  }

  //------------------------------------------------------------------
  // when the type of invoice is changed we keep track of this to know
  // if we have to lock all document type control with OTHER
  //------------------------------------------------------------------
  changeInvoiceType(val: INVOICE_TYPE) {
    this.isInvoiceTypeSystem = val === 0;

    if(this.isInvoiceTypeSystem) {
      for(var element=0;element<this.documents().length;element++) {
        this.DisableDocumentType(element);

        for(var element=0;element<this.documents().length;element++) {
          var document = this.documents().at(element) as FormGroup;
          document.controls["documentType"].setValue(1)
        };
      };
    }
  }

  //--------------------------------------------------------------
  // When the document type changes.  If it's a commercial invoice
  // we lock all other controls to OTHER
  //--------------------------------------------------------------
  changeDocumentType(val: DOCUMENT_TYPE, empIndex: number) {
    //TODO: À faire...
  }

  //------------------------------------------------------
  // Used to check if we have to disable the document type
  //------------------------------------------------------
  DisableDocumentType(empIndex: number): boolean {
    var toDisable = false;

    if(this.isInvoiceTypeSystem) {
      toDisable = true;
    }
    else {
      var atLeastOneCommercialInvoice = false;
      var commercialInvoiceIndex = -1;

      for(var element=0;element<this.documents().length;element++) {
          var document = this.documents().at(element) as FormGroup;

          if(document.controls["documentType"].value == 0) {
            atLeastOneCommercialInvoice = true;
            commercialInvoiceIndex = element;
            break;
          }
      };
      
      var document = this.documents().at(empIndex) as FormGroup;

      if(document.controls["documentType"].value == 1 && atLeastOneCommercialInvoice)
        toDisable = true;
    }

    return toDisable;
  }

  //--------------------------------------------------------------------
  // Check if the Add button is unavailable like no document uploaded or 
  // there's already 5 documents
  //--------------------------------------------------------------------
  IsAddUnAvailable(empIndex: number): boolean {
    var document = this.documents().at(empIndex) as FormGroup;
    return document.controls["fileName"].value == null || this.documents().length == (this.isInvoiceTypeSystem ? 4 : 5);
  }
  //-------------------------------------
  // To know if the file name is required
  //-------------------------------------
  isFileNameRequired(empIndex: number) : boolean {
    return (this.isInvoiceTypeSystem && empIndex > 0) || (!this.isInvoiceTypeSystem);
  }
  //#endregion
  //#region Google Places
  //---------------------------------------------------------------------
  // Installation requise pour Google : 
  // 
  // npm install ngx-google-places-autocomplete
  //---------------------------------------------------------------------
  public initGoogleMapsAutocomplete(IsOrigin: boolean | null) {
    const AddressSection: FormGroup = IsOrigin == null ? this.ior() : (IsOrigin ? this.shipper() : this.consignee());
    var htmlInputElement: HTMLInputElement;

    htmlInputElement = (IsOrigin == null ? this.iorSearchAddressElement : (IsOrigin ? this.shipperSearchAddressElement : this.consigneeSearchAddressElement)).nativeElement;
    htmlInputElement.autocomplete = "off";
    htmlInputElement.value = "";

    //------------------------------------------------------------------------------------------------------------------------------------------------------
    // Task #5091: Because the addlister was making the new google.maps.places.Autocomplete a new instance on the same input twice or more
    //             in another wored, google.maps.places had a lister for the previous country.  Can't explain why certain country it wasn't doing it.  Maybe
    //             the list was shorter than the front one so we didn't see it behind ?
    //             I was able to reproduce the issue and now this the following line, I can't reproduce it.
    //------------------------------------------------------------------------------------------------------------------------------------------------------
    google.maps.event.clearInstanceListeners(htmlInputElement);

    const autocomplete = new google.maps.places.Autocomplete(htmlInputElement, this.autoCompleteOptions);

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: PlaceResult = autocomplete.getPlace();
        
        var streetNo: any;
        var streetName: any;
        var zipCode: any;
        var municipality: any;
        var cityName: any;
        var countryCode: any;
        var countryName: any;
        var stateCode: any;
        var stateName: any;

        //------------------------------------------
        // Get data from google
        //------------------------------------------
        if(place.address_components) {
          place.address_components.forEach(value => {
            if (value.types.indexOf('street_number') > -1) {
              streetNo = value.short_name;
            }

            if (value.types.indexOf('route') > -1) {
              streetName = value.long_name;
            }

            if (value.types.indexOf('postal_code') > -1) {
              zipCode = value.long_name;
            }
            
            if (value.types.indexOf('sublocality') > -1) {
              municipality = value.long_name;
            }

            //-------------------------------------------------------------------------------
            // Selon mes recherches, la documentation de Google ne
            // m'aide pas à comprendre la différence.  Autant Etobicoke que Ville-Marie entre
            // dans sublocality et Toronto ainsi que Montréal se trouve
            // dans locality.  On a mis du code pour avoir le sublocality s'il est fourni
            //
            // J'ai ajouté la vérification de la municipality dans le formatted_address
            // avant de le prendre pour s'assurer qu'on prenne le choix qu'on fait dans la liste
            // Expliqué par Patrick et Alain en meeting.
            //-------------------------------------------------------------------------------
            if (value.types.indexOf('locality') > -1) {
              if(municipality && place.formatted_address.indexOf(municipality) != -1)
                cityName = municipality;
              else
                cityName = value.long_name;
            }

            if (value.types.indexOf('administrative_area_level_1') > -1) {
              stateCode = value.short_name;
              stateName = value.long_name;
            }

            if (value.types.indexOf('country') > -1) {
              countryCode = value.short_name;
              countryName = value.long_name;
            }
          });

          //------------------------------------
          // Lookups for country id and state id
          //------------------------------------
          const countryID = this.countriesList.find(a => a.CON_Code == countryCode).CON_ID;

          //----------------------------------------------------------
          // Assign the values from Google to the controls on the form
          //----------------------------------------------------------
          AddressSection.controls["streetName"].setValue(streetNo + " " + streetName);
          AddressSection.controls["zipCode"].setValue(zipCode);

          AddressSection.controls["cityName"].setValue(cityName);
          AddressSection.controls["countryID"].setValue(countryID);
          
          //---------------------------------------------------------------
          // We have to set the state AFTER the change country is completed
          // in order to have the list of state ready for the state change
          //---------------------------------------------------------------
          if(IsOrigin == null)
            this.changeIorCountryEvent();
          else
            this.changeCountryEvent(IsOrigin);

          var stateID = -1;

          if(IsOrigin == null) {
            if(this.iorFilteredStateProvinces) {
              var Provinces = this.iorFilteredStateProvinces.find(a => a.PRV_Code == stateCode);

              if(Provinces)
                stateID = Provinces.PRV_ID;
              else {
                var Provinces = this.iorFilteredStateProvinces.find(a => a.PRV_Name == stateName);

                if(Provinces)
                  stateID = Provinces.PRV_ID;
              }

            }
        }
          else {
            if(IsOrigin) {
              if(this.shipperFilteredStateProvinces) {
                var Provinces = this.shipperFilteredStateProvinces.find(a => a.PRV_Code == stateCode);

                if(Provinces)
                  stateID = Provinces.PRV_ID;
                else {
                  var Provinces = this.shipperFilteredStateProvinces.find(a => a.PRV_Name == stateName);

                  if(Provinces)
                    stateID = Provinces.PRV_ID;
                }
  
              }
            }
            else {
              if(this.consigneeFilteredStateProvinces) {
                var Provinces = this.consigneeFilteredStateProvinces.find(a => a.PRV_Code == stateCode);

                if(Provinces)
                  stateID = Provinces.PRV_ID;
                else {
                  var Provinces = this.consigneeFilteredStateProvinces.find(a => a.PRV_Name == stateName);

                  if(Provinces)
                    stateID = Provinces.PRV_ID;
                }
              }
            }
          }

          AddressSection.controls["stateID"].setValue(stateID);
          AddressSection.controls["cityName"].setValue(cityName);

          if(IsOrigin == null) {
            this.iorSearchAddressElement.nativeElement.value = "";
            this.iorUnitElement.nativeElement.focus();
          }
          else {
            if(IsOrigin) {
              this.shipperSearchAddressElement.nativeElement.value = "";
              this.shipperUnitElement.nativeElement.focus();
            }
            else {
              this.consigneeSearchAddressElement.nativeElement.value = "";
              this.consigneeUnitElement.nativeElement.focus();
            }
          }
        }
      });
    });
  }
  //#endregion
  //#region Changes actions
  ExpendImporterOfRecord() {
    this.isExpanded = !this.isExpanded
    this.changeIorCountryEvent();
  }

  changeDocumentOnlyStatus(status: boolean): void {
    this.documentOnlyChecked = status;

    this.products().clear();

    if (this.documentOnlyChecked === false) {
      this.products().enable();
      this.forceAndLockQuantityForDryIce(this.isDryIce && !this.documentOnlyChecked);
    }
    else {
      this.products().disable();
      this.isPaperLess = false;
      this.dutiable().controls["PaperLess"].setValue(false);
      this.removeDryIceWeightValidation(false);
      this.forceAndLockQuantityForDryIce(false);
    }
  }
  changeInsuranceRequiredStatus(status: boolean): void {
    this.insuranceRequiredSelected = status;
  }
  changeMeasurementDisplay(unitOfMeasure: UnitOfMeasure): void {
        
        Swal.fire({
          title: this.translateService.instant("app.misc.convertConfirmation"),
          icon: "question",
          showDenyButton: true,
          denyButtonText: this.translateService.instant("app.misc.no"),
          confirmButtonText: this.translateService.instant("app.misc.yes"),
    
        }).then((result) => {
          this.metricMeasureDisplayed = unitOfMeasure == UnitOfMeasure.Metric; // Ça sert absolument à rien ça (Marc le 2023-11-02)

          if (result.isConfirmed) {
            this.convertProperties(this.packages(), ["width", "depth", "height", "weight"], unitOfMeasure);
            this.convertProperties(this.products(), ["weight"], unitOfMeasure);
          }
        });
  }
  convertProperties(formGroup, properties, unitOfMeasure) {
    formGroup.controls.forEach((p, index) => {
      properties.forEach(property => {
        const isWeight = property === "weight";
        const oldValue = p.controls[property].value;
        let newValue: number;

        if(unitOfMeasure == UnitOfMeasure.Metric)
          newValue = this.utilsService.ConvertToMetric(isWeight, oldValue);
        else
          newValue = Number(this.utilsService.ConvertToImperial(isWeight, oldValue).toFixed(4));

        p.controls[property].setValue(newValue);
      });
    });
  }
  globalCurrencyChanged(currencyValue: TypeOfCurrency) {
    this.options().controls["InsuranceCurrency"].setValue(currencyValue);
    this.dutiable().controls["Currency"].setValue(currencyValue);
  }
  dryIceChanged(event: MatCheckboxChange) {
    this.isDryIce = event.checked;
    this.removeDryIceWeightValidation(this.isDryIce && !this.documentOnlyChecked);
    this.forceAndLockQuantityForDryIce(this.isDryIce && !this.documentOnlyChecked);
  }
  //#endregion
  //#region Methods
  swapAddresses() {
    this.audio.play();
    let temp = this.shipper().value;
    this.shipper().patchValue(this.consignee().value);
    this.consignee().patchValue(temp);
    this.changeCountryEvent(true);
    this.changeCountryEvent(false);
  }
  customPackagesChanged(PAC_ID: number, packageIndex: string | number) {
    var customPackage: Packages = this.customPackages.find(p => p.PAC_ID == PAC_ID);
    let unitOfMeasure:UnitOfMeasure = this.metricMeasureDisplayed ? UnitOfMeasure.Metric : UnitOfMeasure.Imperial;

    var width = customPackage.PAC_Width;
    var length = customPackage.PAC_Length;
    var height = customPackage.PAC_Height;
    var weight = customPackage.PAC_Weight;
    var dryIceWeight = customPackage.PAC_DryIceWeight ? customPackage.PAC_DryIceWeight : 0;

    if(unitOfMeasure == UnitOfMeasure.Imperial) {
      width = Number(this.utilsService.ConvertToImperial(false, width).toFixed(4));
      length = Number(this.utilsService.ConvertToImperial(false, length).toFixed(4));
      height = Number(this.utilsService.ConvertToImperial(false, height).toFixed(4));
      weight = Number(this.utilsService.ConvertToImperial(true, weight).toFixed(4));
      dryIceWeight = Number(this.utilsService.ConvertToImperial(true, dryIceWeight).toFixed(4));
    }

    (<FormGroup>this.packages().controls[packageIndex]).controls["packageType"].setValue(PackageType[customPackage.PAC_PackageType]);
    (<FormGroup>this.packages().controls[packageIndex]).controls["quantity"].setValue(customPackage.PAC_Quantity);
    (<FormGroup>this.packages().controls[packageIndex]).controls["width"].setValue(width);
    (<FormGroup>this.packages().controls[packageIndex]).controls["depth"].setValue(length);
    (<FormGroup>this.packages().controls[packageIndex]).controls["height"].setValue(height);
    (<FormGroup>this.packages().controls[packageIndex]).controls["weight"].setValue(weight);
    (<FormGroup>this.packages().controls[packageIndex]).controls["dryIceWeight"].setValue(dryIceWeight);
  }
  customProductsChanged(Pro_Id: number, productIndex: string | number) {
    var customProduct: Products = this.customProducts.find(p => p.Pro_Id == Pro_Id);
    var country:Countries = this.countriesList.find(c => c.CON_ID == customProduct.Pro_CON_Ref);
    let unitOfMeasure:UnitOfMeasure = this.metricMeasureDisplayed ? UnitOfMeasure.Metric : UnitOfMeasure.Imperial;

    var weight = customProduct.Pro_Weight;

    if(unitOfMeasure == UnitOfMeasure.Imperial) {
      weight = Number(this.utilsService.ConvertToImperial(true, weight).toFixed(4));
    }

    (<FormGroup>this.products().controls[productIndex]).controls["description"].setValue(customProduct.Pro_Desc);
    (<FormGroup>this.products().controls[productIndex]).controls["countryOfOrigin"].setValue(country.CON_Code);
    (<FormGroup>this.products().controls[productIndex]).controls["hsCode"].setValue(customProduct.Pro_HSCode);
    (<FormGroup>this.products().controls[productIndex]).controls["qty"].setValue(customProduct.Pro_Quantity);
    (<FormGroup>this.products().controls[productIndex]).controls["unitPrice"].setValue(customProduct.Pro_UnitValue);
    (<FormGroup>this.products().controls[productIndex]).controls["weight"].setValue(weight);
  }
  saveCurrentAddress(IsOrigin: boolean) {
    this.isProcessing = true;

    this.saveAddress(IsOrigin, (SaveResult: boolean) => {
      this.isProcessing = false;

      if(SaveResult) {
        Swal.fire({
          icon: "info",
          title: this.translateService.instant("app.addressEditor.addressSaved"),
          confirmButtonText: 'OK'
        }).then((sweetAlertResult) => {
          if(IsOrigin)
            this.disableShipperSaveAddress = true;
          else
            this.disableConsigneeSaveAddress = true;
        });
      }
    });
  }
  saveAddress(IsOrigin: boolean, CallBack: { (SaveResult: boolean): void; (arg0: boolean): void; }) {
    const AddressSection: FormGroup = IsOrigin ? this.shipper() : this.consignee();
    const Address = this.addressesList.find((a: spGetAddressListForDivisionResult) => {
      return a.Row == AddressSection.controls['companyName'].value;
    });

    var CompanyName = "";

    if(Address)
      CompanyName = Address.CompanyName;
    else
      CompanyName =  AddressSection.controls['companyName'].value;

    var addressContainer: AddressContainer = {
      "Address": {
        ADD_ID: 0,
        ADD_Company: CompanyName,
        ADD_StreetName: AddressSection.controls['streetName'].value,
        ADD_Unit: AddressSection.controls['unit'].value,
        ADD_City: AddressSection.controls['cityName'].value,
        ADD_PRV_Ref: AddressSection.controls['stateID'].value,
        ADD_Zip: AddressSection.controls['zipCode'].value,
        ADD_CON_Ref: AddressSection.controls['countryID'].value,
        ADD_IsResidential: AddressSection.controls['isResidential'].value,
        ADD_SpecialInstructions: AddressSection.controls['specialInstruction'].value,
        ADD_Div_Ref: 0,
        ADD_CloseTime: "",
        ADD_TaxID: AddressSection.controls['taxID'].value
      },
      "Contacts" : [{
        ADC_ID: 0,
        ADC_ADD_Ref: 0,
        ADC_Name: AddressSection.controls['personName'].value,
        ADC_Email: AddressSection.controls['email'].value,
        ADC_EmailNotification: false,
        ADC_Phone: AddressSection.controls['phone'].value,
        ADC_PhoneExt: AddressSection.controls['extension'].value,
        ADC_Fax: "",
        ADC_Pager: "",
        ADC_IsDefault: false,
        ADC_Typ_Ref: 26
      }]
    }
    var Detail: AddModifyAddressDetail = {
      AddressContainer: addressContainer,
      Language: Language.English,
      IsDevelopmentEnvironment: false
    }

    this.addressBookService.saveAddress(Detail).subscribe(Result => {
      if(Result != null) {
        if(Result.IsValid) {
          this.addressesList = Result.AddressesList;
          sessionStorage.setItem("AddressesList", JSON.stringify(this.addressesList));
        }
      }

      if(CallBack)
        CallBack(Result == null ? false : true);
    });
  }
  saveCurrentIorAddress() {
    this.isProcessing = true;

    this.saveIorAddress((SaveResult: boolean) => {
      this.isProcessing = false;

      if(SaveResult) {
        Swal.fire({
          icon: "info",
          title: this.translateService.instant("app.addressEditor.addressSaved"),
          confirmButtonText: 'OK'
        }).then((sweetAlertResult) => {
          this.disableIorSaveAddress = true;
        });
      }
    });
  }
  saveIorAddress(CallBack: { (SaveResult: boolean): void; (arg0: boolean): void; }) {
    const AddressSection: FormGroup = this.ior();
    const Address = this.addressesList.find((a: spGetAddressListForDivisionResult) => {
      return a.Row == AddressSection.controls['companyName'].value;
    });

    var CompanyName = "";

    if(Address)
      CompanyName = Address.CompanyName;
    else
      CompanyName =  AddressSection.controls['companyName'].value;

    var addressContainer: AddressContainer = {
      "Address": {
        ADD_ID: 0,
        ADD_Company: CompanyName,
        ADD_StreetName: AddressSection.controls['streetName'].value,
        ADD_Unit: AddressSection.controls['unit'].value,
        ADD_City: AddressSection.controls['cityName'].value,
        ADD_PRV_Ref: AddressSection.controls['stateID'].value,
        ADD_Zip: AddressSection.controls['zipCode'].value,
        ADD_CON_Ref: AddressSection.controls['countryID'].value,
        ADD_IsResidential: AddressSection.controls['isResidential'].value,
        ADD_SpecialInstructions: AddressSection.controls['specialInstruction'].value,
        ADD_Div_Ref: 0,
        ADD_CloseTime: "",
        ADD_TaxID: AddressSection.controls['taxID'].value
      },
      "Contacts" : [{
        ADC_ID: 0,
        ADC_ADD_Ref: 0,
        ADC_Name: AddressSection.controls['personName'].value,
        ADC_Email: AddressSection.controls['email'].value,
        ADC_EmailNotification: false,
        ADC_Phone: AddressSection.controls['phone'].value,
        ADC_PhoneExt: AddressSection.controls['extension'].value,
        ADC_Fax: "",
        ADC_Pager: "",
        ADC_IsDefault: false,
        ADC_Typ_Ref: 26
      }]
    }
    var Detail: AddModifyAddressDetail = {
      AddressContainer: addressContainer,
      Language: Language.English,
      IsDevelopmentEnvironment: false
    }

    this.addressBookService.saveAddress(Detail).subscribe(Result => {
      if(Result != null) {
        if(Result.IsValid) {
          this.addressesList = Result.AddressesList;
          sessionStorage.setItem("AddressesList", JSON.stringify(this.addressesList));
        }
      }

      if(CallBack)
        CallBack(Result == null ? false : true);
    });
  }
  onShipperValueChanged() {
    this.disableShipperSaveAddress = false;
  }
  onConsigneeValueChanged() {
    this.disableConsigneeSaveAddress = false;
  }
  onIorValueChanged() {
    this.disableIorSaveAddress = false;
  }
	//-------------------------------------------------------------------
	// Used to get the name of the state since the dataSource have the id
	//-------------------------------------------------------------------
	getStateCode(PRV_ID: number) {
		var province: Provinces = this.statesProvincesList.find(p => p.PRV_ID == PRV_ID);

		if(province)
			return province.PRV_Code;
		else
			return "";
	}

	//---------------------------------------------------------------------
	// Used to get the name of the country since the dataSource have the id
	//---------------------------------------------------------------------
	getCountryCode(CON_ID: number) {
		var country: Countries = this.countriesList.find(c => c.CON_ID == CON_ID);

		if(country)
			return country.CON_Code;
		else
			return "";
	}  
  showProforma() {
      //----------------------------------------------------------------------------------------------------
      // Convert the Order model to a OrderResult model that the proforma popup component expects to receive
      //----------------------------------------------------------------------------------------------------
      var orderResult:OrderResult = {
        Order: {
          ORD_ShipmentDate: this.options().controls["ShipmentDate"].value,
          ORD_Carrier: null,
          CreatedBy: "",
          ORD_CreateDate: "",
          ORD_DeliveryDate: "",
          ORD_ID: null,
          ORD_Instructions: "",
          ORD_IsActive: true,
          ORD_IsVoided: false,
          ORD_Language: "English",
          ORD_LastTrackingDate: null,
          ORD_LastTrackingStatusCode: null,
          ORD_LastTrackingStatusDesc: null,
          ORD_PKU_Ref: -1,
          ORD_Reference: "",
          ORD_ServiceLabel: "",
          ORD_ServiceType: "",
          ORD_ServiceTypeDescription: "",
          ORD_SHIP_ID: "",
          ORD_Status_Typ_Ref: -1,
          ORD_TrackingNumber: "",
          ORD_TrackingURL: "",
          ORD_Tsc_Ref: -1,
          ORD_UnitOfMeasure: "Metric",
          ORD_USR_Ref: -1
        },
        OriginAddress: {
          OAD_City: this.shipper().controls["cityName"].value,
          OAD_Company: this.shipper().controls["companyName"].value,
          OAD_Country: this.getCountryCode(this.shipper().controls["countryID"].value),
          OAD_CountryName: this.shipper().controls["countryName"].value,
          OAD_ID: -1,
          OAD_IsResidential: this.shipper().controls["isResidential"].value,
          OAD_ORD_Ref: -1,
          OAD_SpecialInstructions: this.shipper().controls["specialInstruction"].value,
          OAD_State: this.getStateCode(this.shipper().controls["stateID"].value),
          OAD_StateName: this.shipper().controls["stateName"].value,
          OAD_StreetName: this.shipper().controls["streetName"].value,
          OAD_TYP_Ref: 13,
          OAD_Unit: this.shipper().controls["unit"].value,
          OAD_Zip: this.shipper().controls["zipCode"].value,
          OAD_TaxID: ""
        },
        OriginContact: {
          OAC_Email: this.shipper().controls["email"].value,
          OAC_EmailNotification: false,
          OAC_Fax: "",
          OAC_ID: -1,
          OAC_Name: this.shipper().controls["personName"].value,
          OAC_OAD_Ref: -1,
          OAC_Pager: "",
          OAC_Phone: this.shipper().controls["phone"].value,
          OAC_PhoneExt: this.shipper().controls["extension"].value
        },
        DestinationAddress: {
          OAD_City: this.consignee().controls["cityName"].value,
          OAD_Company: this.consignee().controls["companyName"].value,
          OAD_Country: this.getCountryCode(this.consignee().controls["countryID"].value),
          OAD_CountryName: this.consignee().controls["countryName"].value,
          OAD_ID: -1,
          OAD_IsResidential: this.consignee().controls["isResidential"].value,
          OAD_ORD_Ref: -1,
          OAD_SpecialInstructions: this.consignee().controls["specialInstruction"].value,
          OAD_State: this.getStateCode(this.consignee().controls["stateID"].value),
          OAD_StateName: this.consignee().controls["stateName"].value,
          OAD_StreetName: this.consignee().controls["streetName"].value,
          OAD_TYP_Ref: 13,
          OAD_Unit: this.consignee().controls["unit"].value,
          OAD_Zip: this.consignee().controls["zipCode"].value,
          OAD_TaxID: "",
        },
        DestinationContact: {
          OAC_Email: this.consignee().controls["email"].value,
          OAC_EmailNotification: false,
          OAC_Fax: "",
          OAC_ID: -1,
          OAC_Name: this.consignee().controls["personName"].value,
          OAC_OAD_Ref: -1,
          OAC_Pager: "",
          OAC_Phone: this.consignee().controls["phone"].value,
          OAC_PhoneExt: this.consignee().controls["extension"].value
        },
        IorAddress: {
          OAD_City: this.ior().controls["cityName"].value,
          OAD_Company: this.ior().controls["companyName"].value,
          OAD_Country: this.getCountryCode(this.ior().controls["countryID"].value),
          OAD_CountryName: this.ior().controls["countryName"].value,
          OAD_ID: -1,
          OAD_IsResidential: this.ior().controls["isResidential"].value,
          OAD_ORD_Ref: -1,
          OAD_SpecialInstructions: this.ior().controls["specialInstruction"].value,
          OAD_State: this.getStateCode(this.ior().controls["stateID"].value),
          OAD_StateName: this.ior().controls["stateName"].value,
          OAD_StreetName: this.ior().controls["streetName"].value,
          OAD_TYP_Ref: 53,
          OAD_Unit: this.ior().controls["unit"].value,
          OAD_Zip: this.ior().controls["zipCode"].value,
          OAD_TaxID: this.ior().controls["taxID"].value,
        },
        IorContact: {
          OAC_Email: this.ior().controls["email"].value,
          OAC_EmailNotification: false,
          OAC_Fax: "",
          OAC_ID: -1,
          OAC_Name: this.ior().controls["personName"].value,
          OAC_OAD_Ref: -1,
          OAC_Pager: "",
          OAC_Phone: this.ior().controls["phone"].value,
          OAC_PhoneExt: this.ior().controls["extension"].value
        },
        Dutiable: {
          ODU_Broker: this.dutiable().controls["Broker"].value,
          ODU_CountryOfOrigin: this.dutiable().controls["OriginCountryCode"].value,
          ODU_Currency: TypeOfCurrency[this.dutiable().controls["Currency"].value],
          ODU_ExportReason: ReasonForExport[this.dutiable().controls["ExportReason"].value],
          ODU_ExportReasonDetail: this.dutiable().controls["ExportReasonDetail"].value,
          ODU_ID: -1,
          ODU_InvoiceDate: this.dutiable().controls["InvoiceDate"].value.toString(),
          ODU_InvoiceNumber: this.dutiable().controls["InvoiceNumber"].value,
          ODU_OAD_Ref: -1,
          ODU_ORD_Ref: -1,
          ODU_TaxID: this.dutiable().controls["TaxID"].value,
          ODU_DutiesAndTaxesPaidBy: this.dutiable().controls["DutiesTaxesPaidBy"].value
        },
        DutiableProducts: this.productForm().controls.map(group => {
          let formGroup = group as FormGroup;
          return {
            ODP_Description: formGroup.controls['description'].value,
            ODP_HSCode: formGroup.controls['hsCode'].value,
            ODP_ManufactureCountryCode: formGroup.controls['countryOfOrigin'].value,
            ODP_Value: formGroup.controls['unitPrice'].value,
            ODP_Quantity: formGroup.controls['qty'].value,
            ODP_Weight: formGroup.controls['weight'].value,
            ODP_GrossWeight: formGroup.controls['weight'].value,
            ODP_WeightUnit: "kg",
            ODP_ID: -1,
            ODP_ORD_Ref: -1
          };
        }),
        Packages: null,
        Quote: {
          OQU_CurrencyCode: TypeOfCurrency[this.options().controls["GlobalCurrency"].value],
          OQU_ID: 0,
          OQU_ORD_REF: 0,
          OQU_IsDocumentOnly: false,
          OQU_PaidByShipper: false,
          OQU_IsSignatureRequired: false,
          OQU_IsSaturdayDelivery: false,
          OQU_IsDryIce: false,
          OQU_IsInsurance: false,
          OQU_InsuranceValue: 0,
          OQU_InsuranceCurrency: "",
          OQU_BasePrice: 0,
          OQU_TotalPrice: 0,
          OQU_GlobalProductCode: "",
          OQU_LocalProductCode: ""
        },
        BasePrice : null,
        Surcharges: null,
        Taxes: null,
        Pickup: null,
        CreatedBy: null,
        StatusColor: null,
        StatusFr: null,
        StatusEn: null,
        TotalWeight: null
      }

      this.proformatService.open(orderResult);

      // this.proformatService.confirmed().subscribe(confirmed => {
      //   this.isProcessing = false;
      // });    
  }
  replicateDescription(packageIndex) {
    const descriptionControl = (<FormGroup>this.packages().controls[packageIndex]).controls["description"];

    if (descriptionControl.value) {
      const formArray = this.packages();

      formArray.controls.forEach((control, index) => {
        if (index !== packageIndex) {
          const descriptionControlToUpdate = (<FormGroup>control).controls["description"];
          const descriptionValueToUpdate = descriptionControlToUpdate.value;
          
          if (!descriptionValueToUpdate) {
            descriptionControlToUpdate.setValue(descriptionControl.value);
          }
        }
      });
    }
  }
  GetPaperlessAvailability(): boolean {
    const paperlessCountries: PaperLessCountries[] = JSON.parse(sessionStorage.getItem("paperlessCountries"));

    var OriginAvailable = false;
    var DestinationAvailable = false;
    const originCountryID = this.shipper().controls['countryID'].value;
    const destinationCountryID =  this.consignee().controls['countryID'].value;

    const originPaperLessCountry = paperlessCountries.find(p => p.PLC_CON_Ref == originCountryID);
    const destinationPaperLessCountry = paperlessCountries.find(p => p.PLC_CON_Ref == destinationCountryID);

    if(originPaperLessCountry)
      OriginAvailable = originPaperLessCountry.PLC_Inbound;

    if(destinationPaperLessCountry)
      DestinationAvailable = destinationPaperLessCountry.PLC_Outbound;
    
    return OriginAvailable && DestinationAvailable;
  }
  removeDryIceWeightValidation(isValidate: boolean) {
    
    for (let i = 0; i < this.packages().length; i++) {
      let formGroup = this.packages().at(i) as FormGroup;
      let formControl = formGroup.controls['dryIceWeight'];
    
      formControl.setValidators(isValidate ? [Validators.required] : null);
      formControl.updateValueAndValidity();
    }

    this.cd.detectChanges();
  }
  forceAndLockQuantityForDryIce(force: boolean) {
    for (let i = 0; i < this.packages().length; i++) {
      let formGroup = this.packages().at(i) as FormGroup;
      let formControl = formGroup.controls['quantity'];

      if(force) {
        formControl.setValue(1);
        formControl.disable();
      } else {
        formControl.enable();
      }
    }    
  }
  validateDryIceWeight(pac: FormGroup) {
    const dryIceWeight = pac.get("dryIceWeight");
    const weight = pac.get("weight");
  
    if((dryIceWeight.value > weight.value || dryIceWeight.value == 0) && this.isDryIce) {
      dryIceWeight.setErrors({ 'invalid': true });
      Swal.fire("", this.translateService.instant("app.shopifyOrders.details.packageDetails.dryIceWeightValidation"), "error");
    } else {
      dryIceWeight.setErrors(null);
    }
  }
  //#endregion
}

//#region Exported functions
/**
 * The products form is validated only if the dutiable section is visible
 * @param productFormVisible - True if the product form have to be validated
 * @returns validator errors if present, otherwise null
 */
export function productValidator(productFormVisible: boolean, documentOnly: boolean): ValidatorFn {

  return (control: AbstractControl): { [key: string]: boolean } | null => {

    if (productFormVisible === true && documentOnly === false &&
      (control.value === undefined || (control.value === null) || control.value === "")
    ) {
      return { 'productError': true };
    }
    return null;
  };
}

/**
 * Some packages form fields are validated only if the document only checkbox his not checked
 * @param IsDocumentOnly - True if the package form have to be validated
 * @returns validator errors if present, otherwise nullng build
 * 
 */
export function packageValidator(isDocumentOnly: boolean): ValidatorFn {

  return (control: AbstractControl): { [key: string]: boolean } | null => {

    if (isDocumentOnly === false &&
      (control.value === undefined || (control.value === null))
    ) {
      return { 'packageError': true };
    }
    return null;
  };
}
//#endregion