<div class="custom-component">
    <style>
        /* Scoped styles */
        .custom-component ::ng-deep .mat-tooltip {
            z-index: 1001 !important;
        }

        .custom-component .service-description {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .custom-component .sticky-column {
            position: sticky;
            right: 0;
            background-color: #fff;
            z-index: 2;
            box-shadow: -1px 0 5px rgba(0, 0, 0, 0.1);
        }

        .custom-component ::ng-deep .mat-mdc-form-field-subscript-wrapper,
        .custom-component .mat-mdc-form-field-bottom-align::before {
            display: none !important;
        }

        .custom-component ::ng-deep .mat-mdc-form-field-infix {
            min-height: 0;
        }
    </style>

    <!-- Add Row Button -->
    <div style="display: flex; justify-content: flex-end; margin-right: 8px; margin-bottom: -16px;">
        <button mat-raised-button color="primary" mat-button (click)="addRow()" [disabled]="editing"
            style="float:right; margin-top:5px">
            {{ 'app.manageClients.addrow' | translate }}
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <!-- Table Container -->
    <div style="margin-left:8px; margin-right:8px; overflow: auto; margin-top:10px;">
        <table mat-table matSort matSortActive="MGA_CarrierName" matSortDirection="asc" matSortDisableClear
            [dataSource]="dataSource" style="width:100%;">

            <!-- Columns rendered dynamically -->
            <ng-container [matColumnDef]="col.key" *ngFor="let col of COLUMNS_SCHEMA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="col.key == 'isEdit'"
                    [class.sticky-column]="col.type === 'isEdit'">
                    <div *ngIf="translateService.currentLang == 'fr'">{{ col.label }}</div>
                    <div *ngIf="translateService.currentLang != 'fr'">{{ col.labelEn }}</div>
                </th>
                <td mat-cell *matCellDef="let element" [class.sticky-column]="col.type === 'isEdit'">
                    <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
                        <ng-container *ngSwitchCase="'isSelected'">
                            <mat-checkbox (change)="element.isSelected = $event.checked"></mat-checkbox>
                        </ng-container>
                        <div class="btn-edit" *ngSwitchCase="'isEdit'" style="white-space: nowrap;">
                            <div *ngIf="!editing">
                                <button mat-button class="button-edit" (click)="editRate(element)"
                                    matTooltip="{{ 'app.importOrders.ttEdit' | translate }}" matTooltipPosition="above">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-button class="button-remove" (click)="removeRow(element)"
                                    matTooltip="{{ 'app.importOrders.ttDelete' | translate }}" matTooltipPosition="above">
                                    <mat-icon>delete</mat-icon>
                                </button>
                                <button mat-button class="button-manage" (click)="ManageSubChargeEx(element)"
                                    matTooltip="{{ 'app.importOrders.ttManage' | translate }}" matTooltipPosition="above">
                                    <mat-icon>list</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="col.key == 'MGA_ServiceDescription'" class="service-description"
                            [matTooltip]="getServiceDescription(element[col.key])" matTooltipPosition="above"
                            matTooltipClass="no-wrap-tooltip">
                            <span>{{ getServiceDescription(element[col.key]) }}</span>
                        </div>
                        <div *ngIf="col.key != 'MGA_ServiceDescription'">
                            <span *ngSwitchCase="'checkboxSC'">
                                <div *ngIf="element[col.key]" style="margin-top:8px">
                                    <mat-icon>check_box</mat-icon>
                                </div>
                                <div *ngIf="!element[col.key]" style="margin-top:8px">
                                    <mat-icon>check_box_outline_blank</mat-icon>
                                </div>
                            </span>
                            <span *ngSwitchCase="'checkboxDI'">
                                <div *ngIf="element[col.key]" style="margin-top:8px">
                                    <mat-icon>check_box</mat-icon>
                                </div>
                                <div *ngIf="!element[col.key]" style="margin-top:8px">
                                    <mat-icon>check_box_outline_blank</mat-icon>
                                </div>
                            </span>
                            <span *ngSwitchDefault>
                                <div *ngIf="element[col.key] == -1 || element[col.key] == 999999"></div>
                                <div *ngIf="element[col.key] != -1 && element[col.key] != 999999">
                                    {{ element[col.key] }}
                                </div>
                            </span>
                        </div>
                    </div>
                    <div [ngSwitch]="col.type" *ngIf="element.isEdit">
                        <div *ngSwitchCase="'isSelected'"></div>
                        <div class="btn-edit" *ngSwitchCase="'isEdit'" style="white-space: nowrap;">
                            <button mat-button class="button-remove" (click)="cancelEdit(element)"
                                matTooltip="{{ 'app.importOrders.ttCancel' | translate }}" matTooltipPosition="above">
                                <mat-icon>cancel</mat-icon>
                            </button>
                            <button mat-button class="button-edit" (click)="element.isEdit = saveChanges(element)"
                                matTooltip="{{ 'app.importOrders.ttSave' | translate }}" matTooltipPosition="above">
                                <mat-icon>done</mat-icon>
                            </button>
                        </div>
                        <mat-form-field *ngSwitchCase="'listCarriers'">
                            <mat-select [(value)]="element[col.key]" (selectionChange)="selectedCarrierChanged($event)">
                                <mat-option *ngFor="let c of carriersList" [value]="c.CAR_Name">
                                    {{ c.CAR_Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngSwitchCase="'listServiceDescriptions'" class="custom-column">
                            <mat-select [(value)]="element[col.key]">
                                <mat-option *ngFor="let c of filteredServices" [value]="c.SDE_CODE">
                                    {{ c.SDE_DESCRIPTION }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngSwitchCase="'listCountries'">
                            <mat-select [(value)]="element[col.key]">
                                <mat-option *ngFor="let c of countriesList" [value]="c.CON_Code">
                                    {{ c.CON_Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-checkbox *ngSwitchCase="'checkboxSC'" [(ngModel)]="element[col.key]"
                            [checked]="element[col.key]?.MGA_IsFromShoppingCart"></mat-checkbox>
                        <mat-checkbox *ngSwitchCase="'checkboxDI'" [(ngModel)]="element[col.key]"
                            [checked]="element[col.key]?.MGA_DryIce"></mat-checkbox>
                        <mat-form-field class="form-input" *ngSwitchDefault>
                            <input [type]="col.type" matInput [(ngModel)]="element[col.key]" />
                        </mat-form-field>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="highlight(row)"
                [ngClass]="{'highlightTableColor': selectedRateId == row.MGA_ID}" style="cursor:pointer !important"></tr>
        </table>
    </div>

    <app-processing-overlay *ngIf="isProcessing" [message]="'app.misc.processing' | translate">
    </app-processing-overlay>
</div>
