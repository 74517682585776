import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input,  Output } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Component({
    selector: 'app-complet-address-form',
    templateUrl: './complet-address-form.component.html',
    styleUrls: ['./complet-address-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CompletAddressFormComponent implements OnInit {

  @Input() public parentForm: FormGroup;
  @Input() public groupName: string;

  @Output() public changeCountry = new EventEmitter<string>();
  
  public countries = [
    {
      name: 'Canada',
      code: 'CA'
    },
    {
      name: 'USA',
      code: 'US'
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

  changeCountryEvent(event: any): void {
    this.changeCountry.emit(event.value);
  }

}
