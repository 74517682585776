import { Subscription } from "rxjs";
import { OnInit, OnDestroy, Directive } from "@angular/core";

@Directive()
export abstract class BaseComponentDirective implements OnInit, OnDestroy {
	private subscriptions: Subscription[] = [];
	subscribe(sub: Subscription): void {
		this.subscriptions.push(sub);
	}

	ngOnInit(): void {
		this.onInit();
	}
	ngOnDestroy(): void {
		if (!!this.subscriptions) {
			this.subscriptions.forEach((subscription) => {
				if (!!subscription) {
					subscription.unsubscribe();
				}
			});
		}
		this.onDestroy();
	}
	protected abstract onInit(): void;
	protected abstract onDestroy(): void;
}
