import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageClientsViewLayoutComponent } from "./Layout.component";

const routes: Routes = [{ path: "", redirectTo: "manageclientsviewlayout", pathMatch: "full" }, { path: "manageclientsviewlayout", component: ManageClientsViewLayoutComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageClientsViewLayoutRoutingModule { }
