<!---------------------------------------------------------------->
<!-- Installation required                                      -->
<!---------------------------------------------------------------->
<!-- npm i tslib --location=global                              -->
<!-- npm i angular-split --location=global                      -->
<!---------------------------------------------------------------->

<div style="width: 100%; height: 91vh;background-color: rgb(223, 223, 223);">
	<as-split direction="horizontal">
		<as-split-area [size]="30">
			<Clients (selectedClient) = "Divisions.getDivisions($event);
											Users.getUsers(-1);
											Rates.getRates(-1);
											RateInsurances.getRateInsurances(-1);
											Parameters.getParameters(-1);">
			</Clients>
		</as-split-area>
		<as-split-area [size]="70">
			<as-split direction="vertical">
				<as-split-area [size]="30">
					<Divisions #Divisions (selectedDivision) = "Users.getUsers($event);
															    Rates.getRates($event);
																RateInsurances.getRateInsurances($event);
																Parameters.getParameters($event);
																TabGroup.selectedIndex = 0">
					</Divisions>
				</as-split-area>
				<as-split-area [size]="70">
					<mat-tab-group #TabGroup [@.disabled]="true" [disableRipple]="true" mat-stretch-tabs (selectedTabChange)="tabClick($event)">
						<mat-tab label="{{ 'app.manageClients.tabUsers' | translate }}">
							<Users #Users></Users>
						</mat-tab>
						<mat-tab label="{{ 'app.manageClients.tabRates' | translate }}">
							<Rates #Rates></Rates>
						</mat-tab>
						<mat-tab label="{{ 'app.manageClients.tabInsuranceRates' | translate }}">
							<RateInsurances #RateInsurances></RateInsurances>
						</mat-tab>
						<mat-tab label="{{ 'app.manageClients.tabParameters' | translate }}">
							<Parameters #Parameters></Parameters>
						</mat-tab>
						<mat-tab label="{{ 'app.manageClients.userRights' | translate }}">
							<UserRights #UserRights></UserRights>
						</mat-tab>
					</mat-tab-group>
				</as-split-area>
			</as-split>
		</as-split-area>
	</as-split>
</div>
