<!-- Stepper that display page needed to construct shipment, get quotes and waybill -->
<div #scrollTarget>
  <a id="top"></a>
  <mat-horizontal-stepper linear #stepper [linear]="true">
    <!-- Construct shipment page that correspond to the first step -->
    <mat-step [stepControl]="shipmentFormGroup" [editable]="true" [completed]="stepOneCompleted">
      <form *ngIf="shipmentFormGroup" [formGroup]="shipmentFormGroup" autocomplete="off">
        <ng-template matStepLabel>
          {{ "app.shopifyOrders.details.detail" | translate }}
        </ng-template>
        <app-step1-shipment-form #scrollTarget
          [parentForm]="shipmentFormGroup"
          [order]="order"
          [ngClass]="storageService.getGoToStepTwo() ? 'goToStepTwoVisibilityHidden' : 'goToStepTwoVisibilityVisible'">
        </app-step1-shipment-form>
        <br>
        <div style="z-index: 1" id="fixed-button" [@openClose]="animationState" [style.left]="leftGetQuotesAndSaveButtons" [ngClass]="storageService.getGoToStepTwo() ? 'goToStepTwoVisibilityHidden' : 'goToStepTwoVisibilityVisible'">
          <button mat-raised-button color="primary" style="margin-right: 18px;float:right" (click)="getQuotes()"
            >{{ "app.shopifyOrders.details.getQuotes" | translate }}
            &nbsp;
            <mat-icon>
              send
            </mat-icon>
          </button>
          <button mat-raised-button color="primary" style="margin-right: 10px;float:right" *ngIf="!isGuest"
            (click)="saveOrder()">{{ "app.misc.save" | translate }}
            &nbsp;
            <mat-icon>
              save
            </mat-icon>
          </button>
          <button mat-raised-button color="primary" class="center-icon-button" (click)="scrollToTop()" style="padding-left:10px;padding-right:20px">
            <mat-icon>arrow_upward</mat-icon>&nbsp;&nbsp;{{ "app.misc.scrollTop" | translate }}
          </button>
        </div>
      </form>
    </mat-step>

    <!-- Quotes page that correspond to the second step -->
    <mat-step [editable]="true" [completed]="stepTwoCompleted">
      <ng-template matStepLabel>
        {{ "app.shopifyOrders.details.quotes" | translate }}
      </ng-template>
      <ng-container *ngIf="quotes.length > 0; else emptyQuotesList">
        <app-step2-quotes [quotes]="quotes" [order]="order" [message]="quotesMessage" *ngIf="quotes.length > 0"
          (selectedQuote)="selectQuote($event)">
        </app-step2-quotes>
      </ng-container>
      <ng-template #emptyQuotesList>
        <div *ngIf="isProcessing === false">
          <br>
          {{ "app.shopifyOrders.quotesDetails.clickGetQuoteBtnMessage" | translate }}
          <br>
          <br>
          <div style="white-space: pre-line; background-color: #d1ecf1; width: 100%;">{{quotesMessage}}</div>
          <br>
          <button mat-raised-button color="primary" width="200px" (click)="returnToStepOne($event)">
            <mat-icon class="icon material-icons">west</mat-icon>
            &nbsp;{{ "app.shopifyOrders.details.constructShipment" | translate }}
          </button>
        </div>
      </ng-template>
    </mat-step>

    <!-- Shipment detail page that correspond to the third step -->
    <mat-step *ngIf="!isQuickQuote">
      <ng-template matStepLabel>
        {{ "app.shopifyOrders.details.printWayBill" | translate }}
      </ng-template>
      <app-step3-shipment-detail [shpId]="shpId" *ngIf="displayPrint">
      </app-step3-shipment-detail>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<!-- Overlay displayed while the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>
