// Ref: Building a reusable dialog module with Angular Material -> https://itnext.io/building-a-reusable-dialog-module-with-angular-material-4ce406117918
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef } from '@angular/material/dialog';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { OrderResult } from "@app/shared/CSDAModels/OrderResult";
import { ProformatComponent } from "./proformat.component";

@Injectable()
export class ProformatService {

    dialogRef: MatDialogRef<ProformatComponent>;

    constructor(private dialog: MatDialog) { }

    /** On dialog open, opens a modal dialog containing the proformat component. */
    public open(order: OrderResult) {
        this.dialogRef = this.dialog.open(ProformatComponent, { data: order });
    }

    /** After dialog closed, emits only the first count values emitted by the source Observable */
    public confirmed(): Observable<any> {
        return this.dialogRef.afterClosed().pipe(take(1), map(res => {
            return res;
        }));
    }
}