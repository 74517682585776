import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/dialog";

import { Component, EventEmitter, OnInit, Output, Input, ViewEncapsulation, Inject, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CardValidator } from './validator/card-validator';
import { PaymentCardService } from './service/payment-card.service';
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "@app/core/services/storage.service";

@Component({
    selector: 'ng-payment-card',
    templateUrl: './payment-card.component.html',
    styleUrls: ['./payment-card.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PaymentCardComponent implements OnInit, AfterViewInit {
  isCancelClicked = false;
  isLoading = false;
  public ccForm: FormGroup;
  public months: Array<string> = [];
  public years: Array<number> = [];

  @Input() public ccNumMissing?: string;
  @Input() public ccNumTooShort?: string;
  @Input() public ccNumTooLong?: string;
  @Input() public ccNumChecksumInvalid?: string;
  @Input() public cardHolderMissing?: string;
  @Input() public cardHolderTooLong?: string;
  @Input() public expirationMonthMissing?: string;
  @Input() public expirationYearMissing?: string;
  @Input() public cvvMissing?: string;
  @Input() public cvvNumTooShort?: string;
  @Input() public cvvNumTooLong?: string;
  @Input() public cardExpired?: string;

  @Input() public validateCCNum? = false;
  @Input() public validateCardHolder? = false;
  @Input() public validateExpirationMonth? = false;
  @Input() public validateExpirationYear? = false;
  @Input() public validateCardExpiration? = false;
  @Input() public validateCVV? = false;

  constructor(
    private paymentCardService: PaymentCardService, 
    private _fb: FormBuilder,
    private dialogRef: MatDialogRef<PaymentCardComponent>,
    private translateService: TranslateService,
		private storageService: StorageService,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}

  public ngAfterViewInit(): void {
    // const cancelButton = document.querySelector('.cc-form__button[aria-label="cancel"]');
    // if (cancelButton) {
    //   cancelButton.addEventListener('click', this.onCancelClick.bind(this));
    // }
  }

  public ngOnInit(): void {
    var IsEnglish: boolean = this.storageService.getLanguage()==="en";

    // this.translateService.onLangChange.subscribe(() => {
      this.ccNumMissing            = this.translateService.instant("app.payment.creditCardNumMissing");
      this.ccNumTooShort           = this.translateService.instant("app.payment.creditCardNumTooShort");
      this.ccNumTooLong            = this.translateService.instant("app.payment.creditCardNumTooLong");
      this.ccNumChecksumInvalid    = this.translateService.instant("app.payment.creditCardNumChecksumInvalid");
      this.cardHolderMissing       = this.translateService.instant("app.payment.creditCardHolderMissing");
      this.cardHolderTooLong       = this.translateService.instant("app.payment.creditCardHolderTooLong");
      this.expirationMonthMissing  = this.translateService.instant("app.payment.creditCardExpirationMonthMissing");
      this.expirationYearMissing   = this.translateService.instant("app.payment.creditCardExpirationYearMissing");
      this.cvvMissing              = this.translateService.instant("app.payment.creditCardCVVMissing");
      this.cvvNumTooShort          = this.translateService.instant("app.payment.creditCardCVVNumTooShort");
      this.cvvNumTooLong           = this.translateService.instant("app.payment.creditCardCVVNumTooLong");
      this.cardExpired             = this.translateService.instant("app.payment.creditCardExpired");
    // });

    this.buildForm();
    this.assignDateValues();
  }

  private assignDateValues(): void {
    this.months = PaymentCardService.getMonths();
    this.years = PaymentCardService.getYears();
  }

  private buildForm(): void {
    this.ccForm = this._fb.group(
      {
        cardNumber: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(12),
            Validators.maxLength(19),
            CardValidator.numbersOnly,
            CardValidator.checksum,
          ]),
        ],
        cardHolder: ['', Validators.compose([Validators.required, Validators.maxLength(22)])],
        expirationMonth: ['', Validators.required],
        expirationYear: ['', Validators.required],
        cvv: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(4),
            CardValidator.numbersOnly,
          ]),
        ],
      },
      {
        validator: CardValidator.expiration,
      }
    );
  }

  getCardType(ccNum: string): string | null {
    return PaymentCardService.getCardType(ccNum);
  }

  continue(event: Event) {
    if (this.isCancelClicked) {
      this.isCancelClicked = false;
      return;
    }

    if (this.ccForm.invalid) {
      this.validateCCNum = true;
      this.validateCardHolder = true;
      this.validateExpirationMonth = true;
      this.validateExpirationYear = true;
      this.validateCardExpiration = true;
      this.validateCVV = true;
      
      this.paymentCardService.hideSpinner();
      event.preventDefault();
      return;
    }
    else {
      this.paymentCardService.hideSpinner();
      this.dialogRef.close(this.ccForm.value);
    }
  }

  onCancelClick(event: Event): void {
    this.isCancelClicked = true;
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close(false);
  }
  validateInput(event: KeyboardEvent): void {
    const allowedKeys = [
      'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End', 'Enter', 'Escape'
    ];
    const isNumber = (event.key >= '0' && event.key <= '9');
    const isControlKey = event.ctrlKey || event.metaKey;
  
    if (!isNumber && !allowedKeys.includes(event.key) && !isControlKey) {
      event.preventDefault();
    }
  }
}