import { MatTableDataSource as MatTableDataSource } from "@angular/material/table";

import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from "@angular/core";
import { spCSDAManageRateGetListResult } from "@app/shared/CSDAModels/ManageClientModels/Rate/spCSDAManageRateGetListResult";
import { TranslateService } from "@ngx-translate/core";
import { ManageClientsService } from "../_Services/manage-clients.service";
import { CarriersList } from "@app/shared/CSDAModels/CarriersList";
import { Countries } from "@app/shared/CSDAModels/Countries";
import { CSAPI_ServiceDescriptions } from "@app/shared/CSDAModels/CSAPI_ServiceDescriptions";
import { MatSort } from "@angular/material/sort";
import Swal from "sweetalert2";
import { MoGlobexAddRatesSubchargeEx } from "@app/shared/CSDAModels/MoGlobexAddRatesSubchargeEx";
import { GetManageSurchargeExResult } from "@app/shared/CSDAModels/GetManageSurchargeExResult";
import { Type } from "@app/shared/CSDAModels/Type";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from "@angular/material/tooltip";

const matTooltipDefaultOptions: MatTooltipDefaultOptions = {
  disableTooltipInteractivity: true,
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0
};

@Component({
    selector: 'Rates',
    templateUrl: './Rates.component.html',
    styleUrls: ['./Rates.component.scss'],
    providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipDefaultOptions }],
    standalone: false
})
export class RatesComponent implements OnInit, AfterViewInit {

  //---------------------------------------------------
  // Define the fields of the model which are displayed
  //---------------------------------------------------
  COLUMNS_SCHEMA = this.getSchema();
  displayedColumns: string[] = this.COLUMNS_SCHEMA.map((col) => col.key);
  dataSource = new MatTableDataSource([]);
  ratesList: spCSDAManageRateGetListResult[];

  isProcessing = false;
  public Div_Id = -1;
  selectedRateId = -1;
  originalRate: spCSDAManageRateGetListResult;
  editing = false;
  carriersList: CarriersList[];
  countriesList: Countries[];
  serviceDescriptionsList: CSAPI_ServiceDescriptions[];
  filteredServices: CSAPI_ServiceDescriptions[];
  selectedCarrier: number = -1;
  
  private MGA_ID: number;
  private MGA_DIV_REF: number;
  private SurchargeTypes: { code: string, name: string }[] = [
    { code: 'RES', name: 'Residential' },
    { code: 'SIR', name: 'Signature Required' }
  ];

  constructor(
    private translateService: TranslateService,
    private ManageClientsService: ManageClientsService,
  ) { }

  @HostListener('window:keyup', ['$event'])

  keyEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      //TODO: If possible because I don't have the element here and I need to cancel the element being edited.
    }
  }

  @Output('selectedRate') selectedRate: EventEmitter<number> = new  EventEmitter<number>();
  @ViewChild(MatSort) sort: MatSort;
  
  ngAfterViewInit() {
  }

  ngOnInit(): void {
    this.carriersList = JSON.parse(sessionStorage.getItem("Carriers")) as CarriersList[];
    this.countriesList = JSON.parse(sessionStorage.getItem("Countries")) as Countries[];
    this.serviceDescriptionsList = JSON.parse(sessionStorage.getItem("ServiceDescriptions")) as CSAPI_ServiceDescriptions[];
  }

  getSchema() {
    return [
      {
        key: 'MGA_CarrierName',
        type: 'listCarriers',
        label: "Agent",
        labelEn: 'Carrier'
      },
      {
        key: 'MGA_ServiceDescription',
        type: 'listServiceDescriptions',
        label: "Nom de service",
        labelEn: 'Service Name'
      },
      {
        key: 'MGA_Rate',
        type: 'number',
        label: "Taux",
        labelEn: 'Rate'
      },
      {
        key: 'MGA_Min_Weight',
        type: 'number',
        label: "Poids Min.",
        labelEn: 'Min. Weight'
      },
      {
        key: 'MGA_Max_Weight',
        type: 'number',
        label: "Poids Max.",
        labelEn: 'Max. Weight'
      },
      {
        key: 'MGA_CountryOrig_Code',
        type: 'listCountries',
        label: "Pays Origine",
        labelEn: 'Origin Country'
      },
      {
        key: 'MGA_CountryDest_Code',
        type: 'listCountries',
        label: "Pays Destination",
        labelEn: 'Destination Country'
      },
      {
        key: 'MGA_IsFromShoppingCart',
        type: 'checkboxSC',
        label: "Du Panier",
        labelEn: 'From Shopping Cart'
      },
      {
        key: 'MGA_DryIce',
        type: 'checkboxDI',
        label: "Glace Sèche",
        labelEn: 'Dry Ice'
      },
      {
        key: 'MGA_ShoppingCartName',
        type: 'text',
        label: "Nom du tarif",
        labelEn: 'Rate Name'
      },
      {
        key: 'MGA_ShoppingCartDescription',
        type: 'text',
        label: "Desc. du tarif",
        labelEn: 'Rate Desc.'
      },
      {
        key: 'isEdit',
        type: 'isEdit',
        label: ''
      }
    ];
  }
  
  getRates(id: any) {
    if(id != -1) {
      this.Div_Id = id;
      const DivId = id != -1 ? id : this.Div_Id;
      this.Div_Id = DivId;
      this.isProcessing = true;
      this.editing = false;
      this.selectedRateId = -1;
      
      this.ManageClientsService.getRates(DivId).subscribe(Result => {
        this.ratesList = Result.RatesList;
        this.dataSource = new MatTableDataSource(this.ratesList);
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch(property) {
            case 'MGA_ServiceDescription': 
              return this.getServiceDescription(item.MGA_ServiceDescription);
            default: 
              return item[property];
          }
        };
    
        this.dataSource.sort = this.sort;
            this.isProcessing = false;
      })
    } else {
      this.dataSource = null;
    }
  }

  addRow() {
    const newRow = {
      MGA_ID: -1,
      MGA_DIV_REF: this.Div_Id,
      MGA_CarrierName: '',
      MGA_ServiceDescription: '',
      MGA_Rate: null,
      MGA_Min_Weight: null,
      MGA_Max_Weight: null,
      MGA_CountryOrig_Code: '',
      MGA_CountryDest_Code: '',
      MGA_IsFromShoppingCart: false,
      MGA_ShoppingCartName: '',
      MGA_ShoppingCartDescription: '',
      MGA_DryIce: false,
      isEdit: true,
    };

    this.editing = true;
    this.dataSource.data = [newRow, ...this.dataSource.data];
    this.filteredServices = null;
  }

  removeRow(element) {
    Swal.fire({
      title: 'Confirmation',
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.isProcessing = true;

        this.ManageClientsService.deleteRate(element).subscribe(Result => {
          this.isProcessing = false;
          this.dataSource.data = this.dataSource.data.filter((u) => u.MGA_ID != element.MGA_ID);
        });
      }
    });    
  }

  removeSelectedRows() {
    Swal.fire({
      title: 'Confirmation',
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.dataSource.data = this.dataSource.data.filter((u: any) => !u.isSelected);
      }
    });    
  }

  saveChanges(element: spCSDAManageRateGetListResult): boolean {

    if(this.validateRange(element)) {
      this.isProcessing = true;

      const index = this.dataSource.data.findIndex(object => {
        return object.MGA_ID === element.MGA_ID;
      });

      this.ManageClientsService.saveRate(element).subscribe(Result => {
        this.dataSource.data[index].MGA_ID = Result.MGA_ID;
        this.isProcessing = false;
      });

      this.editing = false;
      return false;
    }
    else
      return true;
  }

  editRate(element: any) {
    this.editing = true;
    element.isEdit = true;
    this.originalRate = JSON.parse(JSON.stringify(element));
    this.filteredServices = this.serviceDescriptionsList.filter(f => f.SDE_CARRIER == element.MGA_CarrierName);
  }

  cancelEdit(element: any) {
    if(element.MGA_ID == -1)
      this.dataSource.data = this.dataSource.data.filter((u) => u.MGA_ID != element.MGA_ID);
    else {
      element.MGA_CarrierName = this.originalRate.MGA_CarrierName;
      element.MGA_ServiceDescription = this.originalRate.MGA_ServiceDescription;
      element.MGA_Rate = this.originalRate.MGA_Rate;
      element.MGA_Min_Weight = this.originalRate.MGA_Min_Weight;
      element.MGA_Max_Weight = this.originalRate.MGA_Max_Weight;
      element.MGA_CountryOrig_Code = this.originalRate.MGA_CountryOrig_Code;
      element.MGA_CountryDest_Code = this.originalRate.MGA_CountryDest_Code;
      element.MGA_IsFromShoppingCart = this.originalRate.MGA_IsFromShoppingCart;
      element.MGA_ShoppingCartName = this.originalRate.MGA_ShoppingCartName;
      element.MGA_ShoppingCartDescription = this.originalRate.MGA_ShoppingCartDescription;
      element.MGA_DryIce = this.originalRate.MGA_DryIce;
    }
    
    this.editing = false;
    element.isEdit = false;
    return false;
  }

  highlight(row){
    if(!this.editing)
      this.selectedRateId = row.MGA_ID;

    //return false;
  }

  selectedCarrierChanged($event) {
    this.filteredServices = this.serviceDescriptionsList.filter(f => f.SDE_CARRIER == $event.value);
  }

  getServiceDescription(Code: string): string {
    const index = this.serviceDescriptionsList.findIndex(object => {
      return object.SDE_CODE === Code;
    });

    if(index != -1)
      return this.serviceDescriptionsList[index].SDE_DESCRIPTION.trim();
    else
      return '';
  }

  validateRange(element: spCSDAManageRateGetListResult) : boolean {
    
    const index = this.dataSource.data.findIndex(rate => {

      var SameID = element.MGA_ID == rate.MGA_ID;

      var CarrierSame = element.MGA_CarrierName == rate.MGA_CarrierName;
      var ServiceSame = element.MGA_ServiceDescription == rate.MGA_ServiceDescription;
      var CountryOrigSame = (element.MGA_CountryOrig_Code == undefined ? '' : element.MGA_CountryOrig_Code) == (rate.MGA_CountryOrig_Code == undefined ? '' : rate.MGA_CountryOrig_Code);
      var CountryDestSame = (element.MGA_CountryDest_Code == undefined ? '' : element.MGA_CountryDest_Code) == (rate.MGA_CountryDest_Code == undefined ? '' : rate.MGA_CountryDest_Code);
      var MinInside   = (+element.MGA_Min_Weight >= rate.MGA_Min_Weight && +element.MGA_Min_Weight < rate.MGA_Max_Weight);
      var MaxInside   = (+element.MGA_Max_Weight > rate.MGA_Min_Weight && +element.MGA_Max_Weight <= rate.MGA_Max_Weight);
      var AllOutside   = (+element.MGA_Min_Weight <= rate.MGA_Min_Weight && +element.MGA_Max_Weight >= rate.MGA_Max_Weight);
      var IsDryIce =  element.MGA_DryIce == rate.MGA_DryIce;

      var finalState = !SameID && CarrierSame && ServiceSame && CountryOrigSame && CountryDestSame && (MinInside || MaxInside || AllOutside) && IsDryIce;

      return finalState;
    });

    if(index != -1) {
      Swal.fire('', this.translateService.instant("app.manageClients.rateRangeOverlap"), "error") 
      return false;
    }
    else
      return true;
  }

  //-----------------------------------------------------------------------------------------------------------------------
  ManageSubChargeEx(element: any) {
    var CurrentRate: spCSDAManageRateGetListResult = element as spCSDAManageRateGetListResult;

    this.isProcessing = true;
    this.ManageClientsService.getSurchargeEx(CurrentRate.MGA_ID, CurrentRate.MGA_DIV_REF).subscribe((Result: GetManageSurchargeExResult) => {
      this.isProcessing = false;
      this.MGA_ID = CurrentRate.MGA_ID;
      this.MGA_DIV_REF = CurrentRate.MGA_DIV_REF;

      const style = document.createElement('style');
      style.innerHTML = `
        /* Material Design styles */
        .swal2-popup {
          font-family: Arial, sans-serif;
          padding: 4px;
          overflow: hidden;
          position: relative;
        }
  
        /* Remove Horizontal Scroll */
        .swal2-popup #dataGrid {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 15px;
          table-layout: fixed;
          overflow-x: hidden; /* Disable horizontal scrolling */
        }
  
        .swal2-popup #dataGrid th, .swal2-popup #dataGrid td {
          padding: 6px;
          border: 1px solid #ddd;
          word-wrap: break-word;
        }

        .swal2-popup #dataGrid th {
          background-color: #f4f4f4;
          font-weight: bold;
        }
  
        .swal2-popup #dataGrid tbody tr:hover {
          background-color: #f9f9f9;
        }
  
        .swal2-popup #dataGrid td {
          font-size: 14px;
        }
  
        @media (max-width: 768px) {
          .swal2-popup #dataGrid th, .swal2-popup #dataGrid td {
            padding: 8px;
            font-size: 12px;
          }
  
          .swal2-popup #dataGrid {
            font-size: 12px;
          }
        }
  
        /* Material Design Style for Select Dropdown */
        select.form-control {
          width: 100%;
          padding: 8px 12px;  /* Adjusted size */
          margin: 0;
          border-radius: 4px;
          border: 1px solid #ccc;
          background-color: white;
          font-size: 14px;
          transition: border-color 0.3s ease-in-out;
        }
  
        select.form-control:focus {
          border-color: #4CAF50;
          box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
        }
  
        /* Material Design Style for Input */
        input.form-control {
          padding: 8px 12px;  /* Adjusted size */
          margin: 0;
          border-radius: 4px;
          border: 1px solid #ccc;
          background-color: white;
          font-size: 14px;
          box-sizing: border-box;
          width: 100%;
          transition: border-color 0.3s ease-in-out;
        }
  
        input.form-control:focus {
          border-color: #4CAF50;
          box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
        }
  
        /* Material Design Style for Buttons */
        .btn {
          padding: 8px 16px;  /* Adjusted size */
          margin: 5px;
          border: none;
          background-color: #7066e0;
          color: white;
          font-size: 14px;
          font-weight: bold;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s ease-in-out;
        }
        .btn-danger {
          background-color: red;  /* Set the background color to red */
          color: white;  /* Ensure the text color remains white */
        }

        .btn:hover {
          background-color: #3700B3;
        }
  
        .btn:active {
          background-color: #6200EE;
          box-shadow: 0 0 0 0.2rem rgba(98, 0, 238, 0.25);
        }
  
        /* Ensuring the buttons stay in one line */
        .swal2-popup td:last-child {
          white-space: nowrap;
          text-align: center;
        }
  
        .swal2-popup .add-row-btn-container {
          display: flex;
          align-items: center;
          width: 100%; /* Ensure the container takes up the full width */
        }

        .swal2-popup .add-row-btn {
          margin-top: -70px;
          align-self: flex-start;  /* Align left */
        }

        .swal2-popup .apply-to-all-btn {
        margin-left: 12px;
          align-self: flex-start; /* Align to the left or adjust to your preference */
          margin-top: -70px; /* Adjust as needed */
        }
  
        /* Fixing column layout to avoid overlap */
        .swal2-popup #dataGrid td:nth-child(3) {
          max-width: 120px;  /* Adjust the width of 'Valeur' column */
        }
  
        .swal2-popup #dataGrid td:last-child {
          max-width: 120px; /* Adjust the width of 'Actions' column */
        }
  
        /* Fix the overflow issue for "Valeur" column */
        .swal2-popup #dataGrid td {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        /* Apply centering to the Actions column header */
        .swal2-popup #dataGrid th.actions {
          text-align: center;  /* Center the header text of the Actions column */
        }
      `;
      document.head.appendChild(style);
  
      Swal.fire({
        title: '',
        html: `
          <style>
            /* Spinner animation */
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }

            .spinner {
              border: 14px solid rgb(203, 203, 203);
              border-top: 14px solid rgb(19, 123, 193);
              border-radius: 50%;
              width: 50px;
              height: 50px;
              animation: spin 1s linear infinite;
              margin: 0 auto;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            #spinnerContainer {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              // background-color: rgba(0, 0, 0, 0.1);
              z-index: 9999;
              display: none;
            } 
            #dataGridContainer {
              position: relative;
              max-width: 100%;
              width: 100%;
              margin-top: -140px;
            }
            /* Prevent the Actions column from resizing and adjust the size */
            .surcharge-exceptions-table {
                width: 100%;
                table-layout: fixed; /* Fix the layout to respect set widths */
                border: 1px solid black;
            }
            
            #dataGrid thead tr th {
              background-color: #EEC990 !important;
              color: black;
            }

            .actions-column {
                width: 150px;  /* Set a fixed width for the Actions column */
                text-align: center;  /* Center the buttons */
                white-space: nowrap;  /* Prevent wrapping of button text */
                overflow: hidden;  /* Ensure content doesn’t overflow */
            }

            .actions-column button {
                display: inline-block;  /* Make buttons stay next to each other */
                padding: 4px 8px;       /* Adjust padding to fit buttons in the fixed space */
                font-size: 12px;        /* Adjust font size to fit the buttons within the column */
                margin: 0 2px;          /* Space between buttons */
                box-sizing: border-box; /* Ensure padding and borders are included in the button size */
            }

            @media (max-width: 600px) {
                .actions-column {
                    width: 120px;  /* Reduce the width of the Actions column for small screens */
                }

                .actions-column button {
                    font-size: 10px;  /* Further reduce the font size for small screens */
                    padding: 4px 8px; /* Adjust padding */
                    margin: 0 2px;    /* Space between buttons */
                }
            }
          </style>
          <div id="dataGridContainer">
            <div id="spinnerContainer">
              <div class="spinner"></div>
            </div>                
            <div>
              <table id="dataGrid" class="table table-striped surcharge-exceptions-table">
                <thead>
                  <tr>
                    <th style="text-align: left">Surcharge Type</th>
                    <th style="text-align: left">Value Type</th>
                    <th style="text-align: left">Value</th>
                    <th class="actions-column">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Dynamic rows will go here -->
                </tbody>
              </table>
            </div>
          </div>
        `,
        focusConfirm: false,
        showCancelButton: false,
        showCloseButton: true,
        width: '40%',

        preConfirm: () => {
          // Handle save logic if needed (optional)
        },
        willOpen: () => {
          (document.querySelector('.swal2-title') as HTMLElement).innerHTML = 
            "<span style='display: block; text-align: center !important; font-weight: bold'>MANAGE SURCHAGE EXCEPTIONS</span>" + 
            "<span style='display: block; margin-top: 15px; text-align: left;'>Carrier: <span style='color: darkblue;'>" + CurrentRate.MGA_CarrierName + "</span></span>" +
            "<span style='display: block; text-align: left'>Service: <span style='color: darkblue;'>" + CurrentRate.MGA_ServiceDescription + "</span></span>";
        },
        didOpen: () => {

          const swalPopup = document.querySelector('.swal2-popup') as HTMLElement;

          if (swalPopup)
            swalPopup.style.zIndex = '9995'; // Ensure SweetAlert is below the overlay

          const dataGridContainer = document.getElementById('dataGridContainer');
          const dataGridBody = document.querySelector('#dataGrid tbody') as HTMLElement;
          const valueTypes = Result.ValueTypes;
          const subchargeExs = Result.SubchargeExs;

          subchargeExs.forEach(row => {
            const newRow = document.createElement('tr');
            newRow.setAttribute('data-row-id', row.MGX_ID.toString()); // Store row ID for updates
            this.handleRenderRow(newRow, row, valueTypes, false); // Initial render without controls
            dataGridBody.appendChild(newRow);
          });
  
          const addRowBtn = document.createElement('button');
          addRowBtn.classList.add('btn', 'btn-sm', 'btn-success', 'add-row-btn');
          addRowBtn.textContent = 'Add New Row';
          addRowBtn.addEventListener('click', () => this.handleAddNewRow(dataGridBody, valueTypes));
          
          const applyToAllBtn = document.createElement('button');
          applyToAllBtn.classList.add('btn', 'btn-sm', 'btn-info', 'apply-to-all-btn'); // You can use any class here to style it as needed
          applyToAllBtn.textContent = 'Apply to all service of this carrier';
          applyToAllBtn.addEventListener('click', () => this.handleApplyToAllService(subchargeExs, CurrentRate.MGA_CarrierName));
          
          const addRowBtnContainer = document.createElement('div');
          addRowBtnContainer.classList.add('add-row-btn-container');
          addRowBtnContainer.appendChild(addRowBtn);
          addRowBtnContainer.appendChild(applyToAllBtn);  // Add the new button to the container
          
          dataGridContainer?.appendChild(addRowBtnContainer);
        }
      });
    });
  }
  handleCreateDropdown(options: { code: string | number, name: string }[], selectedValue: string | number) {
    const select = document.createElement('select');
    select.classList.add('form-control');
  
    options.forEach(option => {
      const optionElement = document.createElement('option');
      optionElement.value = option.code.toString();  // Ensure it's a string
      optionElement.textContent = option.name;
  
      // Set the selected value if it matches
      if (option.code.toString() === selectedValue.toString()) {
        optionElement.setAttribute('selected', 'true');
      }
  
      select.appendChild(optionElement);
    });
  
    return select.outerHTML;
  }
  handleAddNewRow(dataGridBody: HTMLElement, valueTypes: Type[]) {
    const newRow = document.createElement('tr');
    const rowData = {
        MGX_MGA_Ref: this.MGA_ID,
        MGX_DIV_Id_Ref: this.MGA_DIV_REF,
        MGX_SurchargeCode: '',
        MGX_Typ_Ref: -1,
        MGX_Value: 0
    };

    newRow.setAttribute('data-is-new', 'true');
    this.handleRenderRow(newRow, rowData, valueTypes, true); // Render new row in edit mode
    dataGridBody.appendChild(newRow);
    this.handleFocusSweet();
  }
  handleSave(row: HTMLTableRowElement, rowData: any, valueTypes: any[]) {
      const cells = row.getElementsByTagName('td');
      const surchargeCode = cells[0].querySelector('select')?.value || cells[0].textContent; // Get value from select or text
      const typeValue = cells[1].querySelector('select')?.value || rowData.MGX_Typ_Ref;
      const value = cells[2].querySelector('input')?.value || cells[2].textContent;

      if (row.getAttribute('data-is-new') === 'true') {
          rowData.MGX_SurchargeCode = surchargeCode;
          rowData.MGX_Typ_Ref = parseInt(typeValue);
          rowData.MGX_Value = parseFloat(value);
      } else {
          // Update existing row data (replace with your actual update logic)
          rowData.MGX_SurchargeCode = surchargeCode;
          rowData.MGX_Typ_Ref = parseInt(typeValue);
          rowData.MGX_Value = parseFloat(value);
      }

      row.setAttribute('data-is-new', 'false');
      this.handleRenderRow(row, rowData, valueTypes, false); // Render in non-edit mode after save

      const spinner = document.getElementById('spinnerContainer') as HTMLElement;
      spinner.style.display = 'block';
      this.ManageClientsService.saveSurchargeEx(rowData).subscribe((Result) => {
          row.setAttribute('data-row-id', Result.MGX_ID.toString()); // Update with the ID from the server
          spinner.style.display = 'none';
          this.handleFocusSweet();

      });
  }
  handleEdit(rowData: any, row: HTMLTableRowElement, valueTypes: any[]) {
      this.handleRenderRow(row, rowData, valueTypes, true);
      this.handleFocusSweet();
  }
  handleCancel(rowData: any, row: HTMLTableRowElement, valueTypes: any[]) {
      if (row.getAttribute('data-is-new') === 'true') {
          row.remove();
      } else {
          this.handleRenderRow(row, rowData, valueTypes, false); // Re-render in non-edit mode
      }

      this.handleFocusSweet();      
  }
  handleDelete(row: MoGlobexAddRatesSubchargeEx, rowElement: HTMLElement) {
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlay.style.display = 'flex';
    overlay.style.justifyContent = 'center';
    overlay.style.alignItems = 'center';
    overlay.style.zIndex = '9999';
  
    const confirmBox = document.createElement('div');
    confirmBox.style.background = 'white';
    confirmBox.style.padding = '20px';
    confirmBox.style.borderRadius = '5px';
    confirmBox.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.1)';
    
    confirmBox.style.display = 'flex';
    confirmBox.style.flexDirection = 'column';
    confirmBox.style.alignItems = 'center';
    confirmBox.style.justifyContent = 'flex-start';
    confirmBox.style.gap = '20px';
  
    confirmBox.innerHTML = `
      <h3>Are you sure you want to delete this row ?</h3>
      <div id="buttonsContainer" style="display: flex; justify-content: center; gap: 20px;">
        <button id="confirmDelete" class="btn btn-success">Yes, Delete</button>
        <button id="cancelDelete" class="btn btn-danger">No, Cancel</button>
      </div>
    `;
  
    overlay.appendChild(confirmBox);
    document.body.appendChild(overlay);
  
    confirmBox.style.transform = 'scale(0)';
    confirmBox.style.transition = 'transform 0.3s ease';
  
    setTimeout(() => {
      confirmBox.style.transform = 'scale(1)';
    }, 50);
  
    document.getElementById('confirmDelete')?.addEventListener('click', () => {
      rowElement.remove();
      confirmBox.style.transform = 'scale(0)';
      
      setTimeout(() => {
        overlay.remove();
      }, 200);
    });
  
    document.getElementById('cancelDelete')?.addEventListener('click', () => {
      confirmBox.style.transform = 'scale(0)';
      setTimeout(() => {
        overlay.remove();
        console.log('Deletion canceled');
      }, 200);
    }); 
    
    this.handleFocusSweet();
  }
  handleRenderRow(row: HTMLTableRowElement, data: any, valueTypes: any[], isEditing: boolean) {
    const surchargeCodeSelect = this.handleCreateDropdown(this.SurchargeTypes, data.MGX_SurchargeCode);

    const typRefSelect = this.handleCreateDropdown(
        valueTypes.map(vt => ({ code: vt.Typ_Id, name: vt.Typ_Name_En })),
        data.MGX_Typ_Ref
    );

    row.innerHTML = `
        <td style="text-align: left">${isEditing ? surchargeCodeSelect : this.SurchargeTypes.find(item => item.code === data.MGX_SurchargeCode).name}</td>
        <td style="text-align: left">${isEditing ? typRefSelect : (valueTypes && data && data.MGX_Typ_Ref) ? valueTypes.find(vt => vt.Typ_Id === data.MGX_Typ_Ref)?.Typ_Name_En || '' : ''}</td>
        <td style='text-align: left !important'>${isEditing ? `<input type="number" value="${data.MGX_Value || ''}" class="form-control" />` : data.MGX_Value || ''}</td>
        <td class="actions-column">
            ${isEditing ? `
                <button class="btn btn-sm btn-success saveBtn">Save</button>
                <button class="btn btn-sm btn-danger cancelBtn">Cancel</button>
            ` : `
                <button class="btn btn-sm btn-warning editBtn">Edit</button>
                <button class="btn btn-sm btn-danger deleteBtn">Delete</button>
            `}
        </td>
    `;

    if (isEditing) {
        const saveBtn = row.querySelector('.saveBtn');
        saveBtn?.addEventListener('click', () => this.handleSave(row, data, valueTypes));

        const cancelBtn = row.querySelector('.cancelBtn');
        cancelBtn?.addEventListener('click', () => this.handleCancel(data, row, valueTypes));
    } else {
        const editBtn = row.querySelector('.editBtn');
        editBtn?.addEventListener('click', () => this.handleEdit(data, row, valueTypes));

        const deleteBtn = row.querySelector('.deleteBtn');
        deleteBtn?.addEventListener('click', () => this.handleDelete(data, row));
    }

    this.handleFocusSweet();
  }  
  handleApplyToAllService(SurchargeExs: MoGlobexAddRatesSubchargeEx[], CarrierName: string) {
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlay.style.display = 'flex';
    overlay.style.justifyContent = 'center';
    overlay.style.alignItems = 'center';
    overlay.style.zIndex = '9999';
  
    const confirmBox = document.createElement('div');
    confirmBox.style.background = 'white';
    confirmBox.style.padding = '20px';
    confirmBox.style.borderRadius = '5px';
    confirmBox.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.1)';
    
    confirmBox.style.display = 'flex';
    confirmBox.style.flexDirection = 'column';
    confirmBox.style.alignItems = 'center';
    confirmBox.style.justifyContent = 'flex-start';
    confirmBox.style.gap = '20px';
  
    confirmBox.innerHTML = `
      <h3>Are you sure you want to do this update ?</h3>
      <div id="buttonsContainer" style="display: flex; justify-content: center; gap: 20px;">
        <button id="confirmUpdate" class="btn btn-success">Yes</button>
        <button id="cancelUpdate" class="btn btn-danger">No</button>
      </div>
    `;
  
    overlay.appendChild(confirmBox);
    document.body.appendChild(overlay);
  
    confirmBox.style.transform = 'scale(0)';
    confirmBox.style.transition = 'transform 0.3s ease';
  
    setTimeout(() => {
      confirmBox.style.transform = 'scale(1)';
    }, 50);
  
    document.getElementById('confirmUpdate')?.addEventListener('click', () => {
      confirmBox.style.transform = 'scale(0)';
      
      setTimeout(() => {
        overlay.remove();
        const spinner = document.getElementById('spinnerContainer') as HTMLElement;
        spinner.style.display = 'block';        
        this.handleExecuteUpdate(SurchargeExs, CarrierName)
      }, 200);
    });
  
    document.getElementById('cancelUpdate')?.addEventListener('click', () => {
      confirmBox.style.transform = 'scale(0)';
      setTimeout(() => {
        overlay.remove();
      }, 200);
    }); 
    
    this.handleFocusSweet();    
  }
  handleExecuteUpdate(SurchargeExs: MoGlobexAddRatesSubchargeEx[], CarrierName: string) {
    const spinner = document.getElementById('spinnerContainer') as HTMLElement;
    spinner.style.display = 'block';
    
    this.ManageClientsService.applySurchargeExs(SurchargeExs, CarrierName).subscribe((Result) => {
      spinner.style.display = 'none';
      this.handleCompleted();
      this.handleFocusSweet();
    });
  }
  handleCompleted() {
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlay.style.display = 'flex';
    overlay.style.justifyContent = 'center';
    overlay.style.alignItems = 'center';
    overlay.style.zIndex = '9999';
  
    const confirmBox = document.createElement('div');
    confirmBox.style.background = 'white';
    confirmBox.style.padding = '20px';
    confirmBox.style.borderRadius = '5px';
    confirmBox.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.1)';
    
    confirmBox.style.display = 'flex';
    confirmBox.style.flexDirection = 'column';
    confirmBox.style.alignItems = 'center';
    confirmBox.style.justifyContent = 'flex-start';
    confirmBox.style.gap = '20px';
  
    confirmBox.innerHTML = `
      <h3>The update is completed.</h3>
      <div id="buttonsContainer" style="display: flex; justify-content: center; gap: 20px;">
        <button id="confirmOk" class="btn btn-success">Ok</button>
      </div>
    `;
  
    overlay.appendChild(confirmBox);
    document.body.appendChild(overlay);
  
    confirmBox.style.transform = 'scale(0)';
    confirmBox.style.transition = 'transform 0.3s ease';
  
    setTimeout(() => {
      confirmBox.style.transform = 'scale(1)';
    }, 50);
  
    document.getElementById('confirmOk')?.addEventListener('click', () => {
      confirmBox.style.transform = 'scale(0)';
      
      setTimeout(() => {
        overlay.remove();
      }, 200);
    });
    
    this.handleFocusSweet();    
  }  
  handleFocusSweet() {
    const confirmButton = document.querySelector('.swal2-confirm.swal2-styled') as HTMLElement;

    if (confirmButton)
        confirmButton.focus();    
  }
  //-----------------------------------------------------------------------------------------------------------------------

}
