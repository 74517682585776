// Ref: Building a reusable dialog module with Angular Material -> https://itnext.io/building-a-reusable-dialog-module-with-angular-material-4ce406117918
// Ref: How to round to at 2 decimal -> https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
// Ref: WHY Angular doesn't like jQuery -> https://blog.bitsrc.io/how-to-use-jquery-with-angular-when-you-absolutely-have-to-42c8b6a37ff9

import { MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource as MatTableDataSource } from "@angular/material/table";

import { Component, ChangeDetectionStrategy, Inject, HostListener, OnInit } from '@angular/core';
import { Countries } from "@app/shared/CSDAModels/Countries";
import { OrderResult } from "@app/shared/CSDAModels/OrderResult";
import { Provinces } from "@app/shared/CSDAModels/Provinces";
import { spGetAddressListForDivisionResult } from "@app/shared/CSDAModels/spGetAddressListForDivisionResult";

@Component({
    selector: 'app-proformat',
    templateUrl: './proformat.component.html',
    styleUrls: ['./proformat.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProformatComponent implements OnInit {

  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'ODP_Quantity',
    'ODP_Description',
    'ODP_ManufactureCountryCode',
    'ODP_HSCode',
    'ODP_Value',
    'totalValue'
  ];

  totalValue = 0;
  countriesList: Countries[];
  statesProvincesList: Provinces[];
  addressesList: spGetAddressListForDivisionResult[];

  constructor(
    private mdDialogRef: MatDialogRef<ProformatComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderResult,
  ) { }

  ngOnInit(): void {
		this.countriesList = JSON.parse(sessionStorage.getItem("Countries")) as Countries[];
		this.statesProvincesList = JSON.parse(sessionStorage.getItem("Provinces")) as Provinces[];
    this.addressesList = JSON.parse(sessionStorage.getItem("AddressesList")) as spGetAddressListForDivisionResult[];

    this.dataSource = new MatTableDataSource(this.data.DutiableProducts);

    this.data.DutiableProducts.forEach(item => {
      this.totalValue = this.totalValue + (item.ODP_Value * item.ODP_Quantity);
    })
  }

  toNumber(value) {
    return parseInt(value);
  }
	//-------------------------------------------------------------------
	// Used to get the name of the state since the dataSource have the id
	//-------------------------------------------------------------------
	getStateName(ADD_PRV_Ref: number) {
		var province: Provinces = this.statesProvincesList.find(p => p.PRV_ID == ADD_PRV_Ref);

		if(province)
			return province.PRV_Name;
		else
			return "";
	}

	//---------------------------------------------------------------------
	// Used to get the name of the country since the dataSource have the id
	//---------------------------------------------------------------------
	getCountryName(ADD_CON_Ref: number) {
		var country: Countries = this.countriesList.find(c => c.CON_ID == ADD_CON_Ref);

		if(country)
			return country.CON_Name;
		else
			return "";
	}

  //---------------------------------------------------------------------------
  // When the user do a choice in the list, the name of the company will be set
  //---------------------------------------------------------------------------
  getCompanyName(val: string | number): string {
    if(typeof val === "number")
    {
      const Element: spGetAddressListForDivisionResult = this.addressesList.find(f => f.Row == val);
      return Element.CompanyName;
    }
    else
      return val;
  }

  public cancel() {
    this.close(false);
  }

  public close(value) {
    this.mdDialogRef.close(value);
  }

  public confirm() {
    window.print();
    this.close(true);
  }

  @HostListener("keydown.esc")
  public onEsc() {
    this.close(false);
  }
}
