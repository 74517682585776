import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";

// Test MR : Juste un commentaire pour pousser dans le cloud
import { MainViewComponent } from "./core/layout/main-view/main-view.component";

const accountModule = () => import("./features/account/account.module").then((x) => x.AccountModule);

const PickupsListModule = () => import("./features/pickupslist/pickupslist.module").then((x) => x.PickupsListModule);

const historyModule = () => import("./features/history/history.module").then((x) => x.HistoryModule);

const quotesModule = () => import("./features/quotes/quotes.module").then((x) => x.QuotesModule);

const productModule = () => import("./features/product/product.module").then((x) => x.ProductModule);

const createNewShipmentModule = () => import("./features/create-new-shipment/create-new-shipment.module").then((x) => x.CreateNewShipmentModule);

const addressbookModule = () => import("./features/address-book/pages/address-book/addressbook-module/addressbook.module").then((x) => x.AddressbookModule);

const addresseditorModule = () => import("./features/address-book/pages/address-editor/addresseditor-module/address-editor.module").then((x) => x.AddressEditorModule);

const customPackagesModule = () => import("./features/custom-packages/custom-packages/custom-packages.module").then((x) => x.CustomPackagesModule);

const customProductsModule = () => import("./features/custom-products/custom-products/custom-products.module").then((x) => x.CustomProductsModule);

const manageClientsViewLayout = () => import("./features/manage-clients/Layout/Layout.module").then((x) => x.ManageClientsViewLayoutModule);

const importModule = () => import("./features/import/import.module").then((x) => x.ImportModule);

const adminModule = () => import("./features/administration/Logs/admin.module").then((x) => x.AdminModule);

const ActiveOrdersModule = () => import("./features/active-orders/active-orders.module").then((x) => x.ActiveOrdersModule);

const routes: Routes = [
	{
		path: "",
		component: MainViewComponent,
		canActivate: [AuthGuard],
		loadChildren: ActiveOrdersModule
	},
	{
		path: "history",
		component: MainViewComponent,
		canActivate: [AuthGuard],
		loadChildren: historyModule
	},
	{
		path: "addressbook",
		component: MainViewComponent,
		canActivate: [AuthGuard],
		loadChildren: addressbookModule
	},
	{
		path: "addresseditor",
		component: MainViewComponent,
		canActivate: [AuthGuard],
		loadChildren: addresseditorModule
	},
	{
		path: "custom-packages",
		component: MainViewComponent,
		canActivate: [AuthGuard],
		loadChildren: customPackagesModule
	},
	{
		path: "custom-products",
		component: MainViewComponent,
		canActivate: [AuthGuard],
		loadChildren: customProductsModule
	},
	{
		path: "quotes/8",
		component: MainViewComponent,
		canActivate: [AuthGuard],
		loadChildren: quotesModule
	},
	{
		path: "quotes/51",
		component: MainViewComponent,
		canActivate: [AuthGuard],
		loadChildren: quotesModule
	},
	{
		path: "pickupsList",
		component: MainViewComponent,
		canActivate: [AuthGuard],
		loadChildren: PickupsListModule
	},
	{
		path: "products",
		component: MainViewComponent,
		canActivate: [AuthGuard],
		loadChildren: productModule
	},
	{
		path: "orders",
		component: MainViewComponent,
		canActivate: [AuthGuard],
		loadChildren: createNewShipmentModule
	},
	{
		path: "account",
		loadChildren: accountModule
	},
	{
		 path: 'shopify-orders', 
		 component: MainViewComponent,
		 canActivate: [AuthGuard],
		 loadChildren: () => import('./features/shopify-orders/shopify-orders.module').then(m => m.ShopifyOrdersModule)
	},
	{
		path: "manageclientsviewlayout",
		component: MainViewComponent,
		canActivate: [AuthGuard],
		loadChildren: manageClientsViewLayout
	},
	{
		path: "importorders",
		component: MainViewComponent,
		canActivate: [AuthGuard],
		loadChildren: importModule
	},
	{
		path: "administration",
		component: MainViewComponent,
		canActivate: [AuthGuard],
		loadChildren: adminModule
	},	
	{ 
		path: "**", 
		redirectTo: "" 
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: false})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
