import { Component, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'app-spinner',
    template: `
    <div *ngIf="show" [@spin]="spinState" class="spinner-container">
      <div class="backdrop"></div>
      <div class="spinner-circle spinner-circle-outer"></div>
      <div class="spinner-circle spinner-circle-inner"></div>
    </div>
  `,
    styles: [`
    .spinner-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .backdrop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .spinner-circle {
      position: absolute;  /* Change this line */
      border-radius: 50%;
      width: 100px;
      height: 100px;
      box-sizing: border-box; /* Add this line */
    }
    .spinner-circle-outer {
      border: 16px solid #f3f3f3;
      border-top: 16px solid blue;
      animation: spinOuter 2s linear infinite;
    }
    .spinner-circle-inner {
      border: 16px solid #f3f3f3;
      border-top: 16px solid red;
      animation: spinInner 2s linear infinite reverse;
    }
    @keyframes spinOuter {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    @keyframes spinInner {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `],
    animations: [
        trigger('spin', [
            state('true', style({ opacity: 1 })),
            state('false', style({ opacity: 0 })),
            transition('true <=> false', animate('500ms'))
        ])
    ],
    standalone: false
})
export class SpinnerComponent {
  @Input() show: boolean = false;

  get spinState() {
    return this.show ? 'true' : 'false';
  }
}
