import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from "./admin-routing.module";
import { SharedModule } from "@app/shared/shared.module";
import { AngularSplitModule } from "angular-split";
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { RightsComponent } from "../Rights/rights.component";
import { UtilitiesComponent } from "../Utilities/Utilities.component";

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    AngularSplitModule,
    MatDialogModule
  ],
  declarations: [AdminComponent, RightsComponent, UtilitiesComponent]
})
export class AdminModule { }
