<div class="form" style="margin:15px; border: 1px solid black;border-radius: 6px;padding:8px; height: calc(100vh - 220px); overflow: auto;">
  <div class="form_row">
    <mat-form-field appearance="outline" class="form-field-width">
      <mat-label>{{'app.administration.utilOrderID' | translate}}</mat-label>
      <input matInput required [(ngModel)]="OrderID" [ngModelOptions]="{standalone: true}" autocomplete="off" placeholder=" {{'app.administration.utilOrderID' | translate}}" />
    </mat-form-field>
  </div>
  <div class="form_row">
    <mat-checkbox [(ngModel)]="IsCopyToLocal" [ngModelOptions]="{standalone: true}" matTooltip="{{'app.administration.utilIsCopyToLocalToolTip' | translate}}" matTooltipPosition="above">
      {{'app.administration.utilIsCopyToLocal' | translate}}
    </mat-checkbox>
  </div>
  <div class="form_row">
    <button mat-raised-button color="primary" mat-button (click)="copyOrder()" [disabled]="!OrderID" matTooltip="{{'app.administration.utilExecuteToolTip' | translate}}" matTooltipPosition="above">
      {{ 'app.administration.utilExecute' | translate }}
      <mat-icon class="material-icons">
        play_arrow
      </mat-icon>
    </button>
  </div>
</div>

<!-- The overlay to display when the page is processing something -->
<app-processing-overlay *ngIf="isProcessing" [message]=" 'app.misc.processing' | translate ">
</app-processing-overlay>