import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { Appearance } from "@app/features/custom-packages/custom-packages.component";
import { Rights } from "@app/shared/CSDAModels/Rights";
import { Language } from "@app/core/enums/Language";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { ValidationService } from "@app/core/services/validation.service";
import { environment } from "@env";
import Swal from "sweetalert2";
import { SaveRightDetail } from "@app/shared/CSDAModels/SaveRightDetail";
import { SaveRightResult } from "@app/shared/CSDAModels/SaveRightResult";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { GetRightsDetail } from "@app/shared/CSDAModels/GetRightsDetail";
import { GetRightsResult } from "@app/shared/CSDAModels/GetRightsResult";
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS, MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions } from "@angular/material/legacy-tooltip";

const matTooltipDefaultOptions: MatTooltipDefaultOptions = {
  disableTooltipInteractivity: true,
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0
};

@Component({
  selector: 'app-rights',
  templateUrl: './rights.component.html',
  styleUrls: ['./rights.component.css'],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipDefaultOptions }]
})
export class RightsComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() appearance: string | Appearance = Appearance.OUTLINE;

  isProcessing = false;
  enableAdd = true;
	searchInput = "";
  rights: Rights[] = [];
  modifiedRight: Rights;
	dataSource = new MatTableDataSource([]);
	displayedColumns: string[] = [
    "Rit_Id",
		"Rit_Name",
		"Rit_Desc",
    "Rit_Action"
	];

  constructor(
		private translateService: TranslateService,
    private httpClient: HttpClient,
    private validationService: ValidationService,
  ) { }

  ngOnInit() {
    this.loadData();
  }
  
  loadData() {
    this.isProcessing = true;
    var Detail: GetRightsDetail = {
      //--------------------
      // Deep clone Products
      //--------------------
      Language: Language.English,
      IsDevelopmentEnvironment: false
    }

    this.httpClient.post<GetRightsResult>(environment.CSDAEndPoint + "/CSDA/GetRights", Detail).subscribe(data => {
      this.isProcessing = false;
      var validation = this.validationService.validate(data);

      if (validation.isValid === false) {
        this.isProcessing = false;
          Swal.fire('', validation.message, "error");
      }
      else {
        this.rights = data.Rights;
        this.dataSource = new MatTableDataSource(this.rights);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isProcessing = false;
      }
    });
  }

  onAddRight() {
    this.rights.push({
      Rit_Id: this.rights.length + 1,
      Rit_Action: "ADD",
      Rit_Name: "",
      Rit_Desc: ""
    });
    
    this.dataSource = new MatTableDataSource(this.rights);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator; 
  }

  onSaveRight(right: Rights) {
    this.isProcessing = true;
    var rightFound = this.rights.find(rit => rit.Rit_Id == right.Rit_Id);
    let rights: Rights[] = [rightFound];

    var Detail: SaveRightDetail = {
      //--------------------
      // Deep clone Packages
      //--------------------
      Right: right,//JSON.parse(JSON.stringify(right)),
      Language: Language.English,
      IsDevelopmentEnvironment: false,
      IsDelete: false
    }
    
    this.httpClient.post<SaveRightResult>(environment.CSDAEndPoint + "/CSDA/SaveRight", Detail).subscribe(data => {
      this.isProcessing = false;
      var validation = this.validationService.validate(data);
      var IsValid:boolean = true;

      if (validation.isValid === false) {
          Swal.fire('', validation.message, "error");
          IsValid = false;
      }
      else {
        Swal.fire({
          icon: "info",
          title: this.translateService.instant("app.misc.saved"),
          confirmButtonText: 'OK'
        }).then((sweetAlertResult) => {});
        
        rightFound.Rit_Action = "";
        rightFound.Rit_Id = data.Rit_Id;
      }
    },
    error => {
      this.isProcessing = false;

      Swal.fire({
        icon: "info",
        title: error,
        confirmButtonText: 'OK'
      }).then((sweetAlertResult) => {});
    })
  }
  
  onModifyRight(right: Rights) {
    this.modifiedRight = {...right};
    var rightFound = this.rights.find(rit => rit.Rit_Id == right.Rit_Id);
    rightFound.Rit_Action = "UPDATE";
  }
  
  onRemoveRight(right: Rights)
  {
    // var rightFound = this.rights.find(rit => rit.Rit_Id == right.Rit_Id);
    // rightFound.Rit_Action = "DELETE";
    
    Swal.fire({
      title: this.translateService.instant("app.misc.delete"),
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.isProcessing = true;
    
        var Detail: SaveRightDetail = {
          //--------------------
          // Deep clone Products
          //--------------------
          Right: right,//JSON.parse(JSON.stringify(right)),
          Language: Language.English,
          IsDevelopmentEnvironment: false,
          IsDelete: true
        }

        this.httpClient.post<SaveRightResult>(environment.CSDAEndPoint + "/CSDA/SaveRight", Detail).subscribe(data => {
          this.isProcessing = false;
          var validation = this.validationService.validate(data);
          var IsValid:boolean = true;

          if (validation.isValid === false) {
              Swal.fire('', validation.message, "error");
              IsValid = false;
          }
          else {
            this.rights = this.rights.filter(rit => rit.Rit_Id !== right.Rit_Id);
            this.dataSource = new MatTableDataSource(this.rights);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          }
        });
      }
    });
  }

  onCancel(row: Rights) {
    if(row.Rit_Action == "ADD") {
      this.rights = this.rights.filter(rit => rit.Rit_Id !== row.Rit_Id);
    }
    else {
      this.rights = this.rights.map(ri => {
        if (ri.Rit_Id == row.Rit_Id) {
          return this.modifiedRight; // Revert back to the original
        } else {
          return ri;
        }
      });
    }

    this.dataSource = new MatTableDataSource(this.rights);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
