import { Component, OnInit } from "@angular/core";
import { StorageService } from "@app/core/services/storage.service";
import { UsersRights } from "@app/shared/CSDAModels/UserRights";

@Component({
    selector: "app-main-menu",
    templateUrl: "./main-menu.component.html",
    styleUrls: ["./main-menu.component.scss"],
    standalone: false
})
export class MainMenuComponent implements OnInit {
	CurrentUsersRights: UsersRights[];

	shopifyAccess: boolean = false;
	clientManagementAccess: boolean = false;
	importAccess: boolean = false;
	isGuest: boolean = false;
	AdministratorOrSystem: boolean = false;

	constructor(
		private storageService: StorageService
	) {}
	
	ngOnInit(): void {
		this.CurrentUsersRights = JSON.parse(sessionStorage.getItem("CurrentUserRights"));

		if(this.CurrentUsersRights.find(c => c.Uri_Rit_Ref == 1 || c.Uri_Rit_Ref == 2) != null)
			this.AdministratorOrSystem = true;
		
		if(this.CurrentUsersRights.find(c => c.Uri_Rit_Ref == 4) != null || this.AdministratorOrSystem)
			this.clientManagementAccess = true;

		if(this.CurrentUsersRights.find(c => c.Uri_Rit_Ref == 5) != null || this.AdministratorOrSystem)
			this.importAccess = true;

		if(this.CurrentUsersRights.find(c => c.Uri_Rit_Ref == 3) != null || this.AdministratorOrSystem)
			this.shopifyAccess = true;

		if(this.CurrentUsersRights.find(c => c.Uri_Rit_Ref == 6)) {
			this.isGuest = true;
			sessionStorage.setItem("isGuest", "true");
		}
	}

	setQuoteType(quoteType: string) {
		sessionStorage.setItem("quoteType", quoteType);
	}
}
