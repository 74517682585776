import { MatFormFieldModule as MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule as MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule as MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatListModule as MatListModule } from '@angular/material/list';
import { MatProgressBarModule as MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTooltipModule as MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule as MatButtonModule } from "@angular/material/button";

import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, Injectable, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { NgxPrintModule } from "ngx-print";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularSplitModule } from "angular-split";
import { environment } from '../environments/environment';
import { AddressEditorComponent } from "./features/address-book/pages/address-editor/address-editor.component";
import { CustomPackagesComponent } from './features/custom-packages/custom-packages.component';
import { CustomPackagesModule } from './features/custom-packages/custom-packages/custom-packages.module';
import { CustomProductsComponent } from './features/custom-products/custom-products.component';
import { CustomProductsModule } from './features/custom-products/custom-products/custom-products.module';
import { ManageClientsViewLayoutComponent } from './features/manage-clients/Layout/Layout.component';
import { ClientsComponent } from './features/manage-clients/Clients/Clients.component';
import { DivisionsComponent } from "./features/manage-clients/Divisions/Divisions.component";
import { UsersComponent } from './features/manage-clients/Users/Users.component';
import { RatesComponent } from './features/manage-clients/Rates/Rates.component';
import { RateInsurancesComponent } from './features/manage-clients/RateInsurances/RateInsurances.component';
import { RateInsurancesModule } from "./features/manage-clients/RateInsurances/RateInsurances.module";
import { ImportComponent } from './features/import/import.component';

import { ClientsModule } from "./features/manage-clients/Clients/Clients.module";
import { DivisionsModule } from './features/manage-clients/Divisions/Divisions.module';
import { ManageClientsViewLayoutModule } from './features/manage-clients/Layout/Layout.module';
import { UsersModule } from "./features/manage-clients/Users/Users.module";
import { RatesModule } from "./features/manage-clients/Rates/Rates.module";
import { ImportModule } from "./features/import/import.module";
import { UploadOrdersComponent } from './features/upload-orders/upload-orders.component';
import { UploadOrdersModule } from "./features/upload-orders/upload-orders.module";
import { ParametersComponent } from './features/manage-clients/Parameters/Parameters.component';
import { ParametersModule } from "./features/manage-clients/Parameters/Parameters.module";
import { ProductModule } from "./features/product/product.module";
import { ShopifyOrdersModule } from "./features/shopify-orders/shopify-orders.module";
import { UserRightsComponent } from './features/manage-clients/User-Rights/User-Rights.component';
import { UserRightsModule } from "./features/manage-clients/User-Rights/User-Rights.module";
import { AdminModule } from "./features/administration/Logs/admin.module";
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { registerLocaleData } from '@angular/common';

// Locale data imports
import localeEn from '@angular/common/locales/en';
import localeEnCA from '@angular/common/locales/en-CA';
import localeArKW from '@angular/common/locales/ar-KW';
import localeArBH from '@angular/common/locales/ar-BH';
import localeArOM from '@angular/common/locales/ar-OM';
import localeArJO from '@angular/common/locales/ar-JO';
import localeEnGB from '@angular/common/locales/en-GB';
import localeFr from '@angular/common/locales/fr';
import localeDeCH from '@angular/common/locales/de-CH';

registerLocaleData(localeEn, 'en-US');     // USD
registerLocaleData(localeEnCA, 'en-CA');   // CAD
registerLocaleData(localeArKW, 'ar-KW');   // KWD
registerLocaleData(localeArBH, 'ar-BH');   // BHD
registerLocaleData(localeArOM, 'ar-OM');   // OMR
registerLocaleData(localeArJO, 'ar-JO');   // JOD
registerLocaleData(localeEnGB, 'en-GB');   // GBP
registerLocaleData(localeEnGB, 'en-KY');   // KYD (fallback)
registerLocaleData(localeFr, 'fr-FR');     // EUR
registerLocaleData(localeDeCH, 'de-CH');   // CHF

// import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgxGpAutocompleteModule as GooglePlaceModule } from "@angular-magic/ngx-gp-autocomplete";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    console.error('Global error handler:', error);
  }
}
  
@NgModule({
  declarations: [
    AppComponent,
    AddressEditorComponent,
    CustomPackagesComponent,
    CustomProductsComponent,
    ManageClientsViewLayoutComponent,
    ClientsComponent,
    DivisionsComponent,
    UsersComponent,
    RatesComponent,
    RateInsurancesComponent,
    ParametersComponent,
    UserRightsComponent,
    ImportComponent,
    UploadOrdersComponent,
    ParametersComponent
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    GooglePlaceModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    CustomPackagesModule,
    CustomProductsModule,
    ManageClientsViewLayoutModule,
    AngularSplitModule,
    ClientsModule,
    DivisionsModule,
    UsersModule,
    RatesModule,
    RateInsurancesModule,
    ParametersModule,
    UserRightsModule,
    ImportModule,
    MatProgressBarModule,
    UploadOrdersModule,
    MatTooltipModule,
    ProductModule,
    MatButtonModule,
    ShopifyOrdersModule,
    MatListModule,
    AdminModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxMask({ validation: false })
  ]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
