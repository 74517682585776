import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from './material/material.module';
import { NgxPrintModule } from 'ngx-print';
import { NgPaymentCardModule } from './components/creditcard/ng-payment-card.module';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask'; // Correct import for ngx-mask

// Components
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { BoxMessageComponent } from './components/box-message/box-message.component';
import { ProcessingOverlayComponent } from './components/processing-overlay/processing-overlay.component';
import { CreatePickupDialogComponent } from './components/create-pickup-dialog-box/create-pickup-dialog/create-pickup-dialog.component';
import { CompletAddressFormComponent } from './components/complet-address-form/complet-address-form.component';
import { ShipmentDetailsComponent } from './components/shipment-details/shipment-details.component';
import { ShipmentSummaryComponent } from './components/shipment-summary/shipment-summary.component';
import { ProformatComponent } from './components/proformat/proformat/proformat.component';
import { CostBreakdownComponent } from './components/cost-breakdown/cost-breakdown.component';
import { SpinnerComponent } from '@app/shared/components/Spinner/Spinner.component';
import { ProformatService } from "./components/proformat/proformat/proformat.service";

@NgModule({
    declarations: [
        LoadingScreenComponent,
        BoxMessageComponent,
        ProcessingOverlayComponent,
        CreatePickupDialogComponent,
        CompletAddressFormComponent,
        ShipmentDetailsComponent,
        ShipmentSummaryComponent,
        ProformatComponent,
        CostBreakdownComponent,
        SpinnerComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        NgxPrintModule,
        NgPaymentCardModule,
		NgxMaskDirective 
    ],
    exports: [
        LoadingScreenComponent,
        BoxMessageComponent,
        ProcessingOverlayComponent,
        CreatePickupDialogComponent,
        CompletAddressFormComponent,
        ShipmentDetailsComponent,
        ShipmentSummaryComponent,
        ProformatComponent,
        CostBreakdownComponent,
        SpinnerComponent,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        TranslateModule,
		NgxMaskDirective
    ],
    providers: [
        DatePipe,
        ProformatService,
        provideNgxMask() // Provide ngx-mask configuration globally
    ]
})
export class SharedModule {}
