//#region Imports & Component definition
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Language } from "@app/core/enums/Language";
import { HttpClient } from "@angular/common/http";
import { ValidationService } from "@app/core/services/validation.service";
import { environment } from "@env";
import { map } from "rxjs/operators";
import { CustomDictionariesService } from "@app/core/services/custom-dictionaries.service";
import { GetCustomPackagesResult } from "@app/shared/CSDAModels/GetCustomPackagesResult";
import { GetCustomPackagesDetail } from "@app/shared/CSDAModels/GetCustomPackagesDetail";
import { Packages } from "@app/shared/CSDAModels/Packages";
import { HeaderService } from "@app/core/services/header.service";
import { NgForm } from "@angular/forms";
import { PackageType } from "@app/core/enums/PackageType";
import { SaveCustomPackagesDetail } from "@app/shared/CSDAModels/SaveCustomPackagesDetail";
import { SaveCustomPackagesResult } from "@app/shared/CSDAModels/SaveCustomPackagesResult";
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS, MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions } from "@angular/material/legacy-tooltip";
import Swal from "sweetalert2";
import { Observable } from "rxjs";
import { UnitOfMeasure } from "@app/core/enums/unit-of-measure";
import { UtilsService } from "@app/core/services/utils.service";
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

const matTooltipDefaultOptions: MatTooltipDefaultOptions = {
  disableTooltipInteractivity: true,
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0
};

export enum Appearance {
  STANDARD = 'standard',
  FILL = 'fill',
  OUTLINE = 'outline',
  LEGACY = 'legacy',
}

@Component({
  templateUrl: './custom-packages.component.html',
  styleUrls: ['./custom-packages.component.scss'],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipDefaultOptions }]
})
//#endregion

export class CustomPackagesComponent implements OnInit {
  //#region Inputs, Outputs and ViewChild
  @ViewChild('customPackagesForm', { static: true }) customPackagesForm: NgForm;
  @ViewChild("removeCustomPackage") removeCustomPackageElement: ElementRef<HTMLButtonElement>  
  @ViewChild('saveCustomPackagesControl', { read: ElementRef }) saveCustomPackagesControl:ElementRef;
  @ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() appearance: string | Appearance = Appearance.OUTLINE;
  //#endregion
  //#region Properties
  isProcessing = false;
  packageTypes = this.customDictionaries.packageTypes;
  customPackages: Packages[] = [];
  customPackageCustomId:number = 999999;
  measures = this.customDictionaries.measures;
  isMetric = true;
  enableAdd = true;
	searchInput = "";  
  modifiedPackage: Packages;
	dataSource = new MatTableDataSource([]);
	displayedColumns: string[] = [
		"PAC_Name",
		"PAC_PackageType",
		"PAC_Description",
		"PAC_Quantity",
		"PAC_Length",
		"PAC_Width",
		"PAC_Height",
    "PAC_Weight",
    "PAC_DryIceWeight",
    "UnitOfMeasure",
    "actions"
	];

  //#endregion  
  //#region Constructor
  constructor(
    private zone:NgZone,
    private utilsService: UtilsService,
    private customDictionaries: CustomDictionariesService,
		private translateService: TranslateService,
    private httpClient: HttpClient,
    private validationService: ValidationService,
		private headerService: HeaderService
  )
    { }
  //#endregion
  //#region OnInit
  //---------------------------------------------------
  // Enable display and set default values of the forms
  //---------------------------------------------------
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.paginator && this.paginator._intl) {
        this.paginator._intl.itemsPerPageLabel = 'Items : ';
      }      
    }, 0);
  }  
  ngOnInit(): void {
    this.isProcessing = true;

    //-------------------
    // Set the page title
    //-------------------
    this.headerService.setTitle(this.translateService.instant("app.customPackages.title"));

    //---------------------------------------------------------------------------------
    // Subscribe to the translate service to update the title when the language changes
    //---------------------------------------------------------------------------------
    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.headerService.setTitle(this.translateService.instant("app.customPackages.title"));
    }); 

    this.getCustomPackages().subscribe(result => {
      this.customPackages = result.Packages;

      this.customPackages.forEach(p => {
        p.PAC_PackageType = PackageType[p.PAC_PackageType.toString()];
      });
      
      this.dataSource = new MatTableDataSource(this.customPackages);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isProcessing = false;
    });
  }
  //#endregion
  //#region Methods
  getUOMName(code: UnitOfMeasure): string | undefined {
    const measure = this.measures.find(m => m.code === code);
    return measure ? this.translateService.instant(measure.name) : undefined;
  }

  getPackageTypeName(code: PackageType): string | undefined {
    const pacType = this.packageTypes.find(m => m.code === code);
    return pacType ? this.translateService.instant(pacType.name) : undefined;
  }  

  getCustomPackages(): Observable<GetCustomPackagesResult> {
    const Detail: GetCustomPackagesDetail = {
      Language: Language.English,
      IsDevelopmentEnvironment: false
    };

    return this.httpClient.post<GetCustomPackagesResult>(environment.CSDAEndPoint + "/CSDA/GetCustomPackages", Detail)
      .pipe(
          map(data => {
              var validation = this.validationService.validate(data);
              var IsValid:boolean = true;

              if (validation.isValid === false) {
                  Swal.fire('', validation.message, "error");
                  IsValid = false;
              }

              return data;
          })
      );    
  }

  onSaveCustomPackage(customPackage: Packages) {

    if(!this.validateDryIceWeight(customPackage))
      return;
    
    this.isProcessing = true;
    var customPackageFound = this.customPackages.find(cp => cp.PAC_ID == customPackage.PAC_ID);
    let packages: Packages[] = [customPackageFound];

    var Detail: SaveCustomPackagesDetail = {
      //--------------------
      // Deep clone Packages
      //--------------------
      Packages: JSON.parse(JSON.stringify(packages)),
      Language: Language.English,
      IsDevelopmentEnvironment: false
    }
    
    //-------------------------------------------------------------------
    // Needs to convert the the PackageType to string before going to API
    //-------------------------------------------------------------------
    Detail.Packages.forEach(p => {
      p.PAC_PackageType = PackageType[p.PAC_PackageType];
    });

    return this.httpClient.post<SaveCustomPackagesResult>(environment.CSDAEndPoint + "/CSDA/SaveCustomPackages", Detail)
    .subscribe(data => {
      this.isProcessing = false;
      var validation = this.validationService.validate(data);
      var IsValid:boolean = true;

      if (validation.isValid === false) {
          Swal.fire('', validation.message, "error");
          IsValid = false;
      }
      else {
        Swal.fire({
          icon: "info",
          title: this.translateService.instant("app.misc.saved"),
          confirmButtonText: 'OK'
        }).then((sweetAlertResult) => {});
        
        customPackageFound.PAC_Action = "";
      }

      return IsValid
    },
    error => {
      this.isProcessing = false;

      Swal.fire({
        icon: "info",
        title: error,
        confirmButtonText: 'OK'
      }).then((sweetAlertResult) => {});
    })
  }

  customPackagesValid(): boolean {
    //---------------------------------------------------------------
    // Validate contacts in a Template-Driven Two Way Binding context
    //---------------------------------------------------------------
    var element: Packages;
    var IsValid = true;

    for(element of this.customPackages) {
      if(!element.PAC_Name && element.PAC_Action != "DELETE") {
        IsValid = false;
      }
    }

    if(this.customPackages.filter(c => c.PAC_Action != "DELETE").length == 0)
      IsValid = false;
    
    return IsValid;
  }

  onAddCustomPackage() {
    this.customPackages.push({
      PAC_ID: -this.customPackages.length - 1,
      PAC_Action: "ADD",
      PAC_Name: "",
      PAC_PackageType: this.customPackages.length == 0 ? PackageType.CUSTOM : this.customPackages[this.customPackages.length-1].PAC_PackageType,
      PAC_Description: "",
      PAC_Quantity: 1,
      PAC_Length: 0,
      PAC_Width: 0,
      PAC_Height: 0,
      PAC_Weight: 0,
      PAC_DryIceWeight: 0,
      PAC_Div_Ref: 0,
      UnitOfMeasure: UnitOfMeasure.Metric
    });
    
    this.dataSource = new MatTableDataSource(this.customPackages);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator; 
    this.applySearch(this.searchInput);   
  }

  onRemoveCustomPackage(customPackage: Packages)
  {
    var customPackageFound = this.customPackages.find(cp => cp.PAC_ID == customPackage.PAC_ID);
    customPackageFound.PAC_Action = "DELETE";
    
    Swal.fire({
      title: this.translateService.instant("app.misc.delete"),
      icon: "question",
      showDenyButton: true,
      denyButtonText: this.translateService.instant("app.misc.no"),
      confirmButtonText: this.translateService.instant("app.misc.yes"),

    }).then((result) => {
      if (result.isConfirmed) {
        this.isProcessing = true;
        let packages: Packages[] = [customPackageFound];
    
        var Detail: SaveCustomPackagesDetail = {
          //--------------------
          // Deep clone Products
          //--------------------
          Packages: JSON.parse(JSON.stringify(packages)),
          Language: Language.English,
          IsDevelopmentEnvironment: false
        }

        //-------------------------------------------------------------------
        // Needs to convert the the PackageType to string before going to API
        //-------------------------------------------------------------------
        Detail.Packages.forEach(p => {
          p.PAC_PackageType = PackageType[p.PAC_PackageType];
        });
    
        return this.httpClient.post<SaveCustomPackagesResult>(environment.CSDAEndPoint + "/CSDA/SaveCustomPackages", Detail)
        .pipe(
          catchError(error => {
            console.error(error);
            return throwError(error);
          })
        )
        .subscribe(data => {
          this.isProcessing = false;
          var validation = this.validationService.validate(data);
          var IsValid:boolean = true;

          if (validation.isValid === false) {
              Swal.fire('', validation.message, "error");
              IsValid = false;
          }
          else {
            this.customPackages = this.customPackages.filter(pac => pac.PAC_ID !== customPackage.PAC_ID);
            this.dataSource = new MatTableDataSource(this.customPackages);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.applySearch(this.searchInput);
          }

          return IsValid;
        });
      }
    });
  }

	/** Find the specified string in the data table
	 * @param searchValue - The partial or complet word to find
	 */
	applySearch(searchValue: string): void {

		/** If the filter value is an empty string we clear the seach bar */
		if (!searchValue) {
			this.searchInput = '';
      this.enableAdd = true;
		}
    else
      this.enableAdd = false;

		/** Then we apply filter */
		this.dataSource.filter = searchValue.trim().toLowerCase();
	}

  onCancel(row: Packages) {
    if(row.PAC_Action == "ADD") {
      this.customPackages = this.customPackages.filter(pac => pac.PAC_ID !== row.PAC_ID);
    }
    else {
      this.customPackages = this.customPackages.map(cp => {
        if (cp.PAC_ID == row.PAC_ID) {
          return this.modifiedPackage; // Revert back to the original
        } else {
          return cp;
        }
      });
    }

    this.dataSource = new MatTableDataSource(this.customPackages);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.applySearch(this.searchInput);
  }

  onModifyCustomPackage(customPackage: Packages) {
    this.modifiedPackage = {...customPackage};
    var customPackageFound = this.customPackages.find(cp => cp.PAC_ID == customPackage.PAC_ID);
    customPackageFound.PAC_Action = "UPDATE";
  }

  onUnitOfMeasureChange(value: UnitOfMeasure, customPackage: Packages) {
    var customPackageFound = this.customPackages.find(cp => cp.PAC_ID == customPackage.PAC_ID);
    
    if(value == UnitOfMeasure.Imperial) {
      this.isMetric = false;
      customPackageFound.PAC_Width = Number(this.utilsService.ConvertToImperial(false, customPackageFound.PAC_Width).toFixed(4));
      customPackageFound.PAC_Length = Number(this.utilsService.ConvertToImperial(false, customPackageFound.PAC_Length).toFixed(4));
      customPackageFound.PAC_Height = Number(this.utilsService.ConvertToImperial(false, customPackageFound.PAC_Height).toFixed(4));
      customPackageFound.PAC_Weight = Number(this.utilsService.ConvertToImperial(true, customPackageFound.PAC_Weight).toFixed(4));
      customPackageFound.PAC_DryIceWeight = Number(this.utilsService.ConvertToImperial(true, customPackageFound.PAC_DryIceWeight).toFixed(4));
    }
    else {
      this.isMetric = true;
      customPackageFound.PAC_Width = Number(this.utilsService.ConvertToMetric(false, customPackageFound.PAC_Width).toFixed(4));
      customPackageFound.PAC_Length = Number(this.utilsService.ConvertToMetric(false, customPackageFound.PAC_Length).toFixed(4));
      customPackageFound.PAC_Height = Number(this.utilsService.ConvertToMetric(false, customPackageFound.PAC_Height).toFixed(4));
      customPackageFound.PAC_Weight = Number(this.utilsService.ConvertToMetric(true, customPackageFound.PAC_Weight).toFixed(4));
      customPackageFound.PAC_DryIceWeight = Number(this.utilsService.ConvertToMetric(true, customPackageFound.PAC_DryIceWeight).toFixed(4));
    }
  }

  getImperialMetricSuffix(isDimension, customPackage: Packages) {
      var customPackageFound = this.customPackages.find(cp => cp.PAC_ID == customPackage.PAC_ID);

      if(customPackageFound) {
        if(customPackageFound.UnitOfMeasure == UnitOfMeasure.Metric) {
          if(isDimension)
            return "cm"
          else
            return "kg"
        }
        else {
          if(isDimension)
            return this.translateService.currentLang === "fr" ? "po" : "in";
          else
          return "lb"
        }
      }
  }
  validateDryIceWeight(customPackage: Packages) {
    var customPackageFound = this.customPackages.find(cp => cp.PAC_ID == customPackage.PAC_ID);
    if(customPackageFound.PAC_DryIceWeight > customPackageFound.PAC_Weight) {
      Swal.fire("", this.translateService.instant("app.shopifyOrders.details.packageDetails.dryIceWeightValidation"), "error")
      return false;
    }
    else
      return true;
  }  
}
  //#endregion

