import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatTableDataSource as MatTableDataSource } from "@angular/material/table";

import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentDirective } from '@app/core/base.component';
import { HeaderService } from '@app/core/services/header.service';
import { Product } from '../../models/product';
import { ProductService } from '../../services/product.service';
import { LoaderService } from '@app/core/services/loader.service';
import { ProductResult } from "@app/shared/CSDAModels/ProductResult";
import { Countries } from "@app/shared/CSDAModels/Countries";
import Swal from "sweetalert2";

const USER_SCHEMA = {
	name: 'text',
	description: 'text',
	length: 'number',
	width: 'number',
	height: 'number'
};

@Component({
    selector: 'app-edit-product-list',
    templateUrl: './edit-product-list.component.html',
    styleUrls: ['./edit-product-list.component.scss'],
    standalone: false
})
export class EditProductListComponent extends BaseComponentDirective implements AfterViewInit {
	IsMetric: boolean = true;
	countriesList: Countries[];
	products: ProductResult[];
	dataSource = new MatTableDataSource([]);
	displayedColumns: string[] = [
		'name',
		'description',
		'packageLength',
		'packageWidth',
		'packageHeight',
		'packageWeight',
		'shippingSurcharge',
		'packageType',
		'country',
		'hscode',
		'unitValue',
		//'action',
	];
	selection = new SelectionModel<Product>(true, []);
	@ViewChild(MatSort) sort: MatSort;
	dataSchema = USER_SCHEMA;
	isLoading = false;

	constructor(
		private headerService: HeaderService,
		private translateService: TranslateService,
		private productService: ProductService,
		public dialog: MatDialog,
		private loaderService: LoaderService
	) {
		super();
	}
	ngAfterViewInit(): void { this.dataSource.sort = this.sort; }

	onInit(): void {
		this.countriesList = JSON.parse(sessionStorage.getItem("Countries")) as Countries[];
		this.headerService.setTitle(
			this.translateService.instant('app.menu.products')
		);

		this.refreshProducts();
	}
	onDestroy(): void { }

	importProducts() {
		this.isLoading = true;
		
		this.productService.getShopifyProducts().subscribe((products) => {
			this.isLoading = false;
			this.products = products.ProductResults;
			this.dataSource = new MatTableDataSource(this.products);
			this.dataSource.sort = this.sort;
			this.sort.active = 'name';
			var Message = "";
			if(this.translateService.currentLang === "fr")
			  Message = "L'opération de récupération des produits a été lancée.";
			else
			  Message = "The operation to get the products has been executed.";

			Swal.fire('', Message, "info");			
		});
	}

	refreshProducts() {
		this.isLoading = true;
		this.productService.refreshProducts().subscribe((result) => {
			this.isLoading = false;
			//this.IsMetric = result.IsMetric; // Parce que le data dans notre table est maintenant toujours en Metric Task #5056
			this.products = result.ProductResults;
			this.dataSource = new MatTableDataSource(this.products);
			this.dataSource.sort = this.sort;
		})
	}

	applyFilter(filterValue: string): void {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	getCountryName(CountryId: number): string {
		var Country = this.firstOrDefault(this.countriesList, c => c.CON_ID == CountryId);
		var result = "";
		
		if(Country)
			result = Country.CON_Name;

		return result;
	}

	firstOrDefault<T>(array: T[], predicate: (item: T) => boolean): T | null {
		for (let item of array) {
		  if (predicate(item)) {
			return item;
		  }
		}
		return null;
	  }	
}
